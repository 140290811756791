import React from 'react'
import InvitedProfile from "../../assets/icons/invitedProfiles.svg"
import { capitalizeFirstAlphabet } from '../../utils'
import EllipsisTextComponent from '../EllipsisTextComponent'
import ProfileImageComponent from '../ProfileImageComponent'
import "./style.scss"

function InvitedProfileCheckCard({
  last_name = "",
  first_name = "",
  headline = "",
  imageLink = '', 
  }) {

//  const {profile,name,title,from} = card
//  const {type,profile_picture,public_identifier,last_name,headline,first_name,entity_urn} = card

    
//  let avatar=""
  // if(!profile)
  // {
  //   const peices=name.split(" ")
  //   if(peices.length>=2)
  //     avatar = peices[0].slice(0,1) + peices[1].slice(0,1) 
  //   else if(peices.length==1)
  //     avatar = peices[0].slice(0,1) 
  //   else  {
  //     avatar="NA"
  //   }
  //   console.log("avatar :",peices.length,avatar.toUpperCase())
  // }

  
  let initials = ""
  
    const firstNameAlphabet = capitalizeFirstAlphabet(first_name?.trim())?.charAt(0)
    const lastNameAlphabet = capitalizeFirstAlphabet(last_name?.trim())?.charAt(0)
    initials = (firstNameAlphabet + lastNameAlphabet)

return (
    <div className='checkbox_card'>
        <div className='full_profile_card'>
            {/* <input type="checkbox"/> */}
            {/* <img src={InvitedProfile} className="invitedStatusRibbon"/> */}
            <div>
                <div className='avatarimage'>
                  <ProfileImageComponent
                      imageLink={imageLink}
                      placeHolder={initials}
                  />
                </div>
                <div
                  className='profileNameAndHeadline'
                >
                    <p>{first_name+" "+last_name}</p>
                    {
                      headline && 
                        <EllipsisTextComponent
                          string={headline}
                          stringLengthCap = {257}
                        />
                    }
                    {/* <p>{public_identifier}</p> */}
                </div>
            </div>
            </div>
    </div>
  )
}

export default InvitedProfileCheckCard