import React from 'react'
import "./style.scss"
import ArrowRise from "../../../assets/icons/ArrowRise.svg"
import ArrowFall from "../../../assets/icons/ArrowFall.svg"

function TotalStatsBox({ title, value, percentage, rise , resultantStyle= false , flexCheck = false}) {
    return (
        <div className={`totalStatsBox ${resultantStyle ? 'totalStatsBoxResultant' : ''} ${flexCheck ? 'totalStatsBoxFlexRow' : ''}`} >
            <span className="title">{title}</span>
            <div className={`${rise ? "rise" : "fall"}`}>
                <span className='xl'>{value}</span>
                <span>
                    {/* <span>{percentage}</span> */}
                    {/* <img src={rise ? ArrowRise : ArrowFall} /> */}
                </span>
            </div>
        </div>
    )
}

export default TotalStatsBox