import React from 'react'
import "./style.scss"
import search from "../../assets/icons/search.svg"


function SearchInput({ placeholder, control, mountLoading , inputWidth, ...options }) {

  const searchData = (e) => {
    if (control?.autoSuggestContacts) {
      control.autoSuggestContacts(e.target.value)
    }
    else {
      control.setSearch(e.target.value)
    }
  }
  // console.log("options ", inputWidth)

  return (
    <div className='searchbox' {...options}>
      <img src={search} />
      <input disabled={mountLoading} type="text" value={control.search} onChange={searchData} placeholder={placeholder} />
    </div>
  )
}

export default SearchInput