import React, { useState } from 'react'
import searchButton from "../../../assets/icons/Funnel.svg"
import SearchInput from '../../../components/SearchInput'

import "./style.scss"

function SearchRow({ search, setSearch }) {

    return (
        <div className='searchRow'>
            <SearchInput placeholder="Search" control={{ search, setSearch }} style={{
                borderRadius: "8px",
                padding: "1px 5px",
                width: "320px",
                marginRight: "7px"
            }} />
            <img src={searchButton} />
        </div>
    )
}

export default SearchRow