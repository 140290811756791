/* global chrome */

import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import MainHeader from "../../../components/MainHeader"
import SendInvitesModal from "../../../components/SendInvitesModal"

import FooterPagination from '../../../components/FooterPagination'
import { setInvitationSendingCheck, INITIALIZE_SELECTED_USER_CONTACT, initInvitationsCredits, resetMessageTemplate, selectSelectedUserContacts, setUserContacts, updateSelectedUserContact, updateUserContactState, setShowMessagesSendingCards } from '../../../actions/referrals'
import useInterval from 'use-interval'
import { pingExtToStartSendingMessages, sendMessageToFirstDegreeConnection } from '../../../MessagePassing'
import { awaitfunction, callYoriApi, getRandomInt, invitationSentMilestones, invitationSentMilestonesBadges, notify } from '../../../utils'
import InviteProfileCheckCard from "../../../components/InviteProfileCheckCard";
import SearchInput from "../../../components/SearchInput";
import fullLeftArrow from "../../../assets/icons/arrow-right.svg"
import { useNavigate } from "react-router";
import PrimaryButton from "../../../components/PrimaryButton";
import LoadingSpinner from "../../../components/LoadingSpinner"
import reward1 from "../../../assets/icons/reward1.svg"
import reward2 from "../../../assets/icons/reward2.svg"
import reward3 from "../../../assets/icons/reward3.svg"
import reward5 from "../../../assets/icons/reward4.svg"
import reward4 from "../../../assets/icons/reward5.svg"

import "./style.scss"
import RewardNotificationModal from "../../../components/RewardNotificationModal";
import SelectedUserContactsModal from "../SelectedContactsModal";
import CustomToastContainer from "../../../components/ToastContainer";
import { async } from "@firebase/util";
import SendInvitesModalV2 from "../../../components/SendInvitesModal/v2";
import SendInvitesModalUpdated from "../../../components/SendInvitesModalUpdated";
import { setPublicIdentifier, setUserSummary } from "../../../actions/auth";
const SendInvites = ({ state }) => {
    const [openModal, setOpenModal] = useState(false);
    const [invitesFlag, setInvitesflag] = useState(true)

    const [temp, setTemp] = useState(true)
    // const [search, setSearch] = useState("")
    // const [sendingInvites, setSendingInvites] = useState(false)
    // const [query, setQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1)

    const [suggestedContactsList, setSuggestedContactsList] = useState([])
    const [flag, setFlag] = useState(true)
    const navigate = useNavigate()

    let {
        selectedUserContacts,
        showMessagesSendingCards
    } = useSelector(state => state.referrals?.userContacts)
    let contacts = useSelector(state => state.referrals?.userContacts?.userContacts)

    let {
        userSummary
    } = useSelector(state => state.auth?.user)

    let [contactsNotReferred, setContactsNotReferred] = useState([])
    // const totalPages = Math.ceil(contactsNotReferred.length / 10)
    // const totalContacts = contactsNotReferred.length
    // const minContactCount = currentPage * 10 - 9
    // const maxContactCount = (currentPage * 10) > totalContacts ? totalContacts : currentPage * 10
    // const [sendInvitesType, setSendInvitesType] = useState("")
    // const [selectedContactsModalOpen, setSelectedContactsModalOpen] = useState(false)
    // const [mountLoading, setMountLoading] = useState(true)

    const totalPages = Math.ceil(contactsNotReferred.length / 50)
    const totalContacts = contactsNotReferred.length
    const minContactCount = currentPage * 50 - 49
    const maxContactCount = (currentPage * 50) > totalContacts ? totalContacts : currentPage * 50
    const [sendInvitesType, setSendInvitesType] = useState("")
    const [selectedContactsModalOpen, setSelectedContactsModalOpen] = useState(false)
    const [mountLoading, setMountLoading] = useState(true)


    const dispatch = useDispatch()


    async function getContactsFromBackend() {
        // dispatch(setIsUserContactsLoading(true))
        // fetch
        setMountLoading(true)
        callYoriApi(`/referral/user_contacts`, "GET")
            .then(response => {
                // dispatch(setUserContacts(response.payload))
                setContactsNotReferred(response.payload)
            })
        setMountLoading(false)

    }

    useEffect(() => {
        console.log("State of Mount Loading : " , mountLoading)
    }, [mountLoading])
    
    const getPersonalizedMessageSummary = async () =>{
        const extId = process.env.REACT_APP_EXT_ID

        chrome.runtime.sendMessage(extId, { type: "GET_USER_PUBLIC_IDENTIFIER" }, async (publicIdentifier) => {
            console.log("Got response of public_identifier :::" , publicIdentifier)
            if(publicIdentifier){
                dispatch(setPublicIdentifier(publicIdentifier))
                const { user_summary } = await callYoriApi('/referral/user_summary' , "POST" , { body : JSON.stringify({"public_identifier" : publicIdentifier})})
                dispatch(setUserSummary(user_summary))
            }
        })
  
    }


    useEffect(() => {
        if(!userSummary){
            getPersonalizedMessageSummary()
        }
        getContactsFromBackend()
        // dispatch({
        //     type: INITIALIZE_SELECTED_USER_CONTACT
        // })
        state?.setQuery("")
        state?.setSearch("")
    }, [])



    const handleOnNext = () => {
        if (currentPage !== totalPages) {
            console.log(`CURRENT PAGE + 1. NEW PAGE :`, (currentPage + 1))
            setCurrentPage(currentPage + 1)
        }
    }
    const handleOnPrev = () => {
        if (currentPage !== 1) {
            console.log(`CURRENT PAGE - 1. NEW PAGE :`, (currentPage - 1))
            setCurrentPage(currentPage - 1)
        }
    }

    let delay

    const autoSuggestContacts = (value) => {
        state?.setSearch(value)
        if (value == '') {
            setContactsNotReferred([...contacts])
            setSuggestedContactsList([])
        }
        state?.setQuery(value)
    }

    // const [sendingReferralsUsers, setSendingReferralsUsers] = useState(selectedUserContacts)


    // useEffect(() => {
    //     setSendingReferralsUsers([...(new Set([...sendingReferralsUsers, ...selectedUserContacts]))])
    // }, [selectedUserContacts])

    const filterUsers = value => {
        let filterData = contacts?.filter(elem => (
            (elem?.contact?.first_name + " " + elem?.contact?.last_name)?.toLowerCase()?.includes(value?.toLowerCase()) || (elem?.contact?.headline)?.toLowerCase()?.includes(value?.toLowerCase())
        ))

        let idsOfFilterData = filterData.map((contact , index) => contact.id)
        let alreadySelectedUserContacts = selectedUserContacts ? selectedUserContacts.filter(contact => !idsOfFilterData.includes(contact.id)) : []
        let arrayToSpread = [...filterData]

        setSuggestedContactsList([...arrayToSpread]);
        setContactsNotReferred([...arrayToSpread])
    };

    useEffect(() => {
        setContactsNotReferred(contacts)
    }, [contacts])

    useEffect(() => {
        if (currentPage != 1) {
            setCurrentPage(1)
        }
        if (state?.query) {
            delay = setTimeout(() => {
                // if (state?.query) {
                filterUsers(state?.query)
                // }
            }, 500)
            return () => clearTimeout(delay)
        }
    }, [state?.query, showMessagesSendingCards, state?.sendingInvites])


    const selectAllOnPageAndSend = () => {
        const currentPageProfiles = contactsNotReferred.slice(minContactCount - 1, maxContactCount)
        const currentPageProfilesNotSent = currentPageProfiles.filter(profile => profile.is_referal_sent == false)


        if (currentPageProfilesNotSent.length > 0) {
            // let filtered_contacts = currentPageProfilesNotSent.slice(0, 10 - countSelected)
            // filtered_contacts?.map(profile => {
            //     dispatch(selectSelectedUserContacts(true, profile.id))
            // })
            // if (filtered_contacts.length + countSelected >= 10) {
            //     showMessage()
            // }
            currentPageProfilesNotSent.map(profile => {
                dispatch(selectSelectedUserContacts(true, profile.id))
            })


            // selectedUserContacts?.every(item => item.id != profile.id)
            // setSendingInvites(true)
        }
    }



    const getInviteStatus = (isSent, hasFailed) => isSent ? "approved" : hasFailed ? "rejected" : null


    const showSendModal = (type) => {
        setOpenModal(true)
        setSendInvitesType(type)
    }

    useEffect(() => {
        if (sendInvitesType) {
            if (sendInvitesType === "selected_success") {
                //looky here prime
                selectedUserContacts?.map(contact => contact?.id)?.map(id => dispatch(setInvitationSendingCheck(true, id)))
                // state?.setSendingInvites(true)
                state?.startSendingMessages()
            }
            else if (sendInvitesType === "all_on_page_success") {
                selectAllOnPageAndSend()
            }
        }
    }, [sendInvitesType])





    // useEffect(() => {
    //     console.log("contactsNotReferred" , {contactsNotReferred})
    // }, [contactsNotReferred , currentlySending , selectedUserContacts])

    const showMessage = () => {
        notify("You can select max 10 contacts in a batch", "info")
    }


    return <div className='invitations_credits'>

        {/* Modals */}

        {/* <SendInvitesModal 
            open={openModal}
            onClose={() => setOpenModal(false)} 
            selected={selectedUserContacts.length} 
            setSendInvitesType={setSendInvitesType} 
            sendInvitesType={sendInvitesType} 
        /> */}


        <SendInvitesModalUpdated 
            open={openModal}
            onClose={() => setOpenModal(false)} 
            selected={selectedUserContacts.length} 
            setSendInvitesType={setSendInvitesType} 
            sendInvitesType={sendInvitesType} 
        />

        <SelectedUserContactsModal
            open={selectedContactsModalOpen}
            onClose={() => setSelectedContactsModalOpen(false)}
            onInviteButtonClick={() => showSendModal("selected")}
            sendingInvites={state?.sendingInvites}
        />

        <CustomToastContainer />
        <MainHeader />
        {/* Modal Will open When Invite button clicked */}
        <div className='invitations_container'>

            {
                showMessagesSendingCards ?
                    <div className='invitations_left'>
                        <div className="invitations_top_bar" style={{ display: "flex", justifyContent: "start", gap: "0.4rem" }}>
                            <span style={{ cursor: 'pointer' }} >
                                <img onClick={() => showMessagesSendingCards && dispatch(setShowMessagesSendingCards(false)) } src={fullLeftArrow} />
                            </span>
                            <h2>Back</h2>
                        </div>
                        <div
                            className="invites_section_container"
                        >
                            <div className='invites_section'>
                                <ul>
                                    <li className={`${flag && temp ? "invites_active" : ""}`} onClick={() => { setFlag(true); setTemp(true) }}>LinkedIn</li>
                                    {/* <li className={`${!flag && temp ? "invites_active":""}`} onClick={()=>{setFlag(false); setTemp(true)}}>Email</li>
                                    <li className={`${!temp ? "invites_active":""}`} onClick={()=>setTemp(false)}>Social</li> */}
                                </ul>
                                <div className='invite_profiles'>
                                    {
                                        //looky here prime
                                        selectedUserContacts?.filter((card, ind) => card?.is_in_sending_flow)?.sort(function(a, b){return a.id - b.id}).map((card, ind) => (
                                            <InviteProfileCheckCard
                                                key={ind}
                                                profileId={card?.id}
                                                last_name={card?.contact?.last_name}
                                                first_name={card?.contact?.first_name}
                                                profile_picture={card?.contact?.profile_picture}
                                                headline={card?.contact?.headline}
                                                isLoading={card?.isSending}
                                                imageLink={`https://qlu-media-test.s3.amazonaws.com/${card?.contact?.public_identifier}.jpg`}
                                                inviteStatus={getInviteStatus(card?.isSent, card?.hasFailed)}
                                                hasCheck={false}
                                                isReferralSent={card?.isSent}
                                                public_identifier = {card?.contact?.public_identifier}
                                                messageSendingState={card?.messageSendingState}
                                            />))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='invitations_left'>
                        <div className="invitations_top_bar" style={{ display: "flex", justifyContent: "start", gap: "0.4rem" }}>
                            <span>
                                <img onClick={() => navigate("/invitations_credits")} src={fullLeftArrow} />
                            </span>
                            <h2>Back</h2>
                        </div>
                        {
                            mountLoading ? 
                            <>
                                <span
                                    style={{alignSelf: 'center'}}
                                >
                                    <LoadingSpinner></LoadingSpinner>
                                </span>
                            </> :
                            <>
                                <div
                                    className="invites_section_container"
                                >
                                    <div className='invites_section'>
                                        <ul>
                                            <li className={`${flag && temp ? "invites_active" : ""}`} onClick={() => { setFlag(true); setTemp(true) }}>LinkedIn</li>
                                            {/* <li className={`${!flag && temp ? "invites_active":""}`} onClick={()=>{setFlag(false); setTemp(true)}}>Email</li>
                                        <li className={`${!temp ? "invites_active":""}`} onClick={()=>setTemp(false)}>Social</li> */}
                                        </ul>
                                        <div className='invite_more_header'>
                                            <span>
                                                <SearchInput mountLoading={mountLoading} placeholder={"Search by name or headline "} control={{ "search": state?.search, autoSuggestContacts }} />
                                            </span>
                                            <div>
                                                <p>{selectedUserContacts.length != 0 && state?.sendingInvites && !showMessagesSendingCards ? <span style={{ cursor: "pointer" }} onClick={() => { dispatch(setShowMessagesSendingCards(true)) }}>View Sending</span> : <></>}</p>
                                                <p>{selectedUserContacts.length > 0 ? <span style={{ cursor: "pointer" }} onClick={() => { setSelectedContactsModalOpen(true) }}>{`${selectedUserContacts.length} Selected`}</span> : <></>}</p>
                                                <button 
                                                    className='invite_btn' 
                                                    onClick={() => selectAllOnPageAndSend()} 
                                                    disabled={minContactCount - 1 == maxContactCount} 
                                                    
                                                >
                                                    Select all on Page
                                                </button>
                                                <PrimaryButton 
                                                    variant='Beta' 
                                                    style={
                                                        (selectedUserContacts.length === 0 || state?.sendingInvites) 
                                                        ? 
                                                        { 
                                                            cursor: "not-allowed", 
                                                            fontSize: "13px", 
                                                            padding: "0.2rem 0.7rem"
                                                        } 
                                                        : 
                                                        {
                                                            fontSize: "13px", 
                                                            padding: "0.2rem 0.7rem"
                                                        }
                                                    }
                                                    onClick={() => showSendModal("selected")} 
                                                    disabled={selectedUserContacts.length == 0 || state?.sendingInvites}
                                                >
                                                    Invite selected
                                                </PrimaryButton>
                                            </div>
                                        </div >
                                        <FooterPagination
                                            minCount={minContactCount}
                                            maxCount={maxContactCount}
                                            total={totalContacts}
                                            currentPage={currentPage}
                                            totlaPages={totalPages}
                                            onNext={handleOnNext}
                                            onPrev={handleOnPrev}
                                        >
                                            <div className='invite_profiles'>
                                                {
                                                    contactsNotReferred.slice(minContactCount - 1, maxContactCount)
                                                        .map((card, ind) =>
                                                            <InviteProfileCheckCard
                                                                key={ind}
                                                                profileId={card?.id}
                                                                last_name={card?.contact?.last_name}
                                                                first_name={card?.contact?.first_name}
                                                                profile_picture={card?.contact?.profile_picture}
                                                                headline={card?.contact?.headline}
                                                                hasCheck={true}
                                                                public_identifier = {card?.contact?.public_identifier}
                                                                isReferralSent={card?.is_referal_sent}
                                                                imageLink={`https://qlu-media-test.s3.amazonaws.com/${card?.contact?.public_identifier}.jpg`}
                                                                showMessage={showMessage}
                                                                isInSendingFlow={card?.is_in_sending_flow}
                                                            />)
                                                }
                                            </div>

                                        </FooterPagination>
                                    </div >
                                </div >
                            </>
                        }
                    </div >
            }

        </div >

    </div >
}

export default SendInvites