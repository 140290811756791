import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ButtonPrimary from '../../../../components/ButtonPrimary'
import PrimaryButton from '../../../../components/PrimaryButton'
import { packages } from '../../../../utils'
import "./style.scss"

function UpdateSubscription({ subscription_id, walletDetails }) {
    console.log("Wallet credits :", walletDetails)

    const [button1, setbutton1] = useState(subscription_id == 1 ? true : false)
    const [button2, setbutton2] = useState(subscription_id == 2 ? true : false)
    const [button3, setbutton3] = useState(subscription_id == 3 ? true : false)

    const credits = useSelector(state => state?.auth?.wallet?.credits)
    const activeCondition = button1 && "btn1" || button2 && "btn2" || button3 && "btn3"

    console.log("123123123321123....", (subscription_id == 1 && activeCondition == 'btn1' || subscription_id == 2 && activeCondition == 'btn2' || subscription_id == 3 && activeCondition == 'btn3') && credits != 0)
    console.log("123123123321123...2.", (subscription_id == 1 && activeCondition == 'btn1' || subscription_id == 2 && activeCondition == 'btn2' || subscription_id == 3 && activeCondition == 'btn3'))
    console.log("123123123321123...3.", credits !== 0)
    return (
        <div className='update_subscription'>
            <div className='buttons'>
                <button className={`${button1 && "sub_active"}`} onClick={() => { setbutton1(true); setbutton2(false); setbutton3(false) }} ><strong> Free </strong> </button>
                <button className={`${button2 && "sub_active"}`} onClick={() => { setbutton1(false); setbutton2(true); setbutton3(false) }}> <strong>Gold </strong></button>
                <button className={`${button3 && "sub_active"}`} onClick={() => { setbutton1(false); setbutton2(false); setbutton3(true) }}> <strong> Platinum </strong></button>
            </div>
            <div className='paras'>
                <p className='heading'>
                    {
                        button1 && "Free"
                    }
                    {
                        button2 && "$29.99/mo"
                    }
                    {
                        button3 && "$59.99/mo"
                    }

                </p>
                <p>
                    {
                        button1 && `${packages[1].contacts} credits - auto renews every month   `
                    }
                    {
                        button2 && `${packages[2].contacts} credits - auto renews every month`
                    }
                    {
                        button3 && `${packages[3].contacts} credits - auto renews every month`
                    }
                </p>

                <span>
                    <Link to={"/wallet/upgrade_subscription"} style={{ textDecoration: "none" }} state={{ active: button1 && 1 || button2 && 2 || button3 && 3, walletDetails: walletDetails }}>
                        <PrimaryButton variant='Beta' disabled={subscription_id == 1 && activeCondition == 'btn1' || (subscription_id == 2 && activeCondition == 'btn2' || subscription_id == 3 && activeCondition == 'btn3') && credits != 0}>
                            Choose Plan

                        </PrimaryButton>
                    </Link>
                    {/* <ButtonPrimary text="Choose Plan"/> */}
                </span>
            </div>
        </div>
    )
}

export default UpdateSubscription