import React from 'react'
import linkedinlogo from "../../assets/icons/linkedinsocial.svg"
import twitter from "../../assets/icons/twitter.svg"
import whatsapp from "../../assets/icons/whatsapp.svg"
import copylink from "../../assets/icons/copylink.svg"

import "./style.scss"
import PrimaryButton from '../PrimaryButton'
import { useNavigate } from 'react-router'
import { invitationAcceptedMilestones } from '../../utils'

function InviteSocial() {

  const navigate = useNavigate()
  return (
    <div className='invite_social'>
      <h3>Earn {invitationAcceptedMilestones?.[1]} credits for each sign up</h3>
      <p>Invite friends who aren’t on Yori yet. Once they sign up, you both will get {invitationAcceptedMilestones?.[1]} credits each!</p>
      {/* <div className='linktext'>Yori.co/invite/johndoe</div> */}

      {/* <div className='social_icons'>
        <div>
          <div> <img src={copylink} /> Copy Link</div>
          <div className="linkedinIcon"> <img src={linkedinlogo} /> LinkedIn</div>
        </div>
        <div>
          <div> <img src={whatsapp} /> Whatsapp</div>
          <div> <img src={twitter} /> Twitter</div>
        </div>
      </div> */}

      <button style={{ fontSize: "14px", width: "100%", textAlign: "center" }} onClick={() => navigate("/invitations_credits")}>Invite on LinkedIn</button>
    </div>
  )
}

export default InviteSocial