import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setMessageTemplate } from '../../actions/referrals';
import { handleTagRemoval, invitationModalTags, invitationModalTags2 } from '../../utils';
import EyeSvg from '../EyeSvg';
import PlusButton from '../PlusButton';

import fullLeftArrow from "../../assets/icons/arrow-right.svg"

import "./style.scss"


const SendInvitesModalUpdated = ({ open, onClose, selected, sendInvitesType, setSendInvitesType }) => {
  // const message = useSelector()
  const _ = require('lodash');


  let inputRef = React.useRef(null)
  const { messageTemplate } = useSelector(state => state.referrals?.invitations)
  const { userSummary } = useSelector(state => state.auth?.user)

  const [message, setMessage] = useState(messageTemplate)
  const [eyeActive, setEyeActive] = useState(false)
  const [previewingMessageTemplate, setPreviewingMessageTemplate] = useState(false)
  const [dropdownActive, setDropdownActive] = useState(false)


  const dispatch = useDispatch()


  if (!open) return null;

  const fullNameFromLocalStrg = localStorage.getItem("name") || '{Your Full Name}'
  const firstNameFromLocalStrg = localStorage.getItem("name")?.split(" ")[0] || '{Your First Name}'

  const handleChange = (value) => {
    let newValue = ""
    if (value?.length < message?.length) {
      newValue = handleTagRemoval(invitationModalTags2, message, value, inputRef)
      setMessage(newValue.value)
    }
    else if (value?.length > message?.length) {
      newValue = { 'value': value, min: inputRef.current.selectionStart, max: inputRef.current.selectionEnd }
      setMessage(newValue.value)
    }
    if (newValue.min >= 0) {
      setTimeout(() => {
        inputRef.current.selectionStart = newValue.min
        inputRef.current.selectionEnd = newValue.min
      }, 0)
    }
  }

  const handleCancel = () => {
    setMessage(messageTemplate)
    setPreviewingMessageTemplate(false)
    onClose()
  }

  const startSendingReferrals = () => {
    setSendInvitesType(sendInvitesType + "_success")
    dispatch(setMessageTemplate(message))
    onClose()
  }

  const insertDraft = (draft) => {

    const start = inputRef?.current?.selectionStart
    const end = inputRef?.current?.selectionEnd
    if (start != end) {
      setMessage(
        message.replace(message.split('').splice(start, end - start - 1).join(''), '').split('').fill(draft, start, start + 1).join('')
      )
    }
    else if (start == end) {
      setMessage(message.slice(0, start) + draft + message.slice(start))
    }
    document.getElementById('textArea').focus()
    setTimeout(() => {
      inputRef.current.selectionStart = start + draft?.length
      inputRef.current.selectionEnd = start + draft?.length
    }, 0)
  }


  const draftOptions = [
    {
      text: "Recipient’s first name",
      onClick: () => { insertDraft("{recipient’s first name}") }
    },
    {
      text: "Recipient’s full name",
      onClick: () => { insertDraft("{recipient’s full name}") }
    },
    {
      text: "Recipient’s summary",
      onClick: () => { insertDraft("{recipient’s summary}") }
    },
    // {
    //   text: "Recipient’s top skills",
    //   onClick: () => { insertDraft("{recipient’s top skills}") }
    // },
    // {
    //   text: "Recipient’s top companies",
    //   onClick: () => { insertDraft("{recipient’s top companies}") }
    // },
    {
      text: "Invite Link",
      onClick: () => { insertDraft("{link}") }
    },
    {
      text: "My first name",
      onClick: () => { insertDraft("{my first name}") }
    },
    {
      text: "My full name",
      onClick: () => { insertDraft("{my full name}") }
    },
  ]

  const handleShowPreview = () => {
    setPreviewingMessageTemplate(prev => !prev)
  }


const ryanTemp = `Hey Ryan,
Check out your profile summary I created with Yori: 

Ryan Roslansky is the CEO of LinkedIn, the world's leading professional networking platform. He is passionate about connecting professionals around the world and empowering them to build meaningful careers. Ryan has a wide range of skills, including communication, product management, online advertising, monetization, strategy, social media, consumer products, SEO, entrepreneurship, PPC, real estate, digital strategy, business operations management, online marketing, web analytics, e-commerce, SEM, operations management, usability testing, machine learning, web 2.0, Google analytics, strategic partnerships social media marketing, LinkedIn campaign manager, LinkedIn sales insights, LinkedIn sales navigator, LinkedIn talent insights, UX, user-centered design, and growth marketing. Ryan currently resides in the United States.

Apart from doing this, Yori enables me to generate email content, contact info for 30M profiles, and any company’s details and financials - all on the go. In short, it assists me with sales and recruiting prospecting. I would love for you to try it out. If you sign up using this link, we both will get 100 credits each:

{link}`

const handleReplaceInTemplate = (input) => {
  return input?.replace('{recipient’s first name}' , firstNameFromLocalStrg)?.replace('{recipient’s full name}' , fullNameFromLocalStrg )?.replace("{my first name}" , "{sender's first name}")?.replace("{my full name}" , "{sender's full name}")?.replace("{recipient’s summary}" , `\n\n${userSummary}`)

  
} 


  return (
    <div onClick={() => handleCancel()} className='invitaitonModalOverlay'>
      <div
        onClick={(e) => {e.stopPropagation()}}
        className='invitationModalParent'
      >
        {
          previewingMessageTemplate ?
          <>
            <div
              className='invitaitonModalPreviewParent'
            >
              <span
                className='previewHeader'
              >
                <img onClick={handleShowPreview} src={fullLeftArrow}/>
                <h3>Sample message</h3>
              </span>
              <div
                className='previewBody'
              >
                {
                  userSummary ? 
                  <textarea
                    readOnly
                    value={handleReplaceInTemplate(message)}
                  >
                  </textarea>
                  :
                  <textarea
                    readOnly
                    value={ryanTemp}
                  >
                  </textarea>
                }
              </div>
              <div
                className='previewFooter'
              >
                <button onClick={handleShowPreview} className='previewFooterBackButton'>Back</button>
              </div>
            </div>
          </>
          :
          <>
            <div className='invitaitonModalHeader'>
              <span>
                <p>Send Invites</p>
                <p className='contacts'>{selected} contacts selected</p>
              </span>
              
              <span>
                <p>Invite Message</p>
                <button
                  onMouseLeave={() => setEyeActive(false)}
                  onMouseEnter={() => setEyeActive(true)}
                  onClick={handleShowPreview}
                >
                  <EyeSvg
                    active={eyeActive}
                  />
                </button>
              </span>

            </div>
            <div className='invitationModalBody'>
              <div className='invitationModalInputField'>
                  <textarea
                  id='textArea'
                  ref={inputRef}
                  value={message} onChange={(e) => handleChange(e.target.value)}
                  >
                  </textarea>
              </div>

              <span
                className='invitatonModalButtons'
              >
                {
                  draftOptions.map((draft, index) => {
                    return (
                      <PlusButton
                        key={index}
                        text={draft?.text}
                        onClick={draft?.onClick}
                      />
                    )
                  })
                }
              </span>

              <div className='invitaitonModalControlButtons'>

                <button className='cancel_btn' onClick={() => handleCancel()}>Cancel</button>
                <button
                  onClick={startSendingReferrals}
                >
                  Send
                </button>
              </div>

            </div>
          </>
        }
        </div>
    </div >
  );
};

export default SendInvitesModalUpdated