import React, { useEffect, useState } from 'react'

import edit from "../../assets/icons/edit.svg"
import deletelogo from "../../assets/icons/delete.svg"
import viewCrosslogo from "../../assets/icons/view_cross.svg"
import viewRightlogo from "../../assets/icons/view_right.svg"

import "./style.scss"
import { Link } from 'react-router-dom'
import { setSingleContactChecked } from '../../actions/lists'
import { useDispatch } from 'react-redux'
import { setTimeString } from '../../utils'

function ListTableRow({ active, row, functions, countSelected }) {

  const dispatch = useDispatch()
  const [name, setName] = useState(row.name)
  const { editTitle, deleteRow } = functions

  // const [contacts,setContacts] = useState("")
  const { entity_count, created_at } = row

  const [flag, setFlag] = useState(false)
  const [check, setCheck] = useState(false)


  const ShowEditInput = (id) => {
    setFlag(true)
  }

  const handleEdit = (id) => {
    editTitle(id, name)
    setFlag(false)
  }

  const controlCheck = (check, id) => {
    setCheck(check)
    dispatch(setSingleContactChecked({ check, id }))
    countSelected()
  }


  return (
    <div className='table_row'>
      <input type="checkbox" className="check" checked={row.check} onClick={(e) => controlCheck(e.target.checked, row.id)} />
      <div className='first'>
        {
          flag ? <span className="edit_input_section"><input type="text" value={name} onChange={e => setName(e.target.value)} />
            <img src={viewCrosslogo} onClick={() => { setFlag(false); setName(row.name) }} />
            <img src={viewRightlogo} onClick={() => handleEdit(row.id)} />
          </span>
            :
            <span className='listname' onClick={() => controlCheck(!check, row.id)}>{name}</span>
        }
        <span className='total_contacts'>{entity_count}</span>
        <span className='created_on'>{setTimeString(created_at)}</span>
      </div>
      <div className='row_btns'>
        <img src={deletelogo} onClick={(e) => deleteRow(row.id)} />
        <img src={edit} onClick={() => ShowEditInput(row.id)} />
        <button className='viewbtn'> <Link className='navlink' state={{ id: row.id, name: row.name, count: row.entity_count }} to={`${active ? "/lists/userlist" : "/lists/companylist"}`}>View</Link> </button>
      </div>
    </div>
  )
}

export default ListTableRow