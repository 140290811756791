import React, { useEffect, useState } from 'react'
import "./style.scss"
import DetailsBox from '../DetailsBox'
import SearchRow from '../SearchRow'

import leftarrow from "../../../assets/icons/leftarrow.svg"
import rightarrow from "../../../assets/icons/rightarrow.svg"

import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import LinkedInIcon from "../../../assets/icons/LinkedIn - Original.svg"
import yoriIcon from "../../../img/YoriLogo.svg"

import { useSelector } from 'react-redux'
import ProfileImageComp from '../ProfileImageComp'


function UserCompaniesViewed({ user }) {

    const {
        companies_viewed,
        companies_viewed_count,
    } = useSelector(state => state?.dashboard?.dashboard?.userProfile)


    const [search, setSearch] = useState("")
    const [tab, setTab] = useState(0)

    const [data, setData] = useState(companies_viewed)
    const [tableData, setTableData] = useState(data)


    useEffect(() => {
        setTableData(companies_viewed)
    }, [companies_viewed])


    useEffect(() => {
        if (search == '') {
            setTableData(data)
        } else {
            setTableData(data?.filter(company => company?.name?.toLowerCase()?.includes(search?.toLowerCase())))
        }
    }, [search])


    const [pageData, setPageData] = useState([])
    let [totalPages, setTotalPages] = useState(0)
    let [currentPage, setCurrentPage] = useState(1)

    const nextPage = () => {
        if (currentPage < totalPages) {
            setPageData(tableData?.slice(currentPage * 8, (currentPage + 1) * 8))

            setCurrentPage(currentPage + 1)

        }
    }
    const previousPage = () => {
        if (currentPage != 1) {
            setPageData(tableData?.slice((currentPage - 2) * 8, (currentPage - 1) * 8))

            setCurrentPage(currentPage - 1)
        }
    }

    useEffect(() => {
        setTotalPages(Math.ceil(tableData?.length / 8))
        setPageData(tableData?.slice(0, 8))
        // console.log(companies_viewed)
    }, [tableData])


    return (
        <div className='companiesViewed'>
            <div className='header'>
                <DetailsBox width="314.5px" title="Total Companies Viewed" value={companies_viewed_count || 0} />
                {/* <DetailsBox width="314.5px" title="Average Companies" value="195" select={true} /> */}
            </div>

            <SearchRow search={search} setSearch={setSearch} />

            <div className="usersTable">

                <MDBTable className="table">
                    <MDBTableHead className="tableHead">
                        <tr>
                            <th scope='col' style={{ width: "30%" }}>Companies</th>
                            {/* <th scope='col'>Industries</th> */}
                            <th scope='col'>Viewed on</th>
                            <th scope='col'>Date/time</th>
                            <th scope='col' style={{ textAlign: "right" }}>Views on yori by all users</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody className='usersTableBody'>
                        {
                            pageData?.length > 0 && pageData?.map((row, id) => {
                                return <tr className='tableRow' >
                                    <td style={{ width: "30%" }}>
                                        <div className='userName'>
                                            <ProfileImageComp
                                                name={row?.name?.trim()}
                                                src={row?.li_logo || ""}
                                                id={row?.name?.charAt(0)}
                                                width="24px"
                                                height="24px"
                                                fontSize="10px"
                                            />
                                            <p className='fw-bold mb-1'>{row?.name?.trim()?.length > 30 ? row?.name?.trim()?.slice(0, 30) + '...' : row?.name?.trim()}</p>
                                        </div>
                                    </td>
                                    {/* <td>
                                        <p className=''>Social Assistance</p>
                                    </td> */}
                                    <td style={{ display: "flex", alignItems: "center" }}>
                                        <img src={row?.context?.view ? yoriIcon : LinkedInIcon} width="25px" height="35px" />
                                    </td>
                                    <td>
                                        <p className=''>{new Date(row?.date).toLocaleString()}</p>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <p className=''>{row?.yori_views}</p>
                                    </td>

                                </tr>

                            })
                        }
                    </MDBTableBody>
                </MDBTable>
                <div className='table_footer'>
                    <div>
                        {(currentPage - 1) * 8} - {(currentPage * 8) < tableData?.length ? (currentPage * 8) : tableData?.length} of {tableData?.length}
                    </div>


                    <div className='table_footer_actions'>
                        <img src={leftarrow} onClick={previousPage} />
                        <span>Page {totalPages != 0 ? currentPage : currentPage - 1} of {totalPages}</span>
                        <img src={rightarrow} onClick={nextPage} />
                    </div >
                </div>

            </div >


        </div>
    )
}

export default UserCompaniesViewed