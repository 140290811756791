import React from 'react'
import caretPointingRight from "../../img/caretPointingRight.svg"
import caretPointingLeft from "../../img/caretPointingLeft.svg"

import "./style.scss"


const FooterPagination = ({
    minCount = 0,
    maxCount = 1,
    total = 1,
    currentPage = 1,
    totlaPages = 1,
    onPrev = (e) => false,
    onNext = (e) => false,
    children,
}) => {
  return (
    <React.Fragment>
    {children}
    <div
        className='footerPagination'
    >
        <span
            className='elementDetailsContainer'
        >
            <p>{minCount}-{maxCount} of {total}</p>
        </span>
        <span
            className='pageDetailsContainer'
        >
            <button
                onClick={onPrev}
            >
                <img
                    src={caretPointingLeft}
                />
            </button>
            <p>
                Page {currentPage} of {totlaPages}
            </p>
            <button
                onClick={onNext}
            >
                <img
                    src={caretPointingRight}
                />
            </button>
        </span>
    </div>
    </React.Fragment>
  )
}

export default FooterPagination