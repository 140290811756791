/*global chrome*/
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import {isMobile} from 'react-device-detect';
import EnableSection from '../../components/EnableSection'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import HeroSection from '../../components/HeroSection'
import HomeContainerPlaqueComponent from '../../components/HomeContainerPlaqueComponent'
import PillComponent from '../../components/PillComponent'
import WhySection from '../../components/WhySection'


import { pingExtension } from '../../MessagePassing'
import "./style.scss"
import RightArrow from '../../components/RightArrow'
import PlaquesComponent from '../../components/PlaquesComponent'
import ListInfoComponent from '../../components/ListInfoComponent'
import YoriPricingInfo from '../../components/YoriPricingInfo'
const Home = ({
    navigate
}) => {

    const [isInstallingExtension, setIsInstallingExtension] = useState(false)
    const [isInstallingInterval, setIsInstallingInterval] = useState(false)
    const isSignedIn = false

    const location = useLocation()


    useEffect(() => {
        // console.log("location :", location.pathname)
        if (location.pathname !== "/") {
            const path = location.pathname.replace("/", "")?.trim()
            console.log("link ==========:", path)
            localStorage.setItem("referal_link", path)

        }
        //asdaasd
        if (isInstallingExtension) {
            const pingFlag = pingExtension(flag => {
                if (flag) {
                    openSignup()
                }
                else {
                    window.open("https://chrome.google.com/webstore/detail/sqout/gaomncjabkjongmkfhcbdckmempnmgkm", "_self");
                    if (!isInstallingInterval) {
                        setIsInstallingInterval(setInterval(handleIsIntalling, 1000))
                    }
                }
            })
            if (!pingFlag) {
                window.open("https://chrome.google.com/webstore/detail/sqout/gaomncjabkjongmkfhcbdckmempnmgkm", "_self");
                if (!isInstallingInterval) {
                    setIsInstallingInterval(setInterval(handleIsIntalling, 1000))
                }
            }
        }
    }, [isInstallingExtension])

    const handleIsIntalling = () => {
        console.log("==========handleIsIntalling========0======")
        pingExtension(flag => {
            console.log("==========handleIsIntalling========1======")
            if (flag) {
                console.log("==========handleIsIntalling========2======")
                setIsInstallingInterval(prev => {
                    console.log("==========handleIsIntalling========3======", { prev })
                    clearInterval(prev);
                    return false
                })
                openSignup()

            }
        })
    }

    const openSignup = e => {
        if (isSignedIn) {

        }
        else {
            navigate("/signup")
        }
    }


    return (
        <div
            className='homeContainer'
        >
            <div>
                <Header
                    onGetExtension={e => setIsInstallingExtension(true)}
                />
                <HeroSection
                    onGetExtension={e => setIsInstallingExtension(true)}
                />
                <PlaquesComponent
                    onGetExtension={e => setIsInstallingExtension(true)}
                />
                <ListInfoComponent
                    onGetExtension={e => setIsInstallingExtension(true)}
                />
                <YoriPricingInfo
                    onGetExtension={e => setIsInstallingExtension(true)}
                />
            </div>
            <Footer /> 
        </div>
    )
}

export default Home