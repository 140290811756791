import React, { useEffect, useRef, useState } from 'react'
import ListTable from '../../components/ListTable'
import MainHeader from '../../components/MainHeader'
import contactslogo from "../../assets/icons/users.svg"
import companylogo from "../../assets/icons/company.svg"
import contactsblue from "../../assets/icons/usersblue.svg"
import comapnyblue from "../../assets/icons/companyblue.svg"

import "./style.scss"
import SearchInput from '../../components/SearchInput'
import ListTableRow from '../../components/ListTableRow'
import PrimaryButton from '../../components/PrimaryButton'

import { CSVLink } from "react-csv";

import { useSelector, useDispatch } from 'react-redux'
import { setContactsChecked, setFolders } from '../../actions/lists'
import LoadingSpinner from '../../components/LoadingSpinner'
import AddListModal from '../../components/AddListModel'
import refreshToken from '../../services/refreshToken'
import { awaitfunction, callYoriApi, invitationAcceptedMilestones, notify } from '../../utils'
import { ToastContainer } from 'react-toastify'
import { useLocation } from 'react-router'
import RewardNotificationModal from '../../components/RewardNotificationModal'


const Lists = ({ navigate }) => {
    const dispatch = useDispatch()

    const TOKEN_EXPIRE_MESSAGE = "token expired"
    const [signUpModalOpen, setsignUpModalOpen] = useState(false)
    const [rewardTypeCheck, setRewardTypeCheck] = useState('referralSignUp')


    const [isloading, setIsLoading] = useState(false)
    const params = useLocation()?.state


    // notify new user with toast for 25 contacts
    const showSignUpReward = () => {
        if (params?.showAlert) {
            setsignUpModalOpen(true)
            window.history.replaceState({}, document.title)
        }
    }

    const data = [
        { id: "2", check: false, name: "Top Leads for Qlu", entity_count: 45, created_at: "Sept 12, 2021" }
    ]

    const userState = useSelector(state => state?.auth)
    const getContacts = async () => {

        setIsLoading(true)

        // fetch
        await callYoriApi(`/folders/contact/`, "GET")
            .then(resdata => {

                // console.log("contacts response :", resdata)

                if (resdata?.length > 0) {
                    resdata = resdata.map(r => { return { ...r, check: false } })
                    dispatch(setFolders(resdata))
                }
                else
                    dispatch(setFolders([]))

                setIsLoading(false)
            }).catch(err => {
                dispatch(setFolders([]))

                console.log("err :", err)
                setIsLoading(false)
            })

    }


    const getCompanies = async () => {
        setIsLoading(true)

        // fetch
        callYoriApi(`/folders/companies/`, "GET")
            .then(resdata => {
                console.log("Companies response :", resdata)

                if (resdata?.length > 0) {
                    resdata = resdata.map(r => { return { ...r, check: false } })
                    dispatch(setFolders(resdata))
                }
                else
                    dispatch(setFolders([]))

                setIsLoading(false)

            }).catch(err => {
                dispatch(setFolders([]))
                setIsLoading(false)

            })
    }


    const handleContactActive = () => {
        getContacts()
        setActive(true)
    }
    const handleCompanyActive = () => {
        getCompanies()
        setActive(false)
    }

    useEffect(async () => {
        await getContacts()
        showSignUpReward()
    }, [])

    const editTitle = async (id, name) => {
        console.log("Edit :", id)
        // hit edit api
        setIsLoading(true)

        // fetch
        callYoriApi(`/folders/${active ? 'contact' : 'companies'}/${id}`, "PATCH", {
            body: JSON.stringify({ name: name })
        })
            .then(res => {
                console.log("Edit Title res :", res)
                // and fetch again data
                active ? getContacts() : getCompanies()
                setIsLoading(false)
            }
            )
            .catch(err => {
                setIsLoading(false)
                console.log("Edit Title Err:", err)
            })

    }

    const deleteRow = async (id) => {
        console.log("Delete id-----------:", id)

        setIsLoading(true)

        let route = active ? '/folders/contact' : '/folders/companies'
        // hit delete row api
        // fetch
        callYoriApi(`${route}/${id}`, "DELETE")
            .then(res => {
                console.log("Delete Res :", res)


                active ? getContacts() : getCompanies()
                setIsLoading(false)

            }
            ).catch(err => {
                setIsLoading(false)
                console.log("Delete :", err)
            }
            )
    }
    const list = useSelector(state => state?.lists?.folders?.folders)

    const deleteSelected = async () => {
        console.log("delete selected")
        const selected_ids = list.filter(company => company.check)
        let ids = []
        selected_ids.map(selected => ids = [...ids, selected.id])

        console.log("selected_ids :", ids)

        setIsLoading(true)
        let route = active ? '/folders/contact/' : '/folders/companies/'

        // fetch
        callYoriApi(`${route}`, "DELETE", { body: JSON.stringify(ids), })
            .then(mess => {
                console.log("mess :", mess);


                setIsLoading(false);
                active ? getContacts() : getCompanies()
            })
            .catch(err => {
                console.log("Del Err :", err);
                setIsLoading(false)
            })


        setIsLoading(false)
    }


    const [filedata, setFileData] = useState([])
    const fileref = useRef()

    const headers = [
        { label: "List Name", key: "folder_name" },
        { label: "name", key: "name" },
        { label: "Company Name", key: "company_name" },
        { label: "Title", key: "title" },
        { label: "Location", key: "location" },
        { label: "Phone", key: "phone" },
        { label: "Work Email", key: "work_email" },
        { label: "Email", key: "email" },
    ];
    const header2 = [
        { label: "List Name", key: "folder_name" },
        { label: "Name", key: "name" },
        { label: "Size", key: "size" },
        { label: "Location", key: "hq_location" },
        { label: "Company Type", key: "ipo_status" },
        { label: "website", key: "website" },

    ]

    const dowlondSelected = () => {
        const selected_ids = list.filter(company => company.check)
        let dowlond_data = []
        let ids = selected_ids.map(row => row.id)

        const route = active ? "/entities/contact/get" : "/entities/companies/get"

        // fetch
        callYoriApi(`${route}`, "POST", { body: JSON.stringify(ids) })
            .then(data => {
                console.log("data :", data)

                active ?
                    data.map(selected => dowlond_data = [...dowlond_data,
                    {
                        folder_name: selected.folder_name,
                        name: selected.name,
                        company_name: selected.company_name,
                        title: selected.title,
                        location: selected.location,
                        phone: selected.phone,
                        work_email: selected.work_email,
                        email: selected.email,

                        created_at: selected.created_at
                    }
                    ])
                    :
                    data.map(selected => dowlond_data = [...dowlond_data,
                    {
                        folder_name: selected.folder_name,
                        name: selected.name,
                        size: selected.size,
                        hq_location: selected.hq_location,
                        ipo_status: selected.ipo_status,
                        website: selected.website,
                        created_at: selected.created_at
                    }

                    ])

                setFileData(dowlond_data)
                setTimeout(() => fileref.current.link.click(), 1000)
                console.log("data :", dowlond_data)
            }

            )
            .catch(err =>
                console.log("err :", err)
            )
    }

    const [active, setActive] = useState(true)

    const [checkFlag, setCheckFlag] = useState(false)
    const handleCheckFlag = (e) => {
        setCheckFlag(e.target.checked)
        dispatch(setContactsChecked(e.target.checked))
        if (e.target.checked)
            setCount(list.length)
        else
            setCount(0)
    }

    const [count, setCount] = useState(0)
    const countSelected = () => {
        setCount(list.filter(lis => lis.check == true).length)
    }

    const [search, setSearch] = useState("")
    const [openModal, setOpenModal] = useState(false);

    return (
        <div
            className={`listContainer ${isloading && "dim"}`}
        >

            <RewardNotificationModal
                open={signUpModalOpen}
                onClose={() => { setsignUpModalOpen(false); }}
                rewardDetails={{
                    rewardType: rewardTypeCheck,
                    rewardAmount: invitationAcceptedMilestones?.[1],
                }}
            />
            <AddListModal open={openModal}
                onClose={() => { setOpenModal(false); active ? getContacts() : getCompanies() }} active={active} />
            <MainHeader />

            <div className='listBody'>
                <h3>My Lists </h3>
                <div className='list__nav'>
                    <div className='list_titles'>
                        <div className={`${active && "list_nav--active"}`} onClick={handleContactActive}><img src={active ? contactsblue : contactslogo} /> <span>Contacts</span> </div>
                        <div className={`${!active && "list_nav--active"}`} onClick={handleCompanyActive}><img src={!active ? comapnyblue : companylogo} /> <span>Companies</span> </div>
                    </div>
                    <div>
                        <SearchInput control={{ search, setSearch }} placeholder={active ? "Search Contacts Lists" : "Search Companies Lists"} />
                        {/* <ButtonPrimary text="+ New List" /> */}
                        <PrimaryButton
                            style={{
                                // borderRadius: '100px',
                                width: '100%',
                                display: 'block',
                                textAlign: 'center',
                                padding: "0.4rem",
                                fontSize: "16px"
                            }}
                            variant='Beta'
                            // onClick={onClick}   
                            onClick={() => setOpenModal(true)}
                        >
                            + New List
                        </PrimaryButton>
                    </div>
                </div>

                <div className='table_header'>
                    <div>
                        <input type="checkbox" className='check' onClick={handleCheckFlag} /> {count} lists selected
                    </div>
                    {count > 0 ? <div>
                        <button className='deletebtn' disabled={count == 0} style={count == 0 ? { cursor: "not-allowed" } : { cursor: "pointer" }} onClick={deleteSelected}>Delete</button>
                        <button className='dowlondbtn' disabled={count == 0} style={count == 0 ? { cursor: "not-allowed" } : { cursor: "pointer" }} onClick={dowlondSelected}>Download</button>
                        <CSVLink
                            filename='selected_data.csv'
                            target="_self"
                            data={filedata}
                            headers={active ? headers : header2}
                            ref={fileref}
                            style={{ display: "hidden" }}

                        >

                        </CSVLink>
                    </div>
                        : <div></div>}
                </div>
                <ListTable
                    data="folders"
                    search={search}
                    functions={{ editTitle, deleteRow }}
                    countSelected={countSelected}
                    TableRow={ListTableRow}
                    active={active}
                    titles={["List Name", active ? "Total Contacts" : "Total Companies", "Created On"]}
                />

            </div>

            {
                isloading &&
                <LoadingSpinner />
            }

        </div>
    )
}

export default Lists