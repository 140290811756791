import React from 'react'
import "./style.scss"

function Circles({ outerColor, innerColor }) {
    return (
        <div id="outer-circle" style={{ backgroundColor: outerColor }}>
            <div id="inner-circle" style={{ backgroundColor: innerColor }}>
            </div>
        </div>
    )
}

export default Circles