import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


export function PieChart({ values }) {
    const data = {
        labels: ['Organic Signup', 'Invited Customer'],
        datasets: [
            {
                label: ' # ',
                data: values,
                backgroundColor: [
                    'rgb(75, 68, 225)',
                    'rgb(23, 169, 143)',
                ],
                // borderColor: [
                //     'rgba(255, 99, 132, 1)',
                //     'rgba(54, 162, 235, 1)',
                // ],
                // borderWidth: 1,
            },
        ],
    };
    return <div style={{ width: "150px", height: "150px" }}>
        <Pie data={data} width={50}
            height={50}
            options={{
                plugins: {
                    legend: {
                        display: false
                    },
                }
            }}
        />
    </div>

}
