import * as ActionTypes from "../../actions/lists";

const initialState = {
    folders:[]
};

export const folders = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_FOLDERS:
      return {
        ...state,
        folders:[...action.payload],
      };
    case ActionTypes.SET_CONTACTS_CHECKED:
      return {
        ...state,
        folders:state.folders.map(folder=>{return {...folder,check:action.payload}}),        
      }
    case ActionTypes.SET_CONTACT_CHECKED:
      return {
        ...state,
        folders:state.folders.map(folder=>{
          if(folder.id==action.payload.id) 
             folder.check=action.payload.check
          return {...folder}}),        
      }
    
    default:
      return state;
  }
};
