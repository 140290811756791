import React, { useState } from 'react'
import MainHeader from '../../components/MainHeader'
import "./style.scss"
// import profile from "../../assets/icons/profile.svg"
import profile from "../../assets/icons/profile.png"

import { getAuth } from 'firebase/auth'
import { useNavigate } from 'react-router'
import { setUser } from '../../actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from '../../utils'


function UserProfile() {

  const user = { name: localStorage.getItem("name"), email: localStorage.getItem("email") }
  const [name, setName] = useState(user?.name)
  const [email, setEmail] = useState(user?.email)
  const dispatch = useDispatch()
  // const user = useSelector(state=>state?.auth?.user)

  const navigate = useNavigate()

  return (
    <div className='user_profile'>
      <MainHeader />
      <div className='user_profile_container'>
        <h3>
          My Account
        </h3>
        <div className='user_profile_card'>
          <img src={profile} />
          <div>
            <p className='name'>{user?.user}</p>
            <p>{user?.email}</p>
          </div>
        </div>

        <div className='profile_edit'>
          <p>Email</p>
          <input type="text" readOnly={true} value={email} onChange={e => setEmail(e.target.value)} />

          <p>Name</p>
          <div>
            <input type="text" readOnly={true} value={name} onChange={e => setName(e.target.value)} />
            {/* <button className='editbtn'>Edit</button> */}
          </div>
          <button className='logout_btn' onClick={() => signOut(navigate)}>Logout</button>
        </div>
      </div>
    </div>
  )
}

export default UserProfile