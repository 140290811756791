import React from 'react'
import "./style.scss"
import heroImg from "../../img/heroImage.svg"
import heroImg2 from "../../img/heroImage2.svg"
import heroImg2Mob from "../../img/heroImage2Mob.svg"
import GetExtButton from '../GetExtButton'
import chromeLogo from '../../assets/icons/chrome.svg'
import { isMobile } from 'react-device-detect'
const HeroSection = ({
    onGetExtension
}) => {
    console.log("isMobile :", isMobile)

    return (
        <div
            className='heroSection'
        >
            <div
                className='heroSectionInteractable'
            >
                <h1>Your Sales and Recruiting Assistant</h1>
                {
                    !isMobile ?
                        <>

                            <button
                                onClick={onGetExtension}
                            >
                                <img src={chromeLogo} />
                                Get the free extension
                            </button>
                        </> : <></>
                }
            </div>
            <img src={heroImg2} />
            <img src={heroImg2Mob} />
        </div>
    )
}

export default HeroSection