import React, { useEffect, useState } from 'react'
import "./style.scss"
import DetailsBox from '../DetailsBox'
import SearchInput from '../../../components/SearchInput'
import searchButton from "../../../assets/icons/Funnel.svg"

import UsersThree from "../../../assets/icons/UsersThree.svg"
import ChartPieSlice from "../../../assets/icons/ChartPieSlice.svg"
import ShoppingBagOpen from "../../../assets/icons/ShoppingBagOpen.svg"


import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import LinkedInIcon from "../../../assets/icons/LinkedIn - Original.svg"


import leftarrow from "../../../assets/icons/leftarrow.svg"
import rightarrow from "../../../assets/icons/rightarrow.svg"
import { useNavigate } from 'react-router';
import SearchRow from '../SearchRow'
import { useSelector } from 'react-redux'
import ProfileImageComp from '../ProfileImageComp'

function UserReferrals({ user }) {


    const {
        user_contacts,
        accepted_count,
    } = useSelector(state => state?.dashboard?.dashboard?.userProfile)

    const [search, setSearch] = useState("")
    const [tab, setTab] = useState(0)

    const navigate = useNavigate()
    const [Data, setData] = useState(user_contacts)

    useEffect(() => {
        if (user_contacts?.length != 0) {
            setData(user_contacts)
        }
    }, [user_contacts])

    useEffect(() => {
        if (Data?.length != 0) {
            setTableData(Data)
        }
    }, [Data])

    useEffect(() => {
        if (search == '') {
            setTableData(Data)
        } else {
            setTableData(Data?.filter(({ contact }) => `${contact?.first_name} ${contact?.last_name}`?.toLowerCase()?.includes(search?.toLowerCase())))
        }
    }, [search])


    const [tableData, setTableData] = useState(Data)

    const [pageData, setPageData] = useState([])
    let [totalPages, setTotalPages] = useState(0)
    let [currentPage, setCurrentPage] = useState(1)

    const nextPage = () => {
        if (currentPage < totalPages) {
            setPageData(tableData?.slice(currentPage * 8, (currentPage + 1) * 8))

            setCurrentPage(currentPage + 1)

        }
    }
    const previousPage = () => {
        if (currentPage != 1) {
            setPageData(tableData?.slice((currentPage - 2) * 8, (currentPage - 1) * 8))

            setCurrentPage(currentPage - 1)
        }
    }

    useEffect(() => {
        setTotalPages(Math.ceil(tableData?.length / 8))
        setPageData(tableData?.slice(0, 8))
    }, [tableData])

    const filterData = (option) => {
        if (option === 'all') {
            setTab(0)
            setTableData(Data)
        }
        if (option === 'accepted') {
            setTab(1)
            setTableData(Data?.filter(referral => referral?.is_referal_accepted))
        }
        if (option === 'pending') {
            setTab(2)
            setTableData(Data?.filter(referral => !referral?.is_referal_accepted))
        }
    }



    return (
        <div className='userReferrals'>
            <div className='header'>
                <DetailsBox width="213px" title="Total Referrals Sent" value={user_contacts?.length} />
                <DetailsBox width="213px" title="Successful" value={accepted_count} />
                <DetailsBox width="213px" title="Average Acceptance" value={((accepted_count * 100) / user_contacts?.length || 0).toFixed(1) + "%" || 0} />
                {/* <DetailsBox width="213px" title="Email Vs Linked in" value="1:5" /> */}

            </div>

            <div className='mainSection'>
                <div className='leftSide'>
                    <div className='sideBarButtons'>
                        <div onClick={() => filterData("all")} className={`sideBarButton ${tab === 0 ? 'sideBarButtonActive' : ''}`} >
                            <img src={ChartPieSlice} />
                            <span> All Invitees</span>
                        </div>
                        <div onClick={() => filterData("accepted")} className={`sideBarButton ${tab === 1 ? 'sideBarButtonActive' : ''}`} >
                            <img src={UsersThree} />
                            <span> Successful</span>
                        </div>
                        <div onClick={() => filterData("pending")} className={`sideBarButton ${tab === 2 ? 'sideBarButtonActive' : ''}`}  >
                            <img src={ShoppingBagOpen} />
                            <span> Pending</span>
                        </div>
                    </div>
                </div>
                <div className='rightSide'>
                    <SearchRow search={search} setSearch={setSearch} />

                    <div className="usersTable">

                        <MDBTable className="table">
                            <MDBTableHead className="tableHead">
                                <tr>
                                    <th scope='col'>Invitees</th>
                                    <th scope='col'>Invite Channel</th>
                                    <th scope='col'>Invite Date/time</th>
                                    <th scope='col'>Message</th>
                                    <th scope='col' style={{ textAlign: "right" }}>Status</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody className='usersTableBody'>
                                {
                                    pageData?.length > 0 && pageData?.map((row, id) => {
                                        return <tr className='tableRow'>
                                            <td>
                                                <div className='userName'>
                                                    <ProfileImageComp
                                                        name={`${row?.contact?.first_name.trim()} ${row?.contact?.last_name.trim() || ''}`}
                                                        src={row?.contact?.profile_picture || ""}
                                                        id={row?.id}
                                                        width="24px"
                                                        height="24px"
                                                        fontSize="10px"
                                                    />
                                                    <p className='fw-bold mb-1'>{`${row?.contact?.first_name.trim()} ${row?.contact?.last_name.trim() || ''}`}</p>
                                                </div>
                                            </td>
                                            <td style={{ display: "flex", alignItems: "center" }}>
                                                <img src={LinkedInIcon} width="25px" height="35px" />
                                            </td>
                                            <td>
                                                <p className=''>{(new Date((row?.created_at)))?.toLocaleString()}</p>
                                            </td>
                                            <td>
                                                <p className=''>{`${row?.message ? `${row?.message?.slice(0, 20)}...` : 'Not Available'}`}</p>

                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <p className=''>{row?.is_referal_accepted ? 'Accepted' : 'Pending'}</p>
                                            </td>

                                        </tr>

                                    })
                                }
                            </MDBTableBody>
                        </MDBTable>
                        <div className='table_footer'>
                            <div>
                                {(currentPage - 1) * 8} - {(currentPage * 8) < tableData?.length ? (currentPage * 8) : tableData?.length} of {tableData?.length}
                            </div>


                            <div className='table_footer_actions'>
                                <img src={leftarrow} onClick={previousPage} />
                                <span>Page {totalPages != 0 ? currentPage : currentPage - 1} of {totalPages}</span>
                                <img src={rightarrow} onClick={nextPage} />
                            </div >
                        </div>

                    </div >



                </div>
            </div>


        </div>
    )
}

export default UserReferrals