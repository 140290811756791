import { ToastContainer } from "react-toastify"

const CustomToastContainer = ({ ...props }) => {
    return <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#333333", color: "White", width: "130%", marginLeft: "-40px", padding: "1rem", zIndex: 1 }}
        {...props}
    />

}

export default CustomToastContainer