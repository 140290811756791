import React from 'react'
import UserProfileCard from '../UserProfileCard'
import ArrowUpRight from "../../../assets/icons/ArrowUpRight.svg"

import "./style.scss"
import { getTimeToSubscriptionExp } from '../../../utils'

function UserDetails({ user }) {

    const subscriptionRemainingDays = getTimeToSubscriptionExp(parseInt(user?.subscription_updated_at))
    return (
        <div className='UserDetails'>
            <div className='ProfileSection'>
                <div>
                    <p>Personal Details</p>
                    <div>
                        <p className='label'>Name</p>
                        <p className='value'>{user?.first_name?.trim() + ' ' + user?.last_name}</p>
                        <p className='label'>Email</p>
                        <p className='value'>{user?.email}</p>
                        <p className='label'>User type</p>
                        <p className='userType'>{user?.referral_id ? `Referral by @${user?.referrer_name}` : 'Orgainc'}</p>

                    </div>
                </div>
            </div>

            {/* <div className='ProfileSection'>
                <div>
                    <p>Linkedin Profile</p>
                    <div>

                        <div className='box'>
                            <UserProfileCard name="Ashir Manzoor" title="Product Designer @ Qlu" />

                            <div className='link'>
                                <p>https://Linkedin.com/AshirManzoor</p>
                                <img src={ArrowUpRight} />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='ProfileSection'>
                <div>
                    <p>Credits</p>
                    <div>

                        <div className='box'>
                            <p className='credits'>{user?.credits} contacts</p>
                            <p className='label'>remaining</p>
                            <div className='line' ></div>
                            <div className='RneewRow'>
                                <p> Renews in {subscriptionRemainingDays} days</p>
                                <button style={{
                                    width: "86px",
                                    padding: "7px"
                                }}>Send Credits</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className='ProfileSection'>
                <div>
                    <p>Subscribed Plan</p>
                    <div>

                        <div className='box'>
                            <p className='credits'>
                                {
                                    user?.subscription_id == 1 ? 'Free' : (user?.subscription_id == 2 ? 'Gold' : (user?.subscription_id == 3 ? 'Platinum' : 'Invalid Subs Id'))
                                }
                            </p>
                            <p className='label'>Current Plan</p>
                            <div className='line' ></div>
                            <div className='RneewRow'>
                                <p> </p>
                                <button style={{
                                    width: "86px",
                                    padding: "7px"
                                }}>Change Plan</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDetails