import React from 'react'


import plus from "../../assets/icons/plus.svg"
import "./style.scss"

const PlusButton = ({
    text = "",
    onClick
}) => {
  return (
    <button
        className='plusButton'
        onClick={onClick}
    >
        <span>
            <img
                src={plus}
            />
            <p>
                {text}
            </p>
        </span>
    </button>
  )
}

export default PlusButton