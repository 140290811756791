import React, { useState } from 'react'
import './style.scss'
import PrimaryButton from '../PrimaryButton'

const AuthenticationStepForm = ({
    submitLabel='Next',
    inputPlaceholder='',
    onClick=e=>false
}) => {
    
    const [showPassword, setShowPassword] = useState(false)

    return (
        <div
            className='authenticationStepFormContainer'
        >
            <div>
                <input placeholder={inputPlaceholder}/>
            </div>
            <PrimaryButton 
                style={{
                    borderRadius: '100px',
                    width: '100%',
                    display: 'block',
                    textAlign: 'center'
                }}
                variant='Beta'
                onClick={onClick}    
            >
            {submitLabel}
            </PrimaryButton>
    </div>)
}

export default AuthenticationStepForm