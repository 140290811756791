import React, { useEffect, useState } from 'react';
import "./style.scss"
import closebtn from "../../assets/icons/view_cross.svg"
import PrimaryButton from '../PrimaryButton';
import refreshToken from '../../services/refreshToken';
import { callYoriApi } from '../../utils';

const AddListModal = ({ open, onClose, active }) => {
  const [searchText, setSearchText] = useState("")

  const [searchData, setSearchData] = useState([])
  // useEffect(() => {
  //   setSearchData(listitems.filter(li=> li.name.toLowerCase().includes(searchText.toLowerCase()) ))
  // }, [searchText])


  if (!open) return null;


  const createList = async () => {
    const route = active ? "/folders/contact/" : "/folders/companies/"
    if (searchText.trim()?.length > 0) {
      // const token = await refreshToken()
      // fetch
      callYoriApi(`${route}`, "POST", {
        body: JSON.stringify({ name: searchText })
      })
        .then(data => {
          console.log("data :", route, " : ", data);

          setSearchText("")
          onClose()
        }).catch(err =>
          console.log("err :".err)
        )
    }
  }



  return (
    <div onClick={onClose} className='overlay '>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className='modalContainer'
      >
        <div className='modalRight'>
          <div className='modal_header'>
            <h3>
              Save Contact
            </h3>
            <img src={closebtn} onClick={onClose} />
          </div>
          <div className='modalbody'>

            <p>Create New List</p>
            <input type="text" placeholder='Enter new List' value={searchText} onChange={(e) => setSearchText(e.target.value)} />

            {/* <div className='list'>
                    <p className='list_title'>Lists</p>

                   {
                        searchData?.length > 0 ? searchData.map(item=> 
                            <div className='model_listitem'> 
                                <input type="checkbox"/>
                                <div>
                                    <p>{item.name}</p>
                                    <p>{item.entity_count} Contacts</p>
                                </div>
                            </div>
                        )
                        :
                        <p>No results. Create and save to new list “first 20”</p>
                    } 


                </div>   
                
                */}

            <div className='btndiv'>
              <PrimaryButton variant='Beta' onClick={() => createList()} style={{ fontSize: "14px", padding: "0.3rem 0.8rem" }}>
                Create New list
              </PrimaryButton>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AddListModal;