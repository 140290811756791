import React from 'react'
import { useNavigate } from 'react-router'
// import sqoutSvg from "../../img/Sqout.svg"
// import sqoutLogoSvg from "../../img/SqoutLogo.svg"
import yoriSvg from "../../img/Yori.svg"
import yoriLogoSvg from "../../img/YoriLogo.svg"
import GetExtButton from '../GetExtButton'
import chromeLogo from '../../assets/icons/chrome.svg'
import burgerComp from '../../img/burgerComp.svg'

import "./style.scss"
import { isMobile } from 'react-device-detect'
const Header = ({
    onGetExtension=false
}) => {
    const push = useNavigate()
  return (
    <div
        className='headerContainer'
    >
        <span>
            <div    
                onClick={e=>push("/")}
            >
                <img
                    src={yoriLogoSvg}
                />
                <img
                    src={yoriSvg}
                />
            </div>
        </span>
        {
            !isMobile ? 
            <>
                <button
                    onClick={onGetExtension}
                >
                    <img src={chromeLogo}/>
                    Get the free extension
                </button>

                <button
                    onClick={onGetExtension}
                >
                    Get extension
                </button>
            </>:<></>
        }
    </div>
  )
}

export default Header