import { callYoriApi } from "../utils"
const _ = require('lodash');


export const INITIATE_DASHBOARD = "INITIATE_DASHBOARD"
export const INITIATE_DASHBOARD_FAILURE = "INITIATE_DASHBOARD_FAILURE"
export const FETCH_INITIAL_USERS = "FETCH_INITIAL_USERS"
export const fetchInitialUsers = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: INITIATE_DASHBOARD
        })
        // fetch
        const apiData = await callYoriApi('/admin/all_users', "POST" , { body: JSON.stringify({ starter_index: 0 }) })
        const users = apiData?.users
        const allUsersCount = apiData?.allUsersCount
        const allUsersIds = apiData?.allUsersIds
        const initialUsersSorted = _.orderBy(users, Object.keys(users[0]), 'asc')

        dispatch({
            type: FETCH_INITIAL_USERS,
            payload: {
                initialUsersSorted, 
                allUsersCount,
                allUsersIds
            }
        })
    }
    catch (err) {
        dispatch({
            type: INITIATE_DASHBOARD_FAILURE
        })
    }
}


export const FETCH_REMAINING_USERS_CHUNK = "FETCH_REMAINING_USERS_CHUNK"
export const fetchRemainingUsersChunk = (id) => async (dispatch, getState) => {

    const allUsers = getState().dashboard.dashboard.allUsers

    // console.log("Last ID for the next Chunk was this :::::::::::::" , allUsers[allUsers?.length - 1].id)

    const apiData = await callYoriApi('/admin/all_users', "POST" , { body: JSON.stringify({ starter_index: allUsers[allUsers?.length - 1].id }) })
    const users = apiData?.users
    const allUsersCount = apiData?.allUsersCount
    const remainingUsersSorted = _.orderBy(users, Object.keys(users[0]), 'asc')

    dispatch({
        type : FETCH_REMAINING_USERS_CHUNK,
        payload : {
            remainingUsersSorted,
            allUsersCount
        }
    })

}

export const INIT_USER_PROFILE = "INIT_USER_PROFILE"
export const SET_USER_PROFILE = "SET_USER_PROFILE"
export const setUserProfile = (id) => async (dispatch, getState) => {
    dispatch({ type: INIT_USER_PROFILE })
    const userProfile = await callYoriApi("/admin/user_profile", "POST", { body: JSON.stringify({ user_id: id }) })

    dispatch({
        type: SET_USER_PROFILE,
        payload: userProfile
    })
}

export const REFRESH_USER_ACTIVITY = "REFRESH_USER_ACTIVITY"
export const refreshUserActivity = (id) => async (dispatch, getState) => {

    const url = `/admin/user_activity`
    const {user_activity_response} = await callYoriApi(url , 'POST' , { body: JSON.stringify({id}) })
    
    dispatch({
        type: REFRESH_USER_ACTIVITY,
        payload : {user_activity_response}
    })
}
    
