import React from 'react'
import AuthBackgroundCover from '../../components/AuthBackgroundCover'
import yoriLogoAndText from '../../img/yoriLogoAndText.svg'

import "./style.scss"
import PrimaryButton from '../../components/PrimaryButton'
import { useLocation, useNavigate } from 'react-router'

const OnBoardingFlow = () => {
  const navigate = useNavigate()
  const params = useLocation()?.state

  const handleClick = () => {
    if(params?.is_new_user && params?.is_referral){
      navigate("/onboarding/reward_welcome", { state: { ...params } })
    }
    else if(params?.is_new_user && !params?.is_referral){
      navigate("/onboarding/intro_to_yori", { state: { ...params } })
    }
  }
  return (
    <div
      className='onBoardingFlow'
    >
      <span
        className='onBoardingLeft'
      >
        <img
            style={{
                alignSelf: 'flex-start',
                justifySelf: 'flex-start',
                marginLeft: '58px',
                marginTop: '32px',
            }}
            src={yoriLogoAndText}
        />
        <div
          className='onBoardingLeft_Inner'
        >
          <div
            className='headline'
          >
            <h1>You signed up!</h1>
            <h1>Here's how Yori works</h1>
          </div>
          <span
            className='listedItem'
          >
            <h2>1</h2>
            <p>On a company page, clicking the Yori icon shows you all about the company right there.</p>
          </span>
          <span
            className='listedItem'
          >
            <h2>2</h2>
            <p>And on a profile page, Access all about someone's contact and current company right on their profile.</p>
          </span>

          <PrimaryButton
            style={{
              width: '100%',
              marginTop: '85px',
              padding: '15px'
            }}
            onClick={handleClick}
          >
            <span
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  margin: '0px'
                }}
              >Next</p>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <p
                  style={{
                    margin: '0px'
                  }}
                >{'>'}</p>
                <p
                  style={{
                    margin: '0px'
                  }}
                >{'>'}</p>
                <p
                  style={{
                    margin: '0px'
                  }}
                >{'>'}</p>
              </div>
            </span>
          </PrimaryButton>

        </div>
      </span>
      <span>
        <AuthBackgroundCover/>
      </span>
    </div>
  )
}

export default OnBoardingFlow