import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

Legend.display = false;

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        }
    }
    //     title: {
    //         display: true,
    //         text: 'Chart.js Bar Chart',
    //     },
    // },
};


export function BarChart({ values, color, barChartLabels }) {
    const data = {
        labels: barChartLabels,
        datasets: [
            {
                label: " Views ",
                data: values,
                backgroundColor: color,
            },
            // {
            //     label: 'Dataset 2',
            //     data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
            // },
        ],
    };
    return <Bar options={options} data={data} width="530px" height="150px" />;
}
