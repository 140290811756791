/* global chrome */


import React, { useEffect, useState } from 'react'
import InvitationProfileCard from '../../components/InvitationProfileCard'
import InvitationsCount from '../../components/InvitationsCount'
import MainHeader from '../../components/MainHeader'
import "./style.scss"

import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../components/PrimaryButton'
import InvitedProfileCheckCard from '../../components/InviteProfileCheckCard/InvitedProfileCheckCard'
import SendInvitesModal from '../../components/SendInvitesModal'

import { useDispatch, useSelector } from 'react-redux'
import { callYoriApi, invitationAcceptedMilestones, invitationSentMilestones } from '../../utils'
import { initInvitationsCredits } from '../../actions/referrals'
import { setPublicIdentifier, setUserSummary } from '../../actions/auth'


function InvitationsCredits() {
    const dispatch = useDispatch()

    const [openModal, setOpenModal] = useState(false);
    const [flag, setFlag] = useState(true)
    const [invitesFlag, setInvitesflag] = useState(true)

    let {
        invitations_sent,
        invitations_accepted
    } = useSelector(state => state.referrals?.invitations)


    const getPersonalizedMessageSummary = async () =>{
        const extId = process.env.REACT_APP_EXT_ID

        chrome.runtime.sendMessage(extId, { type: "GET_USER_PUBLIC_IDENTIFIER" }, async (publicIdentifier) => {
            console.log("Got response of public_identifier :::" , publicIdentifier)
            if(publicIdentifier){
                dispatch(setPublicIdentifier(publicIdentifier))
                const { user_summary } = await callYoriApi('/referral/user_summary' , "POST" , { body : JSON.stringify({"public_identifier" : publicIdentifier})})
                dispatch(setUserSummary(user_summary))
            }
        })
  
    }
    
    const navigate = useNavigate()

    useEffect(() => {
        getPersonalizedMessageSummary()
        dispatch(initInvitationsCredits())
    }, [])


    // const sendInvites = () => {
    //     setInvitesflag(!invitesFlag)
    // }
    return (
        <div className='invitations_credits'>
            {/* <SendInvitesModal open={openModal}
                onClose={() => setOpenModal(false)} /> */}
            <MainHeader />
            {/* Modal Will open When Invite button clicked */}
            <div className='invitations_container'>
                {
                    invitesFlag &&
                    <div className='invitations_left'>
                        <div
                            className='invitations_top_bar'
                        >
                            <h2>Invitations</h2>
                            <PrimaryButton
                                style={{
                                    // borderRadius: '100px',
                                    // width: '100%',
                                    display: 'block',
                                    textAlign: 'center',
                                    padding: "0.4rem 0.7rem",
                                    fontSize: "14px"
                                }}
                                variant='Beta'
                                onClick={() => { navigate("/connect") }}
                            >
                                Send Invites
                            </PrimaryButton>


                            {/* <button  onClick={sendInvites} >Send Invites</button> */}
                        </div>
                        <div
                            className='invites_section_container'
                        >
                            <div className='count_section'>
                                <InvitationsCount
                                    numberOfProspects={invitations_sent.length}
                                    title="Invitations Sent"
                                    hasBottomBorder={true}
                                    packageMilestones={invitationSentMilestones}
                                />
                                <InvitationsCount
                                    numberOfProspects={invitations_accepted.length}
                                    hasBottomBorder={true}
                                    packageMilestones={invitationAcceptedMilestones}
                                    title="Invitations Accepted"
                                />
                            </div>
                            <div className='invites_section'>
                                <ul>
                                    <li className={`${flag ? "invites_active" : ""}`} style={{ cursor: "pointer" }} onClick={() => setFlag(true)}>Sent</li>
                                    <li className={`${!flag ? "invites_active" : ""}`} style={{ cursor: "pointer" }} onClick={() => setFlag(false)}>Accepted</li>
                                </ul>
                                {
                                    flag ? <div className='invite_profiles'>
                                        {
                                            invitations_sent.map((card, ind) => {
                                                return (
                                                    <InvitationProfileCard 
                                                        key={ind}
                                                        last_name={card?.profile_data?.last_name}
                                                        first_name={card?.profile_data?.first_name}
                                                        profile_picture={card?.profile_data?.profile_picture}
                                                        headline={card?.profile_data?.headline}
                                                        public_identifier = {card?.profile_data?.public_identifier}
                                                        imageLink={`https://qlu-media-test.s3.amazonaws.com/${card?.profile_data?.public_identifier}.jpg`}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                        :
                                        <div className='invite_profiles'>
                                            {
                                                invitations_accepted.map((card, ind) => {
                                                    return (
                                                        <InvitedProfileCheckCard 
                                                            key={ind}
                                                            last_name={card?.profile_data?.last_name}
                                                            first_name={card?.profile_data?.first_name}
                                                            profile_picture={card?.profile_data?.profile_picture}
                                                            headline={card?.profile_data?.headline}
                                                            imageLink={`https://qlu-media-test.s3.amazonaws.com/${card?.profile_data?.public_identifier}.jpg`}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {/* Send Invites  */}
                {
                    // !invitesFlag &&
                    // (

                    // )

                }



                {/* <div className='invitations_right'>
                <div >
                    <h2>Credits</h2>
                </div>
                <div className='count_section'>
                    <InvitationsCountactCount />
                </div>
                <div>
                    <InviteSocial />
                </div>
            </div> */}


            </div>
        </div>
    )
}

export default InvitationsCredits