import React from 'react'
import AuthBackgroundCover from '../../../components/AuthBackgroundCover'
import yoriLogo from '../../../img/YoriLogo.svg'
import yoriText from '../../../img/Yori.svg'
import yoriLogoAndText from '../../../img/yoriLogoAndText.svg'
import spreadLoveText from '../../../assets/icons/spreadLoveText.svg'
import inviteForMoneyBag from '../../../assets/icons/inviteForMoneyBag.svg'
import "./style.scss"
import PrimaryButton from '../../../components/PrimaryButton'
import { invitationAcceptedMilestones, invitationSentMilestones } from '../../../utils'
import { useNavigate } from 'react-router'

const IntroToYori = () => {

  const milestones = Object.keys(invitationSentMilestones)
  const rewards = Object.values(invitationSentMilestones)
  const navigate = useNavigate()

  const onSkipClick = () => {
    navigate("/lists")
    window.open(
      'https://www.linkedin.com/in/ryanroslansky/',
      '_blank'
    )
  }

  return (
    <div
      className='introToYori'
    >
      <span
        className='introToYoriLeft'
      >
        <img
          style={{
            alignSelf: 'flex-start',
            justifySelf: 'flex-start',
            marginLeft: '58px',
            marginTop: '32px',
          }}
          src={yoriLogoAndText}
        />
        <img
          style={{
            marginTop: '58px'
          }}
          src={spreadLoveText}
        />
        <img
          className='inviteForMoneyBag'
          src={inviteForMoneyBag}
        />

        <div
          className='introToYoriLeft_Inner'
        >
          <span
            className='listedItem'
          >
            <h2>1</h2>
            <p>Invite your friends to Yori, and earn {invitationSentMilestones?.[milestones?.[0]]} credits  for the first {milestones?.[0]} friends you invite! Earn more for more invites later on.</p>
          </span>
          <div
            className='invitationsDetails'
          >
            {
              milestones.map((key, index) => {
                return (
                  <span>
                    <p>Invite {key} friends</p>
                    <p>Get <span>{rewards[index]} credits</span></p>
                  </span>
                )
              })
            }
          </div>
          <span
            className='listedItem'
          >
            <h2>2</h2>
            <p>For every friends that signs up to yori using your invite, you both get {invitationAcceptedMilestones?.[1]} credits! Win win.</p>
          </span>

          <PrimaryButton
            style={{
              width: '100%',
              marginTop: '64px',
              padding: '15px'
            }}
            onClick={onSkipClick}
          >
            <span
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  margin: '0px'
                }}
              >Try out Yori on LinkedIn</p>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <p
                  style={{
                    margin: '0px'
                  }}
                >{'>'}</p>
                <p
                  style={{
                    margin: '0px'
                  }}
                >{'>'}</p>
                <p
                  style={{
                    margin: '0px'
                  }}
                >{'>'}</p>
              </div>
            </span>
          </PrimaryButton>
          <span
            className='skipRouting'
          >
            <a
              onClick={() => navigate("/invitations_credits")}
            >
              Invite mt friends first
            </a>
          </span>
        </div>
      </span>
      <span>
        <AuthBackgroundCover />
      </span>
    </div>
  )
}

export default IntroToYori