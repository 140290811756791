import React from 'react'
import profile from "../../assets/icons/pc1.svg"
import { capitalizeFirstAlphabet } from '../../utils'
import EllipsisTextComponent from '../EllipsisTextComponent'
import ProfileImageComponent from '../ProfileImageComponent'
import "./style.scss"

function InvitationProfileCard({
  last_name = "",
  first_name = "",
  headline = "",
  imageLink = '', 
  }) {
    
    // const {profile,name,title,from} = card
    // const {type,profile_picture,public_identifier,last_name,headline,first_name,entity_urn} = card

    // let avatar=""
    // if(!profile)
    // {
    //   const peices=name.split(" ")
    //   if(peices.length>=2)
    //     avatar = peices[0].slice(0,1) + peices[1].slice(0,1) 
    //   else if(peices.length==1)
    //     avatar = peices[0].slice(0,1) 
    //   else  {
    //     avatar="NA"
    //   }
    //   console.log("avatar :",peices.length,avatar.toUpperCase())
    // }

    
    let initials = ""

    const firstNameAlphabet = capitalizeFirstAlphabet(first_name?.trim())?.charAt(0)
    const lastNameAlphabet = capitalizeFirstAlphabet(last_name?.trim())?.charAt(0)
    initials = (firstNameAlphabet + lastNameAlphabet)


    // let extractionSource = type
    // let typeAlphabets = extractionSource.split("")
    // typeAlphabets[0] = typeAlphabets[0].toUpperCase()
    // extractionSource = typeAlphabets.join("")

  return (
    <div className='profile_card'>
      <ProfileImageComponent
        imageLink={imageLink}
        placeHolder={initials}
      />
      <div
        className='profileNameAndHeadline'
      >
          <p>{first_name+" "+last_name}</p>
          {
            headline && 
              <EllipsisTextComponent
                string={headline}
                stringLengthCap = {257}
              />
          }
          {/* <p>{public_identifier}</p> */}
          {/* <p>{type}</p> */}
      </div>
    </div>
  )
}

export default InvitationProfileCard