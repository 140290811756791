import React, { useState } from 'react'

import linkedin from "../../assets/icons/linkedin.svg"
import deletelogo from "../../assets/icons/delete.svg"
import userpic from "../../assets/icons/user.svg"
import companylogo from "../../assets/icons/randcompanylogo.svg"
import "./style.scss"
import { useDispatch } from 'react-redux'
import { setCompanyChecked } from '../../actions/lists'

function CompanyTableRow({row,functions,countSelected}) {

  const dispatch = useDispatch()
  const {deleteRow} = functions
  const [check,setCheck] = useState(false)

  const controlCheck = (check,id) =>{
    setCheck(check)
    dispatch(setCompanyChecked({check,id}))
    countSelected()
  }
  return (
    <div className='company_table_row'>
            <input type="checkbox" className="check" checked={row.check} onClick={(e)=>controlCheck(e.target.checked,row.id)}/>
            <div className='row'>
                <div className='listname' onClick={()=>controlCheck(!check,row.id)} > <img src={row.logo ? row.logo : row.li_logo}/> {row.name}</div>
                {/* <div className='listname'> <img src={userpic}/> {row.name}</div> */}
                <span className='title'>{row.staff_count}</span>
                <span >{row.hq_location} </span>
                <span >{row.ipo_status} </span>
                <span >{row.website} </span>
                
            </div>
            <div className='row_btns'>
                <img src={deletelogo} onClick={()=>deleteRow(row.id)}/>
                <a href={`https://www.linkedin.com/company/${row.universal_name}`} target="_blank">  <img src={linkedin} /> </a>
                <a href={`https://www.linkedin.com/company/${row.universal_name}`} target="_blank">  <button className='viewbtn'>View</button> </a>
            </div>
        </div>
  )
}

export default CompanyTableRow