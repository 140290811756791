import React from 'react'
import "./style.scss"
import spinner from '../../assets/messageSending.svg'
import successIcon from '../../assets/icons/greenCheckMark.svg'
import { useSelector } from 'react-redux'

function ShowTotalProgress({ 
    currentlySending, 
    total, 
    onClose = () => "", 
    cancelling= false,
    success = false 
}) {

    const {selectedUserContacts} = useSelector(state => state?.referrals?.userContacts)
    return (
        <>
            <div className='ShowTotalProgress'>
                <progress id="file" value={`${selectedUserContacts?.filter(card => card?.is_in_sending_flow)[currentlySending]?.messageSendingState}`} max='4' />
                <div>
                    <div>
                        {/* <div> */}
                        {/* <img src={success ? successIcon : spinner} width={`${success ? '35' : "50"}`} /> */}

                        {/* </div> */}
                        <p>
                            {cancelling ? 'Cancelling...' : success ? "All invites sent!" : "Sending invites..."}
                        </p>
                        <p>
                            {`${currentlySending + 1} of ${total}`}
                        </p>
                    </div>

                    <button onClick={onClose} className='cancel_btn'> {success ? "Dismiss" : "Cancel"}</button>
                </div>
            </div>
        </>
    )
}

export default ShowTotalProgress