import React from 'react'
import "./style.scss"
import spinner from "../../assets/Spinner.svg"

function LoadingSpinner() {
  return (
    <div className='spinner'>
        <img src={spinner} />
    </div>
  )
}

export default LoadingSpinner