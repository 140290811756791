export const SET_FOLDERS = "SET_FOLDERS"
export const setFolders = (payload) => ({
    type: SET_FOLDERS,
    payload
})

export const SET_CONTACTS_CHECKED = "SET_CONTACTS_CHECKED"
export const setContactsChecked = (payload) => ({
    type: SET_CONTACTS_CHECKED,
    payload
})
export const SET_CONTACT_CHECKED = "SET_CONTACT_CHECKED"
export const setSingleContactChecked = (payload) => ({
    type: SET_CONTACT_CHECKED,
    payload
})

export const SET_USERSLIST = "SET_USERSLIST"
export const setUsersList = (payload) => ({
    type: SET_USERSLIST,
    payload
})

export const SET_USERS_CHECKED = "SET_USERS_CHECKED"
export const setUsersChecked = (payload) => ({
    type: SET_USERS_CHECKED,
    payload
})
export const SET_USER_CHECKED = "SET_USER_CHECKED"
export const setSingleUserChecked = (payload) => ({
    type: SET_USER_CHECKED,
    payload
})



export const SET_COMPANYLIST = "SET_COMPANYLIST"
export const setComapnyLists = (payload) => ({
    type: SET_COMPANYLIST,
    payload
})

export const SET_COMPANIES_CHECKED = "SET_COMPANIES_CHECKED"
export const setCompaniesChecked = (payload) => ({
    type: SET_COMPANIES_CHECKED,
    payload
})
export const SET_COMPANY_CHECKED = "SET_COMPANY_CHECKED"
export const setCompanyChecked = (payload) => ({
    type: SET_COMPANY_CHECKED,
    payload
})