import React, { useEffect, useRef, useState } from 'react'
import ListTable from '../../components/ListTable'
import MainHeader from '../../components/MainHeader'
import fullLeftArrow from "../../assets/icons/arrow-right.svg"

import "./style.scss"
import SearchInput from '../../components/SearchInput'
import UserTableRow from '../../components/UserTableRow'

import companypic from "../../assets/icons/randcompanylogo.svg"
import companypic2 from "../../assets/icons/company2.svg"
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setContactsChecked, setUsersChecked, setUsersList } from '../../actions/lists'
import { CSVLink } from 'react-csv'
import LoadingSpinner from '../../components/LoadingSpinner'
import refreshToken from '../../services/refreshToken'
import { callYoriApi } from '../../utils'
function UserListTable() {

    const dispatch = useDispatch()

    const [isloading, setIsLoading] = useState(false)
    const params = useLocation()?.state

    // fetch Contact Entities of Folder from api
    const getContactEntities = async () => {
        // let userid=userState?.user.id

        setIsLoading(true)
        console.log("Parms :", params)
        let folder_id = params.id
        //fetch
        callYoriApi(`/entities/contact/${folder_id}`, 'GET')
            .then(resdata => {

                console.log("Contact Entities response :", resdata)


                if (resdata?.length > 0) {
                    resdata = resdata.map(r => { return { ...r, check: false } })
                    dispatch(setUsersList(resdata))
                }
                else
                    dispatch(setUsersList([]))

                setIsLoading(false)
            }).catch(err => {
                console.log("contact entities Err :", err)
                setIsLoading(false)
            }
            )

    }

    useEffect(() => {
        getContactEntities()
        // dispatch(setUsersList(data))
    }, [])


    const deleteRow = async (id) => {
        console.log("Delete :", id)

        // hit delete row api
        setIsLoading(true)

        let folder_id = params?.id

        // fetch
        callYoriApi(`/entities/contact/${folder_id}/${id}`, 'DELETE')
            .then(res => {
                console.log("Deleted Contact Row :", res)

                getContactEntities()
                setIsLoading(false)

            })
            .catch(err => {
                console.log("Delete Contact Row ERR:", err)
                setIsLoading(false)
            }
            )


        // and fetch again data

    }


    const list = useSelector(state => state?.lists?.userslists?.userslist)
    // deleted multiple selected rows
    const deleteSelected = async () => {
        console.log("delete selected")
        setIsLoading(true)
        let folder_id = params?.id

        const selected_ids = list.filter(company => company.check)
        let ids = []
        selected_ids.map(selected => ids = [...ids, selected.id])

        console.log("selected_ids :", ids)


        //fetch
        callYoriApi(`/entities/contact/${folder_id}`, 'DELETE', {
            body: JSON.stringify(ids),
        })
            .then(mess => {
                console.log("mess :", mess);

                setIsLoading(false);
                getContactEntities()
            })
            .catch(err => {
                console.log("Del Err :", err);
                setIsLoading(false)
            })

    }

    const [filedata, setFileData] = useState([])
    const fileref = useRef()
    const headers = [
        { label: "name", key: "name" },
        { label: "title", key: "title" },
        // { label: "company", key: "company" },
        { label: "location", key: "location" },
        { label: "email", key: "email" },
        { label: "work Email", key: "work_email" },
        { label: "phone", key: "phone" }
    ];
    const dowlondSelected = () => {
        const selected_ids = list.filter(company => company.check)
        let data = []
        selected_ids.map(selected => data = [...data,
        // ,company:selected.company
        { name: selected.name, title: selected.title, location: selected.location, email: selected.email, work_email: selected.work_email, phone: selected.phone }
        ])

        setFileData(data)

        setTimeout(() => fileref.current.link.click(), 500)
        console.log("data :", data)
    }



    const [checkFlag, setCheckFlag] = useState(false)
    const handleCheckFlag = (e) => {
        setCheckFlag(e.target.checked)
        dispatch(setUsersChecked(e.target.checked))

        if (e.target.checked)
            setCount(list.length)
        else
            setCount(0)
    }


    const [count, setCount] = useState(0)
    const countSelected = () => {
        setCount(list.filter(lis => lis.check == true).length)
    }

    const [search, setSearch] = useState("")

    return (
        <div
            className={`listContainer ${isloading && 'dim'}`}
        >
            <MainHeader />
            <div className='listBody'>

                <div className='list__nav'>
                    <div>
                        <span>
                            <Link className='navlink' to={"/lists"}><img src={fullLeftArrow} /></Link>

                        </span>
                        <div className='nav_heading'> {params?.name} </div>
                        <div className='nav_num'> {params?.count} </div>
                    </div>
                    <div>
                        <SearchInput control={{ search, setSearch }} placeholder="Search this list" />

                    </div>
                </div>

                <div className='table_header'>
                    <div>
                        <input type="checkbox" className='check' onClick={handleCheckFlag} /> {count} lists selected
                    </div>
                    <div>
                        <button className='deletebtn' disabled={count == 0} style={count == 0 ? { cursor: "not-allowed" } : { cursor: "pointer" }} onClick={deleteSelected}>Delete</button>
                        <button className='dowlondbtn' disabled={count == 0} style={count == 0 ? { cursor: "not-allowed" } : { cursor: "pointer" }} onClick={dowlondSelected}>Dowlond</button>
                        <CSVLink
                            filename='selected_data.csv'
                            target="_self"
                            data={filedata}
                            headers={headers}
                            ref={fileref}
                            style={{ display: "hidden" }}

                        >

                        </CSVLink>

                    </div>
                </div>
                <ListTable
                    data="userlist"
                    search={search}
                    countSelected={countSelected}
                    functions={{ deleteRow }}
                    TableRow={UserTableRow}
                    titles={["Name", "Title", "Company", "Location", "Contact"]}
                />

            </div>
            {
                isloading &&
                <LoadingSpinner />
            }
        </div>
    )
}

export default UserListTable