import { useDispatch } from 'react-redux'
import { callYoriApi } from '../utils'

const _ = require('lodash')

export const SET_REWARDS = "SET_REWARDS"
export const setRewards = (payload) => ({
    type: SET_REWARDS,
    payload
})

export const SET_REFERRAL_ACCEPTED_REWARD = "SET_REFERRAL_ACCEPTED_REWARD"
export const setReferralAcceptedReward = () => async (dispatch, getState) => {

    const payload = await callYoriApi("/reward/seen_state", "GET")

    // console.log("Rewards not scene ========", payload)
    dispatch({
        type: SET_REFERRAL_ACCEPTED_REWARD,
        payload
    })
}
export const UPDATE_REFERRAL_ACCEPTED_REWARD = "UPDATE_REFERRAL_ACCEPTED_REWARD"
export const updateReferralAcceptedReward = () => async (dispatch, getState) => {

    const payload = await callYoriApi("/reward/seen_state", "POST")

    // console.log("Rewards not scene ========", payload)
    dispatch({
        type: UPDATE_REFERRAL_ACCEPTED_REWARD,
        payload
    })
}

export const INIT_REWARDS_HISTORY = "INIT_REWARDS_HISTORY"
export const initRewardsHistory = () => async (dispatch, getState) => {

    const payload = await callYoriApi("/reward/history", "GET")

    // console.log("History ========", payload)
    dispatch({
        type: INIT_REWARDS_HISTORY,
        payload
    })
}


export const SET_REWARD_BALANCE = "SET_REWARD_BALANCE"
export const setRewardBalance = () => async (dispatch, getState) => {
    try {
        const result = await callYoriApi("/wallet/credits", "GET")
        // console.log("Result ========:", result)

        dispatch({
            type: SET_REWARD_BALANCE,
            payload: result?.doller_credits
        })
    } catch (error) {
        console.log("ERROR :", error?.message)
    }
}




export const SET_INVITATIONS = "SET_INVITATIONS"
export const setInvitations = (payload) => ({
    type: SET_INVITATIONS,
    payload
})


export const SET_USER_CONTACTS = "SET_USER_CONTACTS"
export const setUserContacts = (contacts) => (dispatch, getState) => {

    const idsOfContactsFromCurrentState = getState().referrals.userContacts.userContacts.map(prof => prof.id)
    const idsOfContactsIncoming = contacts.map(prof => prof.id)
    const idsToPush = _.difference(idsOfContactsIncoming, idsOfContactsFromCurrentState)

    const contactsToSend = []
    contacts.map((profile, key) => {
        if (idsToPush.includes(profile.id)) {
            contactsToSend.push(profile)
        }
    })
    const payload = contactsToSend
    dispatch({
        type: SET_USER_CONTACTS,
        payload
    })
}

export const SET_IS_USER_CONTACTS_LOADING = "SET_IS_USER_CONTACTS_LOADING"
export const setIsUserContactsLoading = (payload) => ({
    type: SET_IS_USER_CONTACTS_LOADING,
    payload
})

export const SET_IS_USER_CONTACTS_LOADED = "SET_IS_USER_CONTACTS_LOADED"
export const setIsUserContactsLoaded = (payload) => ({
    type: SET_IS_USER_CONTACTS_LOADED,
    payload
})

export const SET_IS_USER_CONTACTS_SCRAPPED = "SET_IS_USER_CONTACTS_SCRAPPED"
export const setIsUserContactsScrapped = (payload) => ({
    type: SET_IS_USER_CONTACTS_SCRAPPED,
    payload
})

export const INIT_INVITATION_CREDITS = "INIT_INVITATION_CREDITS"
export const INIT_INVITATION_CREDITS_SUCCESS = "INIT_INVITATION_CREDITS_SUCCESS"
export const INIT_INVITATION_CREDITS_FAILURE = "INIT_INVITATION_CREDITS_FAILURE"
export const initInvitationsCredits = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: INIT_INVITATION_CREDITS
        })
        // fetch
        const res = await callYoriApi(`/referral/user/invitations`, "GET")
        if ('data' in res) {
            dispatch({
                type: INIT_INVITATION_CREDITS_SUCCESS,
                payload: res.data
            })
        }
        else {
            // console.log("res :", res)
            throw new Error("Something went wrong in initInvitationsCredits")
        }
    }
    catch (err) {
        dispatch({
            type: INIT_INVITATION_CREDITS_FAILURE
        })
    }
}

export const ADD_SELECTED_USER_CONTACT = "ADD_SELECTED_USER_CONTACT"
export const REMOVE_SELECTED_USER_CONTACT = "REMOVE_SELECTED_USER_CONTACT"
export const INITIALIZE_SELECTED_USER_CONTACT = "INITIALIZE_SELECTED_USER_CONTACT"
export const selectSelectedUserContacts = (value, id) => ({
    type: value ? ADD_SELECTED_USER_CONTACT : REMOVE_SELECTED_USER_CONTACT,
    payload: id
})

export const UPDATE_SELECTED_USER_CONTACT = "UPDATE_SELECTED_USER_CONTACT"
export const updateSelectedUserContact = (payload) => ({
    type: UPDATE_SELECTED_USER_CONTACT,
    payload
})


export const UPDATE_USER_CONTACT_STATE = "UPDATE_USER_CONTACT_STATE"
export const updateUserContactState = (id) => ({
    type: UPDATE_USER_CONTACT_STATE,
    payload: id
})

export const INIT_EARNING = "INIT_EARNING"
export const INIT_EARNING_SUCCESS = "INIT_EARNING_SUCCESS"
export const INIT_EARNING_FAILURE = "INIT_EARNING_FAILURE"
export const initEarning = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: INIT_EARNING
        })
        // fetch
        // console.log(`about to call earning`)
        const res = await callYoriApi("/reward/earning", "GET")
        if ('amount' in res) {
            // console.log('Response of earning ::::::::::::::', res)
            dispatch({
                type: INIT_EARNING_SUCCESS,
                payload: res.amount
            })
        }
        else {
            // console.log("res :", res)
            throw new Error("Something went wrong in REWARD_EARNING")
        }
    }
    catch (err) {
        dispatch({
            type: INIT_EARNING_FAILURE
        })
    }
}


export const SET_MESSAGE_TEMPLATE = "SET_MESSAGE_TEMPLATE"
export const setMessageTemplate = (messageString) => ({
    type: SET_MESSAGE_TEMPLATE,
    payload: messageString
})


export const RESET_MESSAGE_TEMPLATE = "RESET_MESSAGE_TEMPLATE"
export const resetMessageTemplate = () => ({
    type: RESET_MESSAGE_TEMPLATE,
})


export const ADD_INVITATION_SENDING_CHECK = "ADD_INVITATION_SENDING_CHECK"
export const REMOVE_INVITATION_SENDING_CHECK = "REMOVE_INVITATION_SENDING_CHECK"
export const setInvitationSendingCheck = (value , id) => ({
    type: value ? ADD_INVITATION_SENDING_CHECK : REMOVE_INVITATION_SENDING_CHECK,
    payload: id
})

export const SET_MESSAGE_SENDING_STATE = "SET_MESSAGE_SENDING_STATE"
export const REMOVE_MESSAGE_SENDING_STATE = "REMOVE_MESSAGE_SENDING_STATE"
export const setMessageSendingState = (check ,id , value) => ({
    type: check ? SET_MESSAGE_SENDING_STATE : REMOVE_MESSAGE_SENDING_STATE,
    payload: {id , value}
})

export const SET_SHOW_MESSAGE_SENDING_CARDS = "SET_SHOW_MESSAGE_SENDING_CARDS"
export const setShowMessagesSendingCards = (bool) => ({
    type: SET_SHOW_MESSAGE_SENDING_CARDS,
    payload: bool
})

export const REINITIATE_SELECTED_USER_CONTACTS = "REINITIATE_SELECTED_USER_CONTACTS"
export const reinitiateSelecserContacts = (id) => ({
    type: REINITIATE_SELECTED_USER_CONTACTS,
    payload : id
})


export const SET_MESSAGES_PROGRESS = "SET_MESSAGES_PROGRESS"
export const setMessagesProgress = (payload) => (dispatch, getState) => {
    
    // if step not zero 
    // add isSending = true and update step to progress
    // if step=4 
    // set isSent = true and remove is_in_sending_flow =false


    payload?.map((message , id) => {
        if(message?.step > 0 && message?.step < 4){
            dispatch({
                type: UPDATE_SELECTED_USER_CONTACT,
                payload : { id: message?.user_contact_id, value: { isSending: true, isSent: false, hasFailed: false } }
            })
                
            dispatch({
                type:  SET_MESSAGE_SENDING_STATE,
                payload: {id:message?.user_contact_id , value: message?.step}
            })
            
        }
        else if(message?.step==4){
            dispatch({
                type: UPDATE_SELECTED_USER_CONTACT,
                payload : { id: message?.user_contact_id, value: { isSending: false, isSent: true, hasFailed: false }}
            })
            dispatch({
                type: UPDATE_USER_CONTACT_STATE,
                payload : message?.user_contact_id
            })
            dispatch({
                type:  REMOVE_MESSAGE_SENDING_STATE,
                payload: {id:message?.user_contact_id , value: message?.step}
            })
        }
    })
    
    if(payload?.length == payload?.filter(ent => ent?.step == 4)?.length){

        const selectedUserContacts = getState().referrals.userContacts.selectedUserContacts
        selectedUserContacts?.filter(card => card?.is_in_sending_flow)?.map(contact => contact?.id)?.map(id => 
            {
                dispatch({
                    type: REMOVE_SELECTED_USER_CONTACT,
                    payload : id
                })
                dispatch({
                    type: REMOVE_INVITATION_SENDING_CHECK,
                    payload : id
                })
            }
        )    
        dispatch({
            type: SET_SHOW_MESSAGE_SENDING_CARDS,    
            payload : false
        })
    }
    
    dispatch({
        type: SET_MESSAGES_PROGRESS,    
        payload
    })
    if(payload?.length == payload?.filter(ent => ent?.step == 4)?.length){
        dispatch({
            type: RESET_MESSAGES_PROGRESS,    
        })
    }
}


export const RESET_MESSAGES_PROGRESS = "RESET_MESSAGES_PROGRESS"
export const resetMessageProgress = () => ({
    type: RESET_MESSAGES_PROGRESS,
})