import React, { useEffect } from 'react'
import "./style.scss"
// import sqout from "../../../../img/Sqout.svg"
// import sqoutlogo from "../../../../img/SqoutLogo.svg"
// import profile from "../../../../img/profile.PNG"

import halfcircle from "../../../../assets/icons/halfcircle.svg"
import profile from "../../../../assets/icons/profile.svg"
import MainHeader from '../../../../components/MainHeader'

function BillingInformation() {
    const data = [1, 2, 3, 4]

    return (
        <div>
            <MainHeader />

            <div className='billing_information_container'>
                {/* <div className='billing_header'>
                <div>
                    <img src={sqoutlogo}/>
                    <img src={sqout}/>
                </div>
                <div>
                    <img src={profile}/>
                    <spn>John Doe</spn>
                </div>

            </div> */}

                <div className='billing_body'>
                    <div>
                        <h2>Billing Information </h2>
                        <button>Edit Billing and Payment Info</button>
                    </div>

                    <div className='billing_information'>
                        <div>
                            <h4>Sept 17, 2023</h4>
                            <p>Next Invoice Issue Date</p>
                        </div>
                        <div>
                            <h4>$71.99</h4>
                            <p>Next Invoice Total</p>
                        </div>
                        {/* <div>
                        <h4 >6000 <img src={halfcircle}/> </h4>
                        <p>Next Invoice Credits</p>
                    </div> */}
                        <div className='billing_email'>
                            <h4>john.doe@gookup.com</h4>
                            <p>Invoices Sent to</p>
                        </div>
                    </div>

                    <div className='billing_invoice'>
                        <h4>Invoices</h4>
                        <div>
                            <div className='invoice_header'>
                                <p>Date</p>
                                <p>Description</p>
                                <p>Invoice Total</p>
                            </div>
                        </div>

                        {
                            data.map(row => <div className='invoice_row'>
                                <div>
                                    <p>Sept 17, 2022</p>
                                    <p>Yearly Subscription</p>
                                    <p>$71.99</p>
                                </div>
                                <div className='view_invoice'>
                                    {/* <p>View</p> */}
                                    <button className='view_btn'>View</button>
                                </div>
                            </div>

                            )
                        }

                    </div>

                    {/* <div className="billing_footer">
                        <button>Cancel my Subscription</button>
                </div> */}

                </div>

            </div>
        </div>
    )
}

export default BillingInformation