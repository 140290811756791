import React, { useEffect, useState } from 'react'
import InvitationsCount from '../../components/InvitationsCount'
import InviteSocial from '../../components/InviteSocial'
import MainHeader from '../../components/MainHeader'
import PrimaryButton from '../../components/PrimaryButton'
import RewardBox from '../../components/RewardBox'
import "./style.scss"
import reward1 from "../../assets/icons/reward1.svg"
import reward2 from "../../assets/icons/reward2.svg"
import reward3 from "../../assets/icons/reward3.svg"
import reward5 from "../../assets/icons/reward4.svg"
import reward4 from "../../assets/icons/reward5.svg"

import reward1Ribbon from "../../assets/icons/reward1Ribbon.svg"
import reward2Ribbon from "../../assets/icons/reward2Ribbon.svg"
import reward3Ribbon from "../../assets/icons/reward3Ribbon.svg"
import reward5Ribbon from "../../assets/icons/reward4Ribbon.svg"
import reward4Ribbon from "../../assets/icons/reward5Ribbon.svg"


import MessageRewardBox from '../../components/RewardBox/MessageRewardBox'
import { useDispatch, useSelector } from 'react-redux'
import { invitationSentMilestones, invitationAcceptedMilestones, callYoriApi, getBadgeToSend, invitationSentMilestonesBadges } from '../../utils'
import RewardNotificationModal from '../../components/RewardNotificationModal'
import tenContactsBadge from '../../assets/icons/tenContactsBadge.svg'
import TransactionHistory from './components/transactionHistory.js'
import { initInvitationsCredits } from '../../actions/referrals'
import CheckpointProgressComponent from './components/CheckpointProgressComponent'

import { updateReferralAcceptedReward } from '../../actions/referrals'


function Reward() {

    const milestones = Object.keys(invitationSentMilestones)
    const rewards = Object.values(invitationSentMilestones)

    const dispatch = useDispatch()
    const [dollarCredits, setDollarCredits] = useState(0)
    let {
        isLoading,
        isLoaded,
        invitations_sent,
        invitations_accepted
    } = useSelector(state => state.referrals?.invitations)

    const rewardsNotSeen = useSelector(state => state?.referrals?.rewards?.rewards_not_seen)
    const [openModal, setOpenModal] = useState(rewardsNotSeen.length > 0 && isLoaded)
    const [rewardTypeCheck, setRewardTypeCheck] = useState('invitationAccepted')
    const [badgeToSend, setBadgeToSend] = useState(tenContactsBadge)

    let text = ""
    let amount = invitationAcceptedMilestones?.[1] * rewardsNotSeen.length
    if (rewardsNotSeen.length > 0) {
        text = rewardsNotSeen[0].first_name + " " + rewardsNotSeen[0].last_name
        console.log("data =====:", rewardsNotSeen)
        rewardsNotSeen.map((obj, ind) => {
            if (ind > 0 && ind < 3) {
                text += ", " + obj.first_name + " " + obj.last_name
            }
            if (ind == 3) {
                text += ` and ${rewardsNotSeen.length - 3} more`

            }
        })
        console.log("text ====: ", text)
    }




    const data = [
        { img: reward1, count: "$29", text: "1 Invite signs up via your link", color: "" },
        // {img:reward2,count:"$58",text:"Review Yori on Chrome store",color:""},
        // {img:reward3,count:"$29",text:"Invite 100 contacts via LinkedIn",color:""},
    ]
    const data1 = [
        { img: reward1, rewardRibbon: reward1Ribbon, inviteCount: milestones[0], count: `${rewards[0]} credits`, text: `Invite ${milestones[0]} contacts via LinkedIn`, color: "rgba(246, 218, 194, 0.34)" },
        // {img:reward4,count:"$58",text:"Review Yori on Chrome store",color:""}, 
        { img: reward2, rewardRibbon: reward2Ribbon, inviteCount: milestones[1], count: `${rewards[1]} credits`, text: `Invite ${milestones[1]} contacts via LinkedIn`, color: "#F7F7F7" },
        { img: reward3, rewardRibbon: reward3Ribbon, inviteCount: milestones[2], count: `${rewards[2]} credits`, text: `Invite ${milestones[2]} contacts via LinkedIn`, color: "#FFF7E9" },
        { img: reward4, rewardRibbon: reward5Ribbon, inviteCount: milestones[3], count: `${rewards[3]} credits`, text: `Invite ${milestones[3]} contacts via LinkedIn`, color: "#F5F5F7" },
        { img: reward5, rewardRibbon: reward4Ribbon, inviteCount: milestones[4], count: `${rewards[4]} credits`, text: `Invite ${milestones[4]} contacts via LinkedIn`, color: "rgba(217, 241, 246, 0.54)" },
    ]

    useEffect(async () => {

        try {
            const res = await callYoriApi("/wallet/credits", "GET")
            setDollarCredits(res?.doller_credits)

        } catch (error) {
            console.log("Wallet -> Error:", error)
        }

    }, [])

    useEffect(async () => {
        dispatch(initInvitationsCredits())
    }, [])

    const updateRewardSeenState = () => {
        dispatch(updateReferralAcceptedReward())
        setOpenModal(false);
    }
    useEffect(() => {
        const inviteCheckpoints = Object.keys(invitationSentMilestones)
        inviteCheckpoints.map((checkpoint, index) => {
            if (invitations_sent.length >= checkpoint) {
                setBadgeToSend(invitationSentMilestonesBadges[index])
                return
            } else if (invitations_sent.length < 10) {
                setBadgeToSend(invitationSentMilestonesBadges[0])
                return
            }
        })
        if (isLoaded) {
            setOpenModal(rewardsNotSeen.length > 0 && isLoaded)
        }
    }, [isLoaded])


    return (
        <div className='reward'>
            <RewardNotificationModal
                open={openModal}
                onClose={() => { updateRewardSeenState() }}
                badgeSrc={badgeToSend}
                rewardDetails={{
                    rewardType: rewardTypeCheck,
                    name: text,
                    rewardAmount: amount,
                    numberOfContactsInvited: '10',
                    nextRewardAmount: '59',
                    nextNumberOfContactsInvited: '100',
                }}
            />
            <MainHeader />
            <div className='reward_container'>
                <div className='reward_left'>
                    <div className='reward_header'>
                        <p>Rewards</p>
                        {/* <PrimaryButton style={{fontSize:"15px",padding:"0.3rem 0.6rem"}} variant="Betas">Send Invites</PrimaryButton> */}
                    </div>
                    <div className='checkpointProgressContainer'>
                        {/* <InvitationsCount
                            count={dollarCredits?.toFixed(2)}
                            numberOfProspects={invitations_sent.length}
                            title="Earned in rewards so far"
                            hasBottomBorder={false}
                            packageMilestones={invitationSentMilestones}
                        /> */}
                        <CheckpointProgressComponent />
                    </div>

                    <div className='rewards_body'>

                        <p className='rewards_body_heading'>Reward Gallery</p>

                        <div className='rewardbox_container'>
                            {
                                data1.map((row, i) => <RewardBox key={i} data={row} />)
                            }
                            {/* {
                                data.map((row, i) => <RewardBox key={i} data={row} />)
                            } */}
                            {/* <MessageRewardBox /> */}

                        </div>

                    </div>


                </div>
                <div className='reward_right'>
                    <InviteSocial />

                    <TransactionHistory />
                </div>
            </div>
        </div>

    )
}

export default Reward