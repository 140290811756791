import React, { useEffect, useState } from 'react';
import "./style.scss"
import closebtn from "../../../assets/icons/x.svg"
import { useSelector } from 'react-redux';
import InviteProfileCheckCard from '../../../components/InviteProfileCheckCard';
const SelectedUserContactsModal = ({ 
    open, 
    onClose,
    // selectedUserContacts,
    sendingInvites,
    onInviteButtonClick,

}) => {

    let selectedUserContacts = useSelector(state => state.referrals?.userContacts?.selectedUserContacts)

    if (selectedUserContacts.length === 0) {
        onClose()
    }
    if (!open) return null;

    return (
        <div onClick={onClose} className='selected_overlay' >
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className='selected_modalContainer'
            >
                <div className='modal'>
                    <div className='modal_header'>
                        <h3>
                            Selected Profiles
                        </h3>
                        <span>
                            <button className='invite_btn' onClick={onInviteButtonClick} disabled={selectedUserContacts.length == 0 || sendingInvites} style={(selectedUserContacts.length === 0  || sendingInvites) ? { cursor: "not-allowed" } : {}}>Invite selected</button>
                            <div>
                                <span>{selectedUserContacts.length} selected</span>
                                <img src={closebtn} onClick={onClose} />
                            </div>
                        </span>
                    </div>
                    <div className='modalbody'>
                        {
                            selectedUserContacts.length > 0 && selectedUserContacts?.map((card, ind) => {
                                return <InviteProfileCheckCard
                                    key={card?.contact?.id}
                                    profileId={card?.id}
                                    last_name={card?.contact?.last_name}
                                    first_name={card?.contact?.first_name}
                                    profile_picture={card?.contact?.profile_picture}
                                    headline={card?.contact?.headline}
                                    public_identifier={card?.contact?.public_identifier}
                                    hasCheck={true}
                                    isReferralSent={card?.isSent}
                                    isInSendingFlow={card?.is_in_sending_flow}
                                />
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectedUserContactsModal;
