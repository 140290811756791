import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/SearchInput'
import searchButton from "../../../assets/icons/Funnel.svg"
import "./style.scss"
import profile from "../../../assets/icons/profile.png"
import UsersTable from './components/UsersTable'
import DashBoardSideBar from '../../components/sideBar'
import DashboardHeader from '../../components/DashboardHeader'
import SearchRow from '../../components/SearchRow'
import { callYoriApi } from '../../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchInitialUsers, fetchRemainingUsersChunk } from '../../../actions/dashboard'


function Users() {

    const dispatch = useDispatch()
    const [search, setSearch] = useState("")
    const [tab, setTab] = useState(1)
    const [fetchingUsersChunks, setFetchingUsersChunks] = useState(false)
    const [data, setData] = useState([])
    const {
        allUsers,
        allUsersCount
    } = useSelector(state => state?.dashboard?.dashboard)
    let [searchedUsers, setSearchedUsers] = useState([])

    useEffect(() => {
        if (allUsers?.length == 0) {
            setFetchingUsersChunks(true)
            dispatch(fetchInitialUsers())
        }
    }, [])

    useEffect(async () => {
        if(allUsers?.length == allUsersCount){
            setFetchingUsersChunks(false)
        }
        if(allUsers?.length != 0 && allUsers?.length !== allUsersCount){
            dispatch(fetchRemainingUsersChunk())
        }
        // if(search == ''){
        //     setSearchedUsers(allUsers)
        // }
        // if(search != ''){
            searchUserFunction()
        // }
    }, [allUsers])
    


    let delay;
    function debounce(func, timeout = 500) {
        delay = setTimeout(() => {
            func()
        }, timeout)
        return () => clearTimeout(delay)
    }

    const searchUserFunction = () => {
        const searchUser = () => {
            if (search === '') {
                setSearchedUsers(allUsers)
            }
            else {
                setSearchedUsers(allUsers.filter(user =>
                    `${user?.first_name?.trim()} ${user?.last_name?.trim()}`?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    user?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    user?.id == search
                ))
            }
        }
        debounce(searchUser)
    }

    useEffect(() => {
        searchUserFunction()
        // searchUser()
    }, [search])



    return (
        <div className='dashboard'>

            <DashBoardSideBar tab={tab} setTab={setTab} />

            <div className='main'>
                <DashboardHeader tab={tab} />


                <div className='usersScreen'>

                    <div className='usersHeader'>
                        <span className='heading'>
                            Users
                        </span>
                        {
                            allUsers?.length === 0 ?
                                <>
                                    <h1>Loading</h1>
                                </>
                                :
                                <>
                                    <SearchRow search={search} setSearch={setSearch} />

                                    {/* users Table */}
                                    <UsersTable search = {search} data={searchedUsers} />
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Users