import React, { useState } from 'react'
import { PieChart } from './PieChart'
import SelectBox from '../SelectBox'
import "./style.scss"


function PieChartBox({ mainTitle, total, titles, data, type, onSelect = () => false }) {

    // const [noDataState, setNoDataState] = useState(data.every(i => i == 0))
    // console.log(data.every(i => i != 0))

    return (
        <div className='PieChartBox'>
            <div className='chartHead'>
                <span>{mainTitle}</span>
                <SelectBox options={["Today", "This Week", "This Month"]} onSelect={onSelect} type={type} />
            </div>
            <span className='xl'>{total}</span>

            {/* {
                noDataState ? <div>No new {mainTitle === "New Signups" ? "Signups" : "Invites"}</div> : */}
            <div className='chartSection'>
                <ul>
                    <li className='organic'>{titles?.[0]}</li>
                    <li className='invited'>{titles?.[1]}</li>
                </ul>

                <PieChart values={data} />
            </div>
            {/* } */}

        </div>
    )
}

export default PieChartBox