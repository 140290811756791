import React from 'react'

const GreenCheckCircle = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21" cy="21" r="20.5" fill="#6FCF97" fillOpacity="0.1" stroke="#30A451"/>
        <path d="M29 15L18 26L13 21" stroke="#30A451" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default GreenCheckCircle