import React, { useState } from 'react'

import loadeer from '../../assets/Spinner.svg'
import "./style.scss"

function DemoWebsite() {
    const [query, setQuery] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState('')
    const [error, setError] = useState(false)

    const onClick = () => {
        try {
            if (query?.trim()) {
                const url = process.env.REACT_APP_DEMO_API_URL

                setIsLoading(true)
                console.log("first :", query)
                fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        query: query
                    })

                }).then(res => {
                    console.log({ res })
                    return res.json()
                }
                )
                    .then(data => { console.log({ data }); setIsLoading(false); setResult(data?.answer); setQuery('') })
                    .catch(err => setIsLoading(false))
            }
            else {
                alert("add query into textfeild")
            }

        } catch (error) {
            setIsLoading(false)
            setError(true)
            console.error(error?.message)
        }

    }


    return (
        <div className="DemoWebsite">
            <div className='searchBox'>
                <input className='textField' type='text' placeholder='search query...' onChange={e => setQuery(e.target.value)} />
                <button className='submitBtn' onClick={onClick}>Submit</button>
            </div>
            <div className='result'>
                <p>Response </p>

                {
                    isLoading && <img src={loadeer} />
                }
                {
                    error ? "Something went wrong" : result
                }
            </div>
        </div>
    );
}

export default DemoWebsite