/* global chrome */

import React, { Component, useEffect, useState } from 'react'
import './App.css'
import { connect, useDispatch, useSelector } from 'react-redux'
import Home from './views/home';
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { setDropMenu, setUser } from './actions/auth';
import Signup from './views/signup';
import Login from './views/login';
import PrivacyPolicy from './views/privacy-policy';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Lists from './views/list';
import UserListTable from './views/list/UserListTable';
import CompanyListTable from './views/list/CompanyListTable';
import InvitationsCredits from './views/invitations_and_credits';
import Wallet from './views/wallet';
import ConnectScreen from './views/invitations_and_credits/ConnectsScreen';
import BillingInformation from './views/wallet/Components/BillingInformation';
import SubscriptionForm from './views/wallet/Components/SubscriptionForm';
import Reward from './views/reward';
import UserProfile from './views/user_profile';
import Protected from './components/Protected';
import { awaitfunction, callYoriApi, getRandomInt, invitationSentMilestones, invitationSentMilestonesBadges, isLoggedIn, notify, RefreshAndSaveToken, RefreshToken } from './utils';
import { async } from '@firebase/util';
import refreshToken from './services/refreshToken';
import SendInvites from './views/invitations_and_credits/SendInvites';
import 'react-toastify/dist/ReactToastify.css';
import TopUp from './views/wallet/Components/TopUp';
import { gapi } from 'gapi-script';
import OnBoardingFlow from './views/onBoardingFlow';
import IntroToYori from './views/onBoardingFlow/introToYori';
import RewardWelcome from './views/onBoardingFlow/rewardWelcome';
import { reinitiateSelecserContacts, resetMessageTemplate, selectSelectedUserContacts, setInvitationSendingCheck, setIsUserContactsScrapped, setMessageSendingState, setMessagesProgress, setShowMessagesSendingCards, setUserContacts, updateSelectedUserContact, updateUserContactState } from './actions/referrals';
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import reward1 from "./assets/icons/reward1.svg"
import reward2 from "./assets/icons/reward2.svg"
import reward3 from "./assets/icons/reward3.svg"
import reward5 from "./assets/icons/reward4.svg"
import reward4 from "./assets/icons/reward5.svg"
import useInterval from 'use-interval';
import { pingExtToStartSendingMessages, sendMessageToFirstDegreeConnection } from './MessagePassing';
import RewardNotificationModal from './components/RewardNotificationModal';
import { toast, ToastContainer } from 'react-toastify';
import CustomToastContainer from './components/ToastContainer';
import spinner from "./assets/Spinner.svg"
import ShowTotalProgress from './components/ShowTotalProgress';
import Dashboard from './dashboard';
import Users from './dashboard/views/Users';
import DefaultScreen from './dashboard/views/defaultScreen';
import DashBoardUserProfile from './dashboard/views/UserProfile';
import Funnel from './dashboard/views/funnel';
import DemoWebsite from './views/demo_website';

// import 'bootstrap/dist/css/bootstrap.min.css';

const App = ({
  dispatch
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const props = { location, navigate, params }
  const auth = getAuth();
  const REFRESH_TOKEN_LIMIT = 30 * 1000 * 60

  const drop_menu = useSelector(state => state.auth?.user?.drop_menu)
  const messagesProgress = useSelector(state => state.referrals.userContacts?.messagesProgress)
  const loggedinUser = localStorage.getItem("TKNV1")
  const isAdmin = localStorage.getItem("is_super_user")


  // start

  const [requestRateExceeded, setRequestRateExceeded] = useState(false)
  const [sendingInvites, setSendingInvites] = useState(false)
  // const [showMessagesSendingCards, setShowMessagesSendingCards] = useState(false)
  const [query, setQuery] = useState('');
  const [search, setSearch] = useState("")


  const [currentlySending, setCurrentlySending] = useState(0)
  const [intervalTime, setIntervalTime] = useState(10000)
  const [progressInterval, setProgressInterval] = useState(null)
  const [progressIntervalCount, setProgressIntervalCount] = useState(1)
  const [showRewardModel, setshowRewardModel] = useState(false)
  const [numberOfSentReward, setNumberOfSentReward] = useState(0)
  const [nextReward, setnextReward] = useState(0)

  const [openRewardModal, setOpenRewardModal] = useState(false);
  const [rewardTypeCheck, setRewardTypeCheck] = useState('checkPoint')
  const [badgeToSend, setBadgeToSend] = useState(reward1)
  const [cancellingStatus, setCancellingStatus] = useState(false)
  const [contactsFetchedFromMount, setContactsFetchedFromMount] = useState(false)

  const badgesArray = [
    reward1,
    reward2,
    reward3,
    reward5,
    reward4
  ]

  let {
    selectedUserContacts,
    userContacts,
    showMessagesSendingCards
  } = useSelector(state => state.referrals?.userContacts)
  let {
    isLoading,
    isLoaded,
    invitations_sent,
    invitations_accepted,
    messageTemplate
  } = useSelector(state => state.referrals?.invitations)


  // useEffect(() => {

  //   if (completed?.length < selectedUserContacts?.length && !requestRateExceeded && sendingInvites) {
  //     dispatch(updateSelectedUserContact({ id: selectedUserContacts?.filter(card => card?.is_in_sending_flow)[currentlySending]?.id , value: { isSending: true, isSent: false, hasFailed: false } }))
  //   }
  //   // when message sending ends show reward if any reward is acheived
  //   if (completed?.length == selectedUserContacts?.length && showRewardModel) {
  //     setOpenRewardModal(true)
  //     setshowRewardModel(false)
  //   }
  //   // if (currentlySending === selectedUserContacts?.filter(card => card?.is_in_sending_flow).length - 1) {
  //   if (completed?.length == selectedUserContacts?.length) {
  //     dispatch(resetMessageTemplate())
  //     setSendingInvites(false)
  //     selectedUserContacts?.filter(card => card?.is_in_sending_flow)?.map(contact => contact?.id)?.map(id => dispatch(selectSelectedUserContacts(false, id)))
  //     // setShowMessagesSendingCards(false)
  //   }
  //   // if (sendingInvites && selectedUserContacts?.filter(card => card?.is_in_sending_flow)?.[currentlySending]?.id && !progressInterval) {}
  // }, [currentlySending, sendingInvites])





  const startSendingMessages = async () => {
    try {

      selectedUserContacts?.map((obj, id) =>
        dispatch(updateSelectedUserContact({ id: obj?.id, value: { isSending: true, isSent: false, hasFailed: false } }))
      )

      const res = await callYoriApi('/referral/start_sending', "POST", { body: JSON.stringify({ contactsToInitiate: selectedUserContacts?.map(contact => contact?.id), messageTemplate }) })
      dispatch(setShowMessagesSendingCards(true))
      pingExtToStartSendingMessages()
      // setSendingInvites(true)

      setTimeout(async () => {
        const res = await callYoriApi('/referral/progress', "GET")
        console.log("response from backend------------", res)

        dispatch(setMessagesProgress(res?.messages_progress))
        setSendingInvites(true)

      }, 0)


      let interval = setInterval(async () => {
        const res = await callYoriApi('/referral/progress', "GET")
        console.log("response from backend in alarm------------", res)

        dispatch(setMessagesProgress(res?.messages_progress))

        if (res?.messages_progress?.length == res?.messages_progress?.filter(ent => ent?.step == 4)?.length) {
          clearInterval(interval)
          localStorage.removeItem("interval")
          setSendingInvites(false)
          setCancellingStatus(false)
        }
      }, 10000)

      localStorage.setItem("interval", interval)

    } catch (error) {

    }
  }




  async function checkIfScrappingHasBeenDone() {
    //fetch
    // console.log("Check when this is called Part 2")
    await callYoriApi(`/referral/user_contacts`, "GET")
      .then(response => {
        const isDataScrapped = response?.is_all_user_contacts_scraped
        if (isDataScrapped) {
          dispatch(setUserContacts(response.payload))
          dispatch(setIsUserContactsScrapped(response?.is_all_user_contacts_scraped))
          // routeChange()
        }
      })
  }

  const getFirstProgressOnMount = async () => {

    const res = await callYoriApi('/referral/progress', "GET")
    console.log("response from backend in alarm inside the 'getFirstProgressOnMount' Function------------", res)
    res?.messages_progress?.map((entity, id) => dispatch(reinitiateSelecserContacts(entity?.user_contact_id)))
    dispatch(setShowMessagesSendingCards(true))
    setSendingInvites(true)


    dispatch(setMessagesProgress(res?.messages_progress))
  }


  useEffect(async () => {

    if (loggedinUser) {
      setContactsFetchedFromMount(true)
      await checkIfScrappingHasBeenDone()
    }
    const checkInterval = localStorage.getItem("interval")
    let resetMessagingAlarmCount = 0
    if (checkInterval) {
      console.log(resetMessagingAlarmCount, "Check when this is called")

      await getFirstProgressOnMount()

      let interval = setInterval(async () => {
        const res = await callYoriApi('/referral/progress', "GET")
        console.log(resetMessagingAlarmCount, "response from backend in alarm------------", res)

        // if(resetMessagingAlarmCount === 0){
        //   res?.messages_progress?.map((entity , id) => dispatch(reinitiateSelecserContacts(entity?.user_contact_id)))
        //   resetMessagingAlarmCount+=1
        //   dispatch(setShowMessagesSendingCards(true))
        //   setSendingInvites(true)
        // }

        dispatch(setMessagesProgress(res?.messages_progress))


        if (res?.messages_progress?.length == res?.messages_progress?.filter(ent => ent?.step == 4)?.length) {
          clearInterval(interval)
          localStorage.removeItem("interval")
          setSendingInvites(false)
          setCancellingStatus(false)
        }
      }, 10000)
    }

  }, [])



  useEffect(() => {
    const inviteCheckpoints = Object.keys(invitationSentMilestones)
    inviteCheckpoints.map((checkpoint, index) => {
      if (invitations_sent.length >= checkpoint) {
        setBadgeToSend(invitationSentMilestonesBadges[index])
        return
      } else if (invitations_sent.length < 10) {
        setBadgeToSend(invitationSentMilestonesBadges[0])
        return
      }
    })
  }, [isLoaded])


  // end




  useEffect(() => {

    setTimeout(() => {
      const email = localStorage.getItem('email')
      const api = '/admin/dashboard/website_activity'
      const method = 'POST'
      const options = {
        body: JSON.stringify(
          {
            email: email,
            entity_type: email ? "users" : 'anonymous',
            context: {
              name: email ? "users" : 'anonymous',
            },
            action: "WEBSITE_VISIT",
          }
        )
      }

      new Promise((resolve, reject) => {
        const headers = {}
        if (method === "POST" || method === "PATCH" || method === "DELETE") {
          headers["Content-Type"] = "application/json"
        }
        fetch(process.env.REACT_APP_API_URL + api, {
          ...options,
          method: method,
          headers: {
            ...headers,
          }
        })
          .then(r => r.json())
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log('TRACKING WEBSITE ACTIVITY ======== ERROR', e?.message)
            reject(e)
          }
          )
      }
      )
    }, 3000);


  }, []);

  const handleCloseProressModal = async () => {
    setCancellingStatus(true)
    await callYoriApi('/referral/abort_sending', "DELETE")
  }


  return (
    <div onClick={() => drop_menu && dispatch(setDropMenu(false))} >


      <RewardNotificationModal
        open={openRewardModal}
        onClose={() => { setOpenRewardModal(false); }}
        badgeSrc={badgeToSend}
        rewardDetails={{
          rewardType: rewardTypeCheck,
          name: "",
          rewardAmount: invitationSentMilestones[numberOfSentReward],
          numberOfContactsInvited: numberOfSentReward,
          nextRewardAmount: invitationSentMilestones[nextReward],
          nextNumberOfContactsInvited: nextReward,
        }}
      />
      <Routes>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy {...props} />} />
        <Route exact path="/login" element={<Login {...props} />} />
        <Route exact path="/signup" element={<Login {...props} />} />
        <Route path="/onboarding" element={<Protected isLoggedIn={loggedinUser}>
          <OnBoardingFlow {...props} />
        </Protected>} />
        <Route path="/onboarding/intro_to_yori" element={<Protected isLoggedIn={loggedinUser}>
          <IntroToYori {...props} />
        </Protected>} />
        <Route path="/onboarding/reward_welcome" element={<Protected isLoggedIn={loggedinUser}>
          <RewardWelcome {...props} />
        </Protected>} />

        <Route path="/lists" element={<Protected isLoggedIn={loggedinUser}>
          <Lists {...props} />
        </Protected>} />
        <Route path="/lists/userlist" element={<Protected isLoggedIn={loggedinUser}>
          <UserListTable {...props} />
        </Protected>} />
        <Route path="/lists/companylist" element={<Protected isLoggedIn={loggedinUser}>
          <CompanyListTable {...props} />
        </Protected>} />
        <Route path="/invitations_credits" element={<Protected isLoggedIn={loggedinUser}>
          <InvitationsCredits {...props} />
        </Protected>} />
        <Route path="/invitations_credits/send_invites" element={<Protected isLoggedIn={loggedinUser}>
          <SendInvites {...props} state={{ setSendingInvites, startSendingMessages, sendingInvites, query, setQuery, search, setSearch }} />
        </Protected>} />
        <Route path="/wallet" element={<Protected isLoggedIn={loggedinUser}>
          <Wallet {...props} />
        </Protected>} />
        <Route path="/wallet/upgrade_subscription" element={<Protected isLoggedIn={loggedinUser}>
          <SubscriptionForm {...props} />
        </Protected>} />
        <Route path="/reward" element={<Protected isLoggedIn={loggedinUser}>
          <Reward {...props} />
        </Protected>} />
        <Route path="/connect" element={<Protected isLoggedIn={loggedinUser}>
          <ConnectScreen {...props} state={{ setSendingInvites, sendingInvites }} />
        </Protected>} />
        <Route path="/billing" element={<Protected isLoggedIn={loggedinUser}>
          <BillingInformation {...props} />
        </Protected>} />
        <Route path="/profile" element={<Protected isLoggedIn={loggedinUser}>
          <UserProfile {...props} />
        </Protected>} />
        <Route path="/wallet/topup" element={<Protected isLoggedIn={loggedinUser}>
          <TopUp {...props} />
        </Protected>} />

        <Route exact path="/" element={<Home {...props} />} />
        <Route exact path="/:referal_link" element={<Home {...props} />} />


        {/* Dashboard */}
        <Route path="/admin/dashboard" element={<Protected isLoggedIn={loggedinUser && isAdmin}>
          <DefaultScreen {...props} />
        </Protected>} />
        <Route path="/admin/dashboard/users" element={<Protected isLoggedIn={loggedinUser && isAdmin}>
          <Users {...props} />
        </Protected>} />
        <Route path="/admin/dashboard/user-profile" element={<Protected isLoggedIn={loggedinUser && isAdmin}>
          <DashBoardUserProfile {...props} />
        </Protected>} />
        <Route path="/admin/dashboard/funnel" element={<Protected isLoggedIn={loggedinUser && isAdmin}>
          <Funnel {...props} />
        </Protected>} />
        {/* <Route path="/admin/dashboard" element={<DefaultScreen {...props} />} />
        <Route path="/admin/dashboard/users" element={<Users {...props} />} />
      <Route path="/admin/dashboard/user-profile" element={<DashBoardUserProfile {...props} />} /> */}

        <Route exact path="/demo-website" element={<DemoWebsite {...props} />} />

      </Routes>
      {
        sendingInvites &&
        <ShowTotalProgress
          currentlySending={messagesProgress?.filter(ent => ent?.step === 4)?.length}
          total={messagesProgress?.length}
          onClose={handleCloseProressModal}
          success={messagesProgress?.length == messagesProgress?.filter(ent => ent?.step === 4)?.length}
          cancelling={cancellingStatus}
        />
      }
    </div>
  );
}

export default connect(
  state => ({
    ...state
  })
)(App)