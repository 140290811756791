/* global chrome */


import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { setUserContacts, setIsLoaded, setIsLoading, setIsUserContactsLoading } from '../../../actions/referrals'

import FullInviteSocialComp from '../../../components/InviteSocial/FullInviteSocialCom'
import MainHeader from '../../../components/MainHeader'
import "./style.scss"
import gmailfulllogo from "../../../assets/icons/gmailfulllogo.svg"
import linkedinfulllogo from "../../../assets/icons/linkedinfullicon.svg"
import { resolveTo } from '@remix-run/router'
import { setIsUserContactsScrapped } from '../../../actions/referrals';
import { ThreeDots } from 'react-loader-spinner';
import { callYoriApi, notify } from '../../../utils';
import CustomToastContainer from '../../../components/ToastContainer';

// import "./style.scss"

function ConnectScreen({ state }) {

  const [useStateLoading, setUseStateLoading] = useState(false)

  let currentLoadingState = useSelector(state => state.referrals?.userContacts?.isLoading)
  let currentLoadedState = useSelector(state => state.referrals?.userContacts?.isLoaded)
  let isDataScrapped = useSelector(state => state.referrals?.userContacts?.isDataScrapped)
  let userContacts = useSelector(state => state.referrals?.userContacts?.userContacts)
  let isDisabled = currentLoadingState || useStateLoading
  let isLoading = currentLoadingState
  let myInterval = null

  useEffect(() => {
    checkIfScrappingHasBeenDone()
  }, [])


  async function checkIfScrappingHasBeenDone() {
    setUseStateLoading(true)
    callYoriApi(`/referral/user_contacts`, "GET")
      .then(response => {
        isDataScrapped = response?.is_all_user_contacts_scraped
        setUseStateLoading(false)
        if (isDataScrapped) {
          dispatch(setIsUserContactsScrapped(response?.is_all_user_contacts_scraped))
          if(userContacts?.length == 0){
            dispatch(setUserContacts(response.payload))
          }
        }
      })
  }

  const dispatch = useDispatch()



  async function getContactsFromBackend() {
    // dispatch(setIsUserContactsLoading(true))
    console.log(`LOADING STATE ============== `, currentLoadingState)
    // fetch
    callYoriApi(`/referral/user_contacts`, "GET")
      .then(response => {
        postContactsToRedux(response)
      })
  }

  async function postContactsToRedux(response) {
    console.log(`IS IT SCRAPED============`, { response, myInterval })
    if (response?.payload) {
      dispatch(setUserContacts(response.payload))
    }
    if (response.is_all_user_contacts_scraped || !response.is_user_contacts_scraping) {
      clearInterval(myInterval)
      routeChange()
    }
  }

  async function fetchContactsFromLinkedIn() {
    try {
      dispatch(setIsUserContactsLoading(true))
      const extId = process.env.REACT_APP_EXT_ID
      chrome.runtime.sendMessage(extId,
        {
          type: "FETCH_USER_LINKEDIN_CONNECTIONS",
        }, (res) => {
          // console.log("=======RES========", res)
          if (!res?.error) {
            myInterval = setInterval(() => getContactsFromBackend(), 2000)
          } else {
            if (res?.error.code == "401") {
              dispatch(setIsUserContactsLoading(false))
              notify("Sign in to Linked in", "error")
              console.log(`======isDataScrapped======`, isDataScrapped)
            }
            else {
              notify("error occured", "error")
            }
          }
        })
    }
    catch (err) {
      console.log("ERROR :", err.message)
    }
  }


  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/invitations_credits/send_invites`;
    navigate(path);
  }


  return (
    <div>

      <MainHeader />
      <CustomToastContainer />
      <div className='connects_screen'>
        {
          // !loading ?
          <React.Fragment>
            <div>
              <p>Connect your LinkedIn to invite your connections</p>
              <span
                className='buttonAndLoaderContainer'
              >
                <div
                  className='buttonContainer'
                >
                  <button
                    className='fetchButton'
                    onClick={() => isDataScrapped ? routeChange() : fetchContactsFromLinkedIn()}
                    disabled={isDisabled}
                    style={(isDisabled) ? { cursor: "not-allowed" } : {}}
                  >
                    <img
                      src={linkedinfulllogo}
                    />
                  </button>
                </div>
                {isLoading && <div
                  className='loaderContainer'
                >
                  <ThreeDots
                    height="inherit"
                    width="50"
                    radius="9"
                    color="#4B44E1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>}
              </span>
            </div>
          </React.Fragment>
        }
      </div>

    </div>
  )
}

export default ConnectScreen
