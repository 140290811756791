import React from 'react'
import "./style.scss"

function SelectBox({ options, onSelect = () => false, id = false, type }) {

    const handleClick = (e) => {
        let filter = e.target.value === "Today" && 'today' || e.target.value === "This Week" && 'week' || e.target.value === "This Month" && 'month'
        onSelect(type, filter)
    }
    return (
        <select className='selectBox' onChange={handleClick}>
            {
                options?.map((option, ind) => <option selected={id == ind}> {option}</option>)
            }
        </select>
    )
}

export default SelectBox