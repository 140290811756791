import React from 'react'
import './style.scss'

const PrimaryButton = ({
    variant = 'Alpha',
    style = {},
    extra = '',
    ...props
}) => {

    return (
        <button
            className={`PrimaryButton PrimaryButton${variant} ${extra}`}
            style={{ padding: '10px', ...style }}
            {...props}
        />
    )
}

export default PrimaryButton