import React, { useEffect, useState } from 'react'
import arrow from "../../assets/icons/downarrow.svg"
import "./style.scss"

function ListTableHeader({titles,sortData}) {
    const length=titles?.length
    // const [arrowFlag,setArrowFlag] = useState([...titles.map(t=>false)])
    
    let [arrowFlag,setArrowFlag]= useState([...titles.map(t=>false)])
    // useEffect(() => {
    //     arrowFlag = [...titles.map(t=>false)]
      
    // }, [])
    

    const sendTitle= (id,title)=>{
        console.log(title," : ",id)
        //  logic for rotating arrow
        let temFlag=[...arrowFlag]
        temFlag[id] = temFlag[id] == true ? false : true 
        setArrowFlag(temFlag)

       if(title == "name" || title == "list name")  sortData("name",temFlag[id])
       if(title == "total contacts") sortData("entity_count",temFlag[id])
       if(title == "total companies") sortData("entity_count",temFlag[id])
       if(title == "created on") sortData("created_at",temFlag[id])
       if(title == "title") sortData("title",temFlag[id])
       if(title == "company") sortData("company_name",temFlag[id])
       if(title == "location") sortData("location",temFlag[id])
       if(title == "size" ) sortData("staff_count",temFlag[id])
       if(title == "company type" ) sortData("ipo_status",temFlag[id])
       if(title == "website" ) sortData("website",temFlag[id])
    }

return (
    <div className="table_actions">
        <div className={length>3 && `actions_size`}>
            {
                titles?.map((title,id)=> <div key={id} onClick={()=>sendTitle(id,title.toLowerCase())}> <span> {title} </span> 
                    <img src={arrow} style={arrowFlag[id] ? {transform:"rotate(180deg)",zIndex:"-1"} : {}}/>
                </div>)
            }
        </div>
        <div className='btns_space'> </div>
    </div>
  )
}

export default ListTableHeader