import React, { useState, useEffect } from 'react'
import DashboardHeader from '../../components/DashboardHeader'
import DashBoardSideBar from '../../components/sideBar'
import leftarrow from "../../../assets/icons/leftarrow.svg"

import "./style.scss"
import { useLocation, useNavigate } from 'react-router'
import UserProfileCard from '../../components/UserProfileCard'
import PrimaryButton from '../../../components/PrimaryButton'
import UserDetails from '../../components/UserDetails'
import UserActivity from '../../components/UserActivity'
import UserReferrals from '../../components/UserReferrals'
import UserProfileViewed from '../../components/UserProfileViewed'
import UserCompaniesViewed from '../../components/UserCompaniesViewed'
import UserLists from '../../components/UserLists'
import { callYoriApi } from '../../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProfile } from '../../../actions/dashboard'

function DashBoardUserProfile() {
    const dispatch = useDispatch()
    const [search, setSearch] = useState("")
    const [userTab, setUserTab] = useState(0)
    const navigate = useNavigate()

    const user = useLocation().state?.user
    const userProfile = useSelector(state => state?.dashboard?.dashboard?.userProfile)

    useEffect(() => {
        dispatch(setUserProfile(user?.id))
    }, [])

    return (
        <div className='dashboard'>

            <DashBoardSideBar tab={1} />

            <div className='main'>
                <DashboardHeader tab={1} search={search} setSearch={setSearch} />
                <div className='userProfile'>
                    <div className='profileHeader' onClick={() => navigate("/admin/dashboard/users")}>
                        <img src={leftarrow} /> <span>  Back to Users </span>
                    </div>

                    <UserProfileCard user={user} showPackage={true} />

                    <div className='tabsSection'>

                        <div className='tabs'>
                            <span className={`${userTab == 0 ? 'active-tab' : ''}`} onClick={() => setUserTab(0)}>Profile</span>
                            <span className={`${userTab == 1 ? 'active-tab' : ''}`} onClick={() => setUserTab(1)}>Activity</span>
                            <span className={`${userTab == 2 ? 'active-tab' : ''}`} onClick={() => setUserTab(2)}> Referrals</span>
                            <span className={`${userTab == 3 ? 'active-tab' : ''}`} onClick={() => setUserTab(3)}>Profiles Viewed</span>
                            <span className={`${userTab == 4 ? 'active-tab' : ''}`} onClick={() => setUserTab(4)}>Companies Viewed</span>
                            <span className={`${userTab == 5 ? 'active-tab' : ''}`} onClick={() => setUserTab(5)}>Lists</span>
                            {/* <span className={`${userTab == 6 ? 'active-tab' : ''}`} onClick={() => setUserTab(6)}>Settings</span> */}
                        </div>
                        {/* <button className='blockUser'>Block User</button> */}
                    </div>


                    {/* this will be section */}

                    {userTab === 0 && <UserDetails
                        user={user}
                    />}
                    {userTab === 1 && <UserActivity
                        user={user}
                    />}
                    {userTab === 2 && <UserReferrals
                        user={user}
                    />}
                    {userTab === 3 && <UserProfileViewed
                        user={user}
                    />}
                    {userTab === 4 && <UserCompaniesViewed
                        user={user}
                    />}
                    {userTab === 5 && <UserLists
                        user={user}
                    />}
                </div>


            </div>
        </div >
    )
}

export default DashBoardUserProfile