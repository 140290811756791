import React from 'react'
import PrimaryButton from '../PrimaryButton';


import "./style.scss"
import background from "../../assets/icons/rewardModalBackground.svg"

const RewardNotificationModal = ({
    open = true,
    onClose,
    badgeSrc,
    rewardDetails = {},

}) => {

    if (!open) return null;

    return (
        <div
            className='overlay '
            onClick={onClose}
            style={{ zIndex: 1 }}
        >
            <div
                className='rewardModalContainer'
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <img
                    className='modalBackGorund'
                    src={background}
                />
                <div className='modalBody'>
                    <h1
                        style={rewardDetails?.rewardType == 'referralSignUp' ? {
                            fontSize: '24px',
                            fontWeight: '600',
                            margin: '56px 0px'
                        } : {}}
                    >
                        Congragulations!
                    </h1>
                    {
                        rewardDetails?.rewardType != 'referralSignUp' && <div
                            className='rewardBadge'
                        >
                            <img
                                src={badgeSrc}
                            />
                        </div>
                    }
                    <div
                        className='rewardDescription'
                    >
                        {
                            rewardDetails?.rewardType == 'invitationAccepted' ? <>
                                <span
                                    className='invitationReward'
                                >
                                    <p>
                                        {rewardDetails?.name} signed up on Yori using your referral link. You just got <span> {rewardDetails?.rewardAmount} credits </span> for as a reward. We have credited the amount to your wallet!
                                    </p>
                                </span>
                            </> : rewardDetails?.rewardType == 'checkPoint' ? <>
                                <span
                                    className='currentReward'
                                >
                                    <p>
                                        You just got
                                        <span> {rewardDetails?.rewardAmount} credits </span>
                                        for inviting
                                        <span> {rewardDetails?.numberOfContactsInvited} </span>
                                        contacts to Yori. The reward has been credited to your wallet!
                                    </p>
                                </span>
                                <span
                                    className='nextReward'
                                >
                                    <p>
                                        Next award: Invite {rewardDetails?.nextNumberOfContactsInvited} contacts and get
                                        <span> {rewardDetails?.nextRewardAmount} credits </span>
                                        !
                                    </p>
                                </span>
                            </> : rewardDetails?.rewardType == 'referralSignUp' ? <>
                                <span
                                    className='signUpReward'
                                >
                                    <p>
                                        You just signed up! Yay! Here are
                                        <span> {rewardDetails?.rewardAmount} credits </span>
                                        just for you.

                                    </p>
                                </span>
                            </> : <>
                                <span
                                    className='currentReward'
                                >
                                    <p>
                                        Error
                                    </p>
                                </span>
                            </>
                        }
                    </div>
                    <div className='btndiv'>
                        <PrimaryButton onClick={onClose} variant='Omega' style={{ fontSize: "14px", padding: "0.3rem 0.8rem" }}>
                            Dismiss
                        </PrimaryButton>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default RewardNotificationModal