import * as ActionTypes from "../../actions/referrals"

const initialState = {
  isLoading: false,
  isLoaded: false,
  invitations_sent: [],
  invitations_accepted: [],

  // messageTemplate: `{first name}, Here is 100 credits to use on Yori.ai a prospecting app that gets you 1000 emails every month along with other key info. No credit card required.\nP.S. When you enroll, i get 100 credits as well.\n\n {link}`

  messageTemplate: `Hey {recipient’s first name}, 
Check out your profile summary I created with Yori: {recipient’s summary}.
Apart from doing this, Yori enables me to generate email content, contact info for 30M profiles, and any company’s details and financials - all on the go. In short, it assists me with sales and recruiting prospecting.

I would love for you to try it out. If you sign up using this link, we both will get 100 credits each:
  
{link}`

}



export const invitations = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_INVITATION_CREDITS:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case ActionTypes.INIT_INVITATION_CREDITS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        invitations_sent: action.payload.sent,
        invitations_accepted: action.payload.accepted
      };
    case ActionTypes.INIT_INVITATION_CREDITS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    case ActionTypes.SET_MESSAGE_TEMPLATE:
      return {
        ...state,
        messageTemplate: action.payload
      };

    case ActionTypes.RESET_MESSAGE_TEMPLATE:
      return {
        ...state,
        messageTemplate: initialState.messageTemplate
      };

    default:
      return state;
  }
};