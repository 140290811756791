import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        }
    },
};


export function LineChart({ values1, values2, color1, color2, labels }) {
    const data = {
        labels,
        datasets: [
            {
                label: " Views ",
                data: values1,
                backgroundColor: color1,
            },
            {
                label: " Views ",
                data: values2,
                backgroundColor: color2,
            },
        ],
    };



    return <Line options={options} data={data} width="530px" height="150px" />;
}
