import * as ActionTypes from "../../actions/auth";

const data = window.localStorage.getItem("user");
const initialState = {
  email: data?.email,
  name: data?.name,
  id:"",
  isLoggedIn: false,
  drop_menu:false,
  publicIdentifier: '',
  userSummary: ''
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        ...action.payload,
      };  
    case ActionTypes.SET_USER_ID:
      return {
        ...state,
        id:action.payload,
      };
    case ActionTypes.SET_DROPMENU:
      return {
        ...state,
        drop_menu:action.payload,
      };
    case ActionTypes.SET_PUBLIC_IDENTIFIER:
      return {
        ...state,
        publicIdentifier: action.payload,
      };
    case ActionTypes.SET_USER_SUMMARY:
      return {
        ...state,
        userSummary: action.payload,
      };

    default:
      return state;
  }
};


