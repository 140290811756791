import React, { useEffect, useState } from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import MainHeader from '../../components/MainHeader'
import "./style.scss"
import InviteSocial from '../../components/InviteSocial'

import WalletTransactions from './Components/WalletTransactions'

import fullLeftArrow from "../../assets/icons/arrow-right.svg"
import UpdateSubscription from './Components/UpgradeSubscription'
import { callYoriApi, notify } from '../../utils'
import { useLocation, useNavigate } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import CustomToastContainer from '../../components/ToastContainer'
import PrimaryButton from '../../components/PrimaryButton'

const Wallet = () => {
    const params = useLocation()?.state
    // console.log("Show Alert ========:", params)

    const navigate = useNavigate()
    const [showSubscriptionPlan, setShowSubscriptionPlan] = useState(params?.selected || false)
    const [invitesFlag, setInvitesflag] = useState(true)
    const [dollarCredits, setDollarCredits] = useState(0)
    const [subscriptionId, setSubscriptionId] = useState(1)
    const [subscriptionDateTime, setSubscriptionDateTime] = useState(0)
    const [walletDetails, setWalletDetails] = useState()


    const updateSubscription = () => {
        // console.log("hello from here")
        setShowSubscriptionPlan(true)
    }

    const onTopUpClick = () => {
        navigate('/wallet/topup')
    }

    const [transactions, setTransactions] = useState([])
    useEffect(async () => {
        const getTransactions = async () => {
            const data = await callYoriApi("/wallet/transactions", "GET")
            // console.log(data.transactions)
            setTransactions(data.transactions)
        }
        getTransactions()
        try {
            const res = await callYoriApi("/wallet/credits", "GET")
            setDollarCredits(res?.doller_credits)
            setSubscriptionId(res?.subscription_id)
            setSubscriptionDateTime(res?.subscription_updated_at)
            setWalletDetails(res)

        } catch (error) {
            console.log("Wallet -> Error:", error)
        }
    }, [])



    useEffect(() => {
        if (params?.showAlert) {
            notify(params.message, "success")
            window.history.replaceState({}, document.title)
        }
    }, [])

    return (
        <div className='wallet'>
            <MainHeader />
            <CustomToastContainer />


            {!showSubscriptionPlan &&
                <div className='wallet_container'>
                    <div className='wallet_left'>
                        <h2>My Wallet</h2>
                        <div>
                            {/* <div>
                                <span>
                                    <h2>${dollarCredits?.toFixed(2) || 0} </h2>
                                    <p>Money I have</p>
                                </span>
                                <div
                                    onClick={onTopUpClick}
                                >
                                    <PrimaryButton
                                        variant='Theta'
                                        style={{ padding: "6px 16px" }}
                                    >
                                        Top Up
                                    </PrimaryButton>
                                </div>
                            </div> */}
                            <div>
                                <span>
                                    <h2>{subscriptionId == 1 && "Free" || subscriptionId == 2 && "Gold" || subscriptionId == 3 && "Platinum"} </h2>
                                    <p>My Subscription</p>
                                </span>
                                <div onClick={updateSubscription}>
                                    <PrimaryButton
                                        variant='Theta'
                                        style={{ padding: "6px 16px" }}
                                    >
                                        Change
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>


                        <div className="wallet_table">
                            <WalletTransactions transactions={transactions} />
                        </div>

                    </div>
                    {/* <div className='wallet_right'>
        
                        <div>
                            <InviteSocial />
                        </div>

                    </div> */}
                </div>
            }

            {/* upgrade subscription */}
            {
                showSubscriptionPlan && <div className='wallet_upgrade'>
                    <div >
                        <span>
                            <img onClick={() => setShowSubscriptionPlan(false)} src={fullLeftArrow} />
                        </span>
                        <h2>Upgrade Subscription</h2>
                    </div>

                    <UpdateSubscription subscription_id={subscriptionId} subscriptionDateTime={subscriptionDateTime} walletDetails={walletDetails} />
                </div>
            }
            {/* <ToastContainer /> */}

        </div>
    )
}

export default Wallet