import React, { useEffect } from 'react'
import Circles from '../Circles'
import "./style.scss"

function ActivityRow({ type, time, view, viewType, link, linkName, platform, icon }) {
    const colors = {
        auth: { innerColor: "#CCE2FF", outerColor: "#EFF6FF" },
        contact: { innerColor: "#77F7C2", outerColor: "#E2FFF3", textColor: "#2B9166", backgroundColor: "#E2FFF3" },
        linkedin: { innerColor: "#5FC0F5", outerColor: "#E5F5FE", textColor: "#1A6F9E", backgroundColor: "#E5F5FE" },
        yori: { innerColor: "#9C97FE", outerColor: "#EAE9FF", textColor: "#6660F0", backgroundColor: "#EAE9FF" },
    }

    useEffect(() => {
        // console.log(platform)
    }, [])
    

    const styles = colors[type]

    return (
        <div className='activityRow'>
            <span>{time}</span>
            <Circles innerColor={styles?.innerColor} outerColor={styles?.outerColor} />
            {view && <span>{view}</span>}
            {
                viewType &&
                <span style={{ backgroundColor: styles?.backgroundColor, color: styles?.textColor, padding: "0.1rem 0.5rem" }}>{viewType}</span>
            }
            {linkName && <a href={link} target='_blank'> {linkName?.length > 50 ? linkName?.slice(0 , 50) + '...' : linkName}</a>}
            {platform && <span>{platform}</span>}
            {icon && <img src={icon} />}

        </div>)
}

export default ActivityRow