import React from 'react'
import "./style.scss"
import alertIcon from "../../assets/icons/alert-circle.svg"
import PrimaryButton from '../PrimaryButton'
import { useLocation, useNavigate } from 'react-router'

function TopAlert({ text, icon = false }) {
    const navigate = useNavigate()

    return (
        <div className='top_alert'>
            <div>
                <img src={icon ? icon : alertIcon} />
                <span>
                    {text}
                </span>
            </div>
            <div className='top_alert_button'>
                <PrimaryButton
                    variant='Theta'
                    style={{ padding: "6px 16px" }}
                    onClick={() => navigate('/wallet/topup')}
                // disabled={path?.pathname === "/wallet/topup"}
                >
                    Top Up
                </PrimaryButton>
            </div>
        </div>
    )
}

export default TopAlert