import React, { useEffect, useState } from 'react'
import PieChartBox from '../../components/PieChartBox'
import SelectBox from '../../components/SelectBox'
import TotalStatsBox from '../../components/TotalStatsBox'
import BarChartBox from '../../components/BarChartBox'
import "./style.scss"
import DashBoardSideBar from '../../components/sideBar'
import DashboardHeader from '../../components/DashboardHeader'
import { callYoriApi } from '../../../utils'
import GroupChartBox from '../../components/BarChartBox'


function DefaultScreen() {
    const [search, setSearch] = useState("")
    const [tab, setTab] = useState(0)
    const [barChartWeekLabels, setBarChartWeekLabels] = useState(['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']);
    const [barChartMonthLabels, setBarChartMonthLabels] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]);
    // const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]


    const [data, setData] = useState()
    const [topStats, setTopStats] = useState({
        activeUsers: "loading...",
        newUsers: "loading...",
        profileViews: "loading...",
        companyViews: "loading...",
        contactsViews: "loading...",
    })

    const [profileViewsBarChartData, setProfileViewsBarChartData] = useState({
        labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        line1Data: [],
        line2Data: [],
        yoriTotal: 'Loading',
        liTotal: 'Loading',
    })
    const [contactsViewsBarChartData, setContactsViewsBarChartData] = useState({
        labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        barChartData: [],
        total: "loading..."
    })
    const [companiesViewsBarChartData, setCompaniesViewsBarChartData] = useState({
        labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        line1Data: [],
        line2Data: [],
        yoriTotal: 'Loading',
        liTotal: 'Loading',
    })
    const [newSubscriptionBarChartData, setNewSubscriptionBarChartData] = useState({
        labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        barChartData: [],
        total: "loading..."
    })

    const [signUpPieChartData, setSignUpPieChartData] = useState({
        newSignups: "Loading...",
        organicSignUps: 0,
        invitedSignUps: 0
    })
    const [invitesPieChartData, setInvitesPieChartData] = useState({
        invites: "loading...",
        usersInvited: 0,
        invitedSignUps: 0
    })

    const date = (new Date()).toISOString().split('T')[0]
    useEffect(async () => {
        try {
            // console.log(date)
            setData(await callYoriApi("/admin/dashboard", "POST", { body: JSON.stringify({ date, default: true }) }))
        } catch (error) {

        }
    }, [])

    const transformToBarChartData = (type, rawData) => {
        // console.log(rawData)
        switch (type) {
            case "profileViews":
                setProfileViewsBarChartData({
                    labels: barChartWeekLabels,
                    line1Data: (data?.profile_views?.yori_views?.map(view => view.count)),
                    line2Data: (data?.profile_views?.li_views?.map(view => view.count)),
                    yoriTotal: data?.profile_views?.yori_views?.reduce((acc, val) => val.count + acc, 0),
                    liTotal: data?.profile_views?.li_views?.reduce((acc, val) => val.count + acc, 0),
                })
                break;
            case "contactViews":
                setContactsViewsBarChartData({
                    labels: barChartWeekLabels,
                    barChartData: (data?.contacts_views?.map(view => view.count)),
                    total: data?.contacts_views?.reduce((acc, val) => val.count + acc, 0)
                })
                break;
            case "companyViews":
                setCompaniesViewsBarChartData({
                    labels: barChartWeekLabels,
                    line1Data: (data?.company_views?.yori_views?.map(view => view.count)),
                    line2Data: (data?.company_views?.li_views?.map(view => view.count)),
                    yoriTotal: data?.company_views?.yori_views?.reduce((acc, val) => val.count + acc, 0),
                    liTotal: data?.company_views?.li_views?.reduce((acc, val) => val.count + acc, 0),
                })
                break;
            case "newSubscriptions":
                setNewSubscriptionBarChartData({
                    labels: barChartWeekLabels,
                    barChartData: (data?.new_subscriptions?.map(view => view.count)),
                    total: data?.new_subscriptions?.reduce((acc, val) => val.count + acc, 0)
                }
                )
                break;

        }
    }




    useEffect(() => {
        try {
            if (data) {
                setTopStats({
                    activeUsers: data?.active_users.length,
                    newUsers: data?.new_users.length,
                    profileViews: data?.profile_views?.yori_views?.[data?.profile_views?.yori_views?.length - 1]?.count,
                    companyViews: data?.company_views?.yori_views?.[data?.company_views?.yori_views?.length - 1]?.count,
                    // companyViews: data?.company_views?.[data?.company_views.length - 1]?.count,
                    contactsViews: data?.contacts_views?.[data?.contacts_views.length - 1]?.count,
                })

                // set this logic as well

                setSignUpPieChartData({
                    newSignups: data?.new_users.length,
                    organicSignUps: data?.new_users?.filter(user => !user.referral_id).length,
                    invitedSignUps: data?.new_users?.filter(user => user.referral_id).length
                })
                setInvitesPieChartData({
                    usersInvited: data?.users_invited?.length,
                    invitedSignUps: data?.new_users?.filter(user => user.referral_id).length
                })

                console.log('it was shifted here')
                transformToBarChartData("profileViews", data)
                transformToBarChartData("contactViews", data)
                transformToBarChartData("companyViews", data)
                transformToBarChartData("newSubscriptions", data)


            }
        } catch (error) {

        }
    }, [data])


    const onSelect = async (type, filter) => {
        type === 'topStats' && setTopStats({ activeUsers: "loading...", newUsers: "loading...", profileViews: "loading...", companyViews: "loading...", contactsViews: "loading...", })
        type === 'newSignUps' && setSignUpPieChartData({ newSignups: "loading...", organicSignUps: 0, invitedSignUps: 0 })
        type === 'invites' && setInvitesPieChartData({ invites: "loading...", usersInvited: 0, invitedSignUps: 0 })
        type === 'profileViews' && setProfileViewsBarChartData({ labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'], line1Data: [], line2Data: [], yoriTotal: "loading...", liTotal: "loading..." })
        type === 'contactsViews' && setContactsViewsBarChartData({ labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'], barChartData: [], total: "loading..." })
        type === 'companyViews' && setCompaniesViewsBarChartData({ labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'], line1Data: [], line2Data: [], yoriTotal: "loading...", liTotal: "loading..." })
        type === 'newSubscriptions' && setNewSubscriptionBarChartData({ labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'], barChartData: [], total: "loading..." })

        const res = await callYoriApi("/admin/dashboard", "POST", { body: JSON.stringify({ type, filter }) })


        if (type === "topStats") {
            setTopStats(res)
        }
        if (type === "newSignUps") {
            setSignUpPieChartData({
                newSignups: res?.new_signups?.length,
                organicSignUps: res?.new_signups?.filter(user => !user.referral_id).length,
                invitedSignUps: res?.new_signups?.filter(user => user.referral_id).length
            })
        }
        if (type === "invites") {
            setInvitesPieChartData({
                invites: res?.users_invited,
                usersInvited: res?.users_invited,
                invitedSignUps: res?.new_users
            })
        }
        if (type === "profileViews") {
            setProfileViewsBarChartData({
                labels: filter === 'week' ? barChartWeekLabels : (filter === 'month' ? barChartMonthLabels : ["Today", "", "", "", "", ""]),
                line1Data: (res?.profile_views?.yori_views?.map(view => view.count)),
                line2Data: (res?.profile_views?.li_views?.map(view => view.count)),
                yoriTotal: res?.profile_views?.yori_views?.reduce((acc, val) => val.count + acc, 0),
                liTotal: res?.profile_views?.li_views?.reduce((acc, val) => val.count + acc, 0),
            })
        }
        if (type === "contactsViews") {
            setContactsViewsBarChartData({
                labels: filter === 'week' ? barChartWeekLabels : (filter == 'month' ? barChartMonthLabels : ["Today", "", "", "", "", ""]),
                barChartData: (res?.contacts_views?.map(view => view.count)),
                total: res?.contacts_views?.reduce((acc, val) => val.count + acc, 0)
            })
        }
        if (type === "companyViews") {
            setCompaniesViewsBarChartData({
                labels: filter === 'week' ? barChartWeekLabels : (filter === 'month' ? barChartMonthLabels : ["Today", "", "", "", "", ""]),
                line1Data: (res?.company_views?.yori_views?.map(view => view.count)),
                line2Data: (res?.company_views?.li_views?.map(view => view.count)),
                yoriTotal: res?.company_views?.yori_views?.reduce((acc, val) => val.count + acc, 0),
                liTotal: res?.company_views?.li_views?.reduce((acc, val) => val.count + acc, 0),
            })
        }
        if (type === "newSubscriptions") {
            setNewSubscriptionBarChartData({
                labels: filter === 'week' ? barChartWeekLabels : (filter == 'month' ? barChartMonthLabels : ["Today", "", "", "", "", ""]),
                barChartData: (res?.new_subscriptions?.map(view => view.count)),
                total: res?.new_subscriptions?.reduce((acc, val) => val.count + acc, 0)
            })
        }

        // console.log({ type, filter, res })
    }



    return (
        <div className='dashboard'>

            <DashBoardSideBar tab={tab} setTab={setTab} />

            <div className='main'>
                <DashboardHeader tab={tab} search={search} setSearch={setSearch} />
                <div className='dasbboardBody'>

                    <div className='headrow'>
                        <span>Users</span>

                        <SelectBox options={["Today", "This Week", "This Month"]} onSelect={onSelect} type="topStats" />

                    </div>
                    {/* stats section */}
                    <div className='statsSection'>
                        <TotalStatsBox title="Active Users" value={topStats?.activeUsers} percentage="+11.01" rise={true} />
                        <TotalStatsBox title="New Users" value={topStats?.newUsers} percentage="+9.15" rise={true} />
                        <TotalStatsBox title="Profile Views" value={topStats?.profileViews} percentage="-0.56" rise={true} />
                        <TotalStatsBox title="Contacts Viewed" value={topStats?.contactsViews} percentage="-1.48" rise={true} />
                        <TotalStatsBox title="Company Views" value={topStats?.companyViews} percentage="-1.48" rise={true} />
                    </div>

                    <div className='ChartsRow'>
                        <PieChartBox mainTitle={"New Signups"} total={signUpPieChartData?.newSignups} titles={
                            [
                                `${signUpPieChartData?.organicSignUps} Organic Signups`,
                                `${signUpPieChartData?.invitedSignUps} Invited Customer`
                            ]}
                            data={[signUpPieChartData?.organicSignUps, signUpPieChartData?.invitedSignUps]} onSelect={onSelect} type="newSignUps" />
                        <PieChartBox mainTitle={"Invites"}
                            total={invitesPieChartData?.invites} titles={[`${invitesPieChartData?.usersInvited} User Invited`, `${invitesPieChartData?.invitedSignUps} User Signup`]}
                            data={[invitesPieChartData?.usersInvited, invitesPieChartData?.invitedSignUps]} onSelect={onSelect} type="invites" />
                    </div>
                    <div className='ProfileViewsBox'>
                        <GroupChartBox data={profileViewsBarChartData} chartType='lineChart' mainTitle={"Profile Views"} color='rgb(75, 68, 225)' type="profileViews" onSelect={onSelect} />
                        <GroupChartBox data={companiesViewsBarChartData} chartType='lineChart' mainTitle={"Companies Viewed"} color='rgb(75, 68, 225)' type="companyViews" onSelect={onSelect} />
                    </div>
                    <div className='ProfileViewsBox'>
                        <GroupChartBox data={contactsViewsBarChartData} mainTitle={"Contacts Viewed"} color='rgb(75, 68, 225)' type="contactsViews" onSelect={onSelect} />
                        <GroupChartBox data={newSubscriptionBarChartData} mainTitle={"New Subscriptions"} color='rgb(70, 231, 192)' type="newSubscriptions" onSelect={onSelect} />
                    </div>
                </div>
            </div>
        </div>


    )
}

export default DefaultScreen