import React, { useEffect, useState } from 'react'
import {isMobile} from 'react-device-detect';

import HomeContainerPlaqueComponent from '../HomeContainerPlaqueComponent'
import heroSecAsset1 from '../../img/heroSecAsset1.svg'
import heroSecAsset1Mob from '../../img/heroSecAsset1Mob.svg'
import heroSecAsset2 from '../../img/heroSecAsset2.svg'
import heroSecAsset2Mob from '../../img/heroSecAsset2Mob.svg'
import heroSecAsset3 from '../../img/heroSecAsset3.svg'
import heroSecAsset4 from '../../img/heroSecAsset4.svg'
import heroSecAsset4Mob from '../../img/heroSecAsset4Mob.svg'
import heroSecAsset5 from '../../img/heroSecAsset5.svg'
import heroSecAsset5Mob from '../../img/heroSecAsset5Mob.svg'
import heroSecAsset6 from '../../img/heroSecAsset6.svg'
import heroSecAsset6Mob from '../../img/heroSecAsset6Mob.svg'
import heroSecAsset7 from '../../img/heroSecAsset7.svg'
import heroSecAsset7Mob from '../../img/heroSecAsset7Mob.svg'
import RightArrow from '../RightArrow'
import PillComponent from '../PillComponent'

import './style.scss'
const PlaquesComponent = ({
    onGetExtension
}) => {

    const [executiveChartHoverHandler, setexecutiveChartHoverHandler] = useState(false)
    const [accessContactsHoverHandler, setAccessContactsHoverHandler] = useState(false)
    const [companyDetailsHoverHandler, setcompanyDetailsHoverHandler] = useState(false)

  return (
    <div
        className='homeContainerPlaquesParent'
    >
        <h1>What Yori enables you to do</h1>
    {/* ======================================================================================================================================================                 */}
        <div
            className='plaquesCompSatyaProfiles'
        >                    
            <div
                className='summarizeProfile'
            >
                <h2>Summarize a Profile</h2>
                <img src={heroSecAsset4}/>
                <img src={heroSecAsset4Mob}/>
            </div>           
            <div
                className='genMail'
            >
                <h2>Generate a unique email</h2>
                <img src={heroSecAsset6}/>
                <img src={heroSecAsset6Mob}/>
            </div>
        </div>
    {/* ======================================================================================================================================================                 */}
        <div
            className='plaquesCompExampleQuestions'
        >
            <h2>Ask any question about a profile</h2>
            <span>
                <div>
                    <PillComponent text="What is Satya most skilled in?"/>
                    <PillComponent text="Does Satya have any experience leading cloud-based businesses?"/>
                </div>
                <div>
                    <PillComponent text="What teams did Satya lead at Microsoft?"/>
                    <PillComponent text="Will Satya make a good CEO for Twitter?"/>
                </div>
            </span>
            <span
                className='bottomPill'
            >
                <PillComponent text="What are Satya's educational credentials?"/>
            </span>
        </div>
    {/* ======================================================================================================================================================                 */}
        <div
            className='plaquesCompAccessToProfiles'
        >
            <span>
                <h2>Access contacts of over 30M profiles</h2>
                {
                    !isMobile ? 
                    <>
                        <button
                            onClick={onGetExtension}
                            onMouseEnter={() => setAccessContactsHoverHandler(true)}
                            onMouseLeave={() => setAccessContactsHoverHandler(false)}
                        >
                            Start Exploring Profiles
                            <RightArrow variant={accessContactsHoverHandler ? 'blue' : 'white'}/>
                        </button>
                    </> : <></>
                }
            </span>
            <img src={heroSecAsset1}/>
            <img src={heroSecAsset1Mob}/>
        </div>

    {/* ======================================================================================================================================================                 */}

        <div
            className='plaquesCompPersonViewCTA'
        >
            <div
                className='domainAndSkillsCTA'
            >
                <h2>Get a score of a person's domains and skills</h2>
                <img src={heroSecAsset2}/>
                <img src={heroSecAsset2Mob}/>
            </div>
            <div
                className='contactInfoCTA'
            >
                <h2>Find DEI Information - meet your diversity goals</h2>
                <img src={heroSecAsset3}/>
            </div>
        </div>

    {/* ======================================================================================================================================================                 */}

        <div
            className='plaquesCompCompanyViewCTA'
        >
            <span>
                <h2>View executive chart of a company</h2>
                {
                    !isMobile ?
                    <>
                        <button
                            onClick={onGetExtension}
                            onMouseEnter={() => setexecutiveChartHoverHandler(true)}
                            onMouseLeave={() => setexecutiveChartHoverHandler(false)}    
                        >
                            Start Exploring Charts
                            <RightArrow variant={executiveChartHoverHandler ? 'white' : 'black'}/>
                        </button>
                    </> : <></>
                }
            </span>
            <img src={heroSecAsset5}/>
            <img src={heroSecAsset5Mob}/>
        </div>

    {/* ======================================================================================================================================================                 */}

        <div
            className='plaquesCompCompanyFinancialsCTA'
        >
            <span>
                <h2>
                    Find a company's details and financials
                </h2>
                {
                    !isMobile ? 
                    <>
                        <button
                            onClick={onGetExtension}
                            onMouseEnter={() => setcompanyDetailsHoverHandler(true)}
                            onMouseLeave={() => setcompanyDetailsHoverHandler(false)}
                        
                        >
                            Start Exploring Charts
                            <RightArrow variant={companyDetailsHoverHandler ? 'blue' : 'black'}/>
                        </button>
                    </> : <></>
                }
            </span>
            <img src={heroSecAsset7}/>
            <img src={heroSecAsset7Mob}/>
        </div>
    </div>
  )
}

export default PlaquesComponent