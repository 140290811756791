import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initEarning } from '../../../../actions/referrals'
import { invitationSentMilestones } from '../../../../utils'
import CheckPointLabel from './CheckPointLabel'
import profileImagePlaceholder from '../../../../assets/icons/profile.png'
import './style.scss'

const CheckpointProgressComponent = ({

}) => {

    const dispatch = useDispatch()
    const [invitationsSentBelowTen, setInvitationsSentBelowTen] = useState(false)
    const [invitationsSentAboveTenThousand, setInvitationsSentAboveTenThousand] = useState(false)
    const [dataToDisplay, setDataToDisplay] = useState({
        milestoneAcheived: 0,
        nextMilestone: 0,
    })

    let {
        isLoading,
        isLoaded,
        invitations_sent,
        invitations_accepted
    } = useSelector(state => state.referrals?.invitations)


    let {
        isEarningLoading,
        isEarningLoaded,
        earning
    } = useSelector(state => state.referrals?.rewards)

    const milestoneInvites = Object.keys(invitationSentMilestones)
    const milestoneAwards = Object.values(invitationSentMilestones)

    // const invitations_sent = [
    //     {}, {}, {}, {}, {}, {} , {}, {}, {}, {}, {}, {} , {}, {}, {}, {}, {}, {} 
    // ]

    useEffect(() => {
        dispatch(initEarning())
    }, [])



    useEffect(() => {
        if (isLoaded) {
            for (let i = 0; i <= milestoneAwards.length; i++) {
                if (invitations_sent.length < milestoneInvites[i] && milestoneInvites[i] == 10) {
                    // console.log(`invitations_sent.length < milestoneInvites[i] && milestoneInvites[i] == 10======`, {
                    //     'invitations_sent.length < milestoneInvites[i] && milestoneInvites[i] == 10': invitations_sent.length < milestoneInvites[i] && milestoneInvites[i] == 10,
                    //     'milestoneInvites[i]': milestoneInvites[i]
                    // })
                    setInvitationsSentBelowTen(true)
                    return
                }
                else if (invitations_sent.length < milestoneInvites[i] && milestoneInvites[i] != 10) {
                    console.log(`invitations_sent.length < milestoneInvites[i] && milestoneInvites[i] != 10======`, {
                        'invitations_sent.length < milestoneInvites[i] && milestoneInvites[i] == 10': invitations_sent.length < milestoneInvites[i] && milestoneInvites[i] != 10,
                        'milestoneInvites[i]': milestoneInvites[i]
                    })
                    setDataToDisplay({
                        milestoneAcheived: milestoneInvites[i - 1],
                        nextMilestone: milestoneInvites[i]
                    })
                    return
                }
                else if (invitations_sent.length == milestoneInvites[i] && milestoneInvites[i] != 10000) {
                    console.log(`invitations_sent.length < milestoneInvites[i] && milestoneInvites[i] != 10======`, {
                        'invitations_sent.length < milestoneInvites[i] && milestoneInvites[i] == 10': invitations_sent.length == milestoneInvites[i] && milestoneInvites[i] != 10000,
                        'milestoneInvites[i]': milestoneInvites[i]
                    })
                    setDataToDisplay({
                        milestoneAcheived: milestoneInvites[i],
                        nextMilestone: milestoneInvites[i + 1]
                    })
                    return
                }
                else if (invitations_sent.length >= milestoneInvites[i] && milestoneInvites[i] == 10000) {
                    setInvitationsSentAboveTenThousand(true)
                    return
                }
            }
        }
    }, [isLoaded])



    return (
        <div
            className='checkpointProgressComponent'
        >
            {
                !invitationsSentAboveTenThousand ? <div
                    className='progressBar'
                >
                    {
                        invitationsSentBelowTen ?
                            <div className='nextRewardIsTen'>
                                <span
                                    className='userDetails'
                                >
                                    <img src={profileImagePlaceholder} />
                                    <p>You</p>
                                </span>
                                <div
                                    className='progressBarContainer'
                                >
                                    <p>
                                        {
                                            (!isLoaded) ? `Loading` : `${10 - invitations_sent.length} more invites to go`
                                        }
                                    </p>
                                    <div
                                        className='progressBarBackground'
                                    >
                                        <div
                                            className='progressBarProgress'
                                            style={{
                                                width: !isLoaded ? '0%' : `${(invitations_sent.length / 10) * 100}%`
                                            }}
                                        />
                                    </div>
                                </div>
                                <CheckPointLabel
                                    nextCheckPoint={true}
                                    amountOfCheckPoint={milestoneAwards[0]}
                                    invitesOfCheckpoint={milestoneInvites[0]}
                                />
                            </div> : <>
                                <CheckPointLabel
                                    amountOfCheckPoint={invitationSentMilestones[dataToDisplay.milestoneAcheived]}
                                    invitesOfCheckpoint={dataToDisplay.milestoneAcheived}
                                />
                                <div
                                    className='progressBarContainer'
                                >
                                    <p>
                                        {
                                            (!isLoaded) ? `Loading` : `${dataToDisplay.nextMilestone - invitations_sent.length} more invites to go`
                                        }
                                    </p>
                                    <div
                                        className='progressBarBackground'
                                    >
                                        <div
                                            className='progressBarProgress'
                                            style={{
                                                width: !isLoaded ? '0%' : `${(invitations_sent.length / dataToDisplay.nextMilestone) * 100}%`
                                            }}
                                        />
                                    </div>
                                </div>
                                <CheckPointLabel
                                    nextCheckPoint={true}
                                    amountOfCheckPoint={invitationSentMilestones[dataToDisplay.nextMilestone]}
                                    invitesOfCheckpoint={dataToDisplay.nextMilestone}
                                />
                            </>
                    }
                </div> : <></>
            }
            {/* <div
                className={invitationsSentAboveTenThousand ? 'allRewardsEarned' : 'rewardsEarned'}
            >
                <h1>{isEarningLoaded ? `${earning ? (earning) : 0} credits` : 'Loading'}</h1>
                <p>Rewards earned so far</p>
            </div> */}
        </div>
    )
}

export default CheckpointProgressComponent