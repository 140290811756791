/* global chrome */

import { getTextWidth } from "get-text-width";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch } from "react-redux";
import refreshToken from "./services/refreshToken";
import { toast } from "react-toastify";

import tenContactsBadge from './assets/icons/tenContactsBadge.svg'
import hunderedContactsBadge from './assets/icons/hunderedContactsBadge.svg'
import fiveHunderedContactsBadge from './assets/icons/fiveHunderedContactsBadge.svg'
import thousandContactsBadge from './assets/icons/thousandContactsBadge.svg'
import tenThousandContactsBadge from './assets/icons/tenThousandContactsBadge.svg'

export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value)?.length === 0) ||
  (typeof value === 'string' && value.trim()?.length === 0);

export const stringEquality = (str1 = "", str2 = "") => str1?.trim()?.toLowerCase() === str2?.trim()?.toLowerCase()

export const isLoggedIn = async (auth) => await new Promise((resolve, reject) => (onAuthStateChanged(auth, (user) => {
  // console.log("=====================onAuthStateChanged========================", { user })
  return resolve(user)
})))

export const capitalizeFirstAlphabet = (str = "") => {
  if (str == "") {
    return ("")
  }
  // console.log("================capitalizeFirstAlphabet===================", { str })
  return ((str?.charAt(0)?.toUpperCase() || " ") + (str?.slice(1) || ""));
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export async function awaitfunction(ms = 4000) {
  return await new Promise((resolve, reject) => setTimeout(() => {
    resolve(true)
  }, ms))
}

export const calculateStringWidth = (string = ``) => {
  return (getTextWidth(string))
}

export const calculateTextForGivenWidth = (string, stringLengthCap) => {
  const stringSplitted = string.split("")
  // console.log(`======stringSplitted=======` , stringSplitted)
  let stringResponse = ``
  let stringResponseArray = []
  for (let i = 0; i < stringSplitted?.length; i++) {
    if (calculateStringWidth(stringResponse) >= stringLengthCap) {
      stringResponse = stringResponse.concat(` . . . `)
      return (stringResponse)
    } else {
      stringResponseArray.push(stringSplitted[i])
      stringResponse = stringResponseArray.join('')
      // console.log(`======stringResponse=======` , stringResponse)
    }

    if (string == stringResponse) {
      return (stringResponse)
    }
  }
}


export const callYoriApi = async (api, method, options) => {

  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("TKNV1")
    if (!token) {
      console.log("Token is removed from local storage", api)
      return signOut()
    }
    // console.log('$$$$$$$callYoriApi$$$$$$$$$===========THEN', { api, method, token, options })
    const headers = {}
    if (method === "POST" || method === "PATCH" || method === "DELETE") {
      headers["Content-Type"] = "application/json"
    }
    fetch(process.env.REACT_APP_API_URL + api, {
      ...options,
      method: method,
      headers: {
        ...headers,
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(r => r.json())
      .then(data => {
        // console.log("^^^^^^^^^^^^^^^^^callYoriApi^^^^^^^^^^^^^^response^^^^^^", { api, data })
        if (data?.error == "User not found in DB") {
          console.log("Token is removed from local storage on error")
          localStorage.removeItem("idToken")
          localStorage.removeItem("refreshToken")
        }
        resolve(data)
      })
      .catch(e => {
        console.log('$$$$$$$callYoriApi$$$$$$$$$=======EROR', e?.message)
        reject(e)
      }
      )
  }
  )
}

export const invitationSentMilestones = {
  10: 5,
  100: 250,
  500: 500,
  1000: 750,
  10000: 1000,
}
export const invitationSentMilestonesBadges = [
  tenContactsBadge,
  hunderedContactsBadge,
  fiveHunderedContactsBadge,
  thousandContactsBadge,
  tenThousandContactsBadge
]

export const invitationAcceptedMilestones = {
  1: 100
}

export const calculateProgressToNextMilestone = (count, milestones) => {
  let message = ''
  if ('1' in milestones) {
    message = `1 more for a ${invitationAcceptedMilestones?.[1]} credits Reward`
  } else if (!('1' in milestones)) {
    const packageCount = Object.keys(milestones)
    for (let i = 0; i < packageCount?.length; i++) {
      if (count < packageCount[i] && !((i + 1 < packageCount?.length - 1) ? (count > packageCount[i + 1]) : false) && count !== packageCount[i]) {
        message = `Invite ${packageCount[i] - count} more for a ${milestones[packageCount[i]]} credits Reward`
        break
      } else if (count === packageCount[i]) {
        message = `Invite ${packageCount[i + 1] - count} more for a ${milestones[packageCount[i + 1]]} credits Reward`
        break
      } else if (count > packageCount[packageCount?.length - 1]) {
        message = "No More Packages"
        break
      }
    }
  }
  return (message)
}


// export const RefreshAndSaveToken = async () => {
//   console.log("========Token is refresing========")
//   localStorage.setItem("refreshing", true)
//   const token = await refreshToken()
//   if (!token) {
//     console.log("====Called====")
//     localStorage.setItem("refreshing", false)
//     return
//   }
//   localStorage.setItem("idToken", token)
//   localStorage.setItem("lastRefresh", Date.now())
//   localStorage.setItem("refreshing", false)
// }

export const packages = {
  1: { subscription_id: 1, contacts: 25, amount: 0 },
  2: { subscription_id: 2, contacts: 1000, amount: 29.99 },
  3: { subscription_id: 3, contacts: 3000, amount: 59.99 }
}

export function detectCardType(number) {
  var re = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
  }

  for (var key in re) {
    if (re[key].test(number)) {
      return key
    }
  }
}

export const signOut = async (navigate) => {
  // console.log("Before ===:",getAuth())
  // gapi.load('auth2', function () { gapi.auth2.init().then(function () { var auth2 = gapi.auth2.getAuthInstance(); auth2.signOut().then(function () { localStorage.clear() }); }); });

  // const auth2 = window.gapi.auth2.getAuthInstance()
  // if (auth2 != null) {
  //   auth2.signOut().then(localStorage.clear())
  // }


  await trackUserActivity({
    action: "AUTH",
    entity_id: null,
    entity_type: "users",
    context: {
      name: 'LOG OUT'
    },
  }).then(
    () => {

      const extId = process.env.REACT_APP_EXT_ID
      if (extId && chrome.runtime) {
        chrome.runtime.sendMessage(extId, { type: "AUTH_LOGOUT" }, (res) => { })
      }
      localStorage.clear()

      navigate("/login")
    }
  )
}

export const calculateIncentive = (contacts_rem, subscirptionDate, cur_package) => {
  const current_date = new Date()
  const days_used = Math.round((current_date.getTime() - parseInt(subscirptionDate)) / (1000 * 3600 * 24))
  // console.log("days_used :", days_used)
  const contacts_used = 1000 - contacts_rem
  // console.log("contacts_used :", contacts_used, "  :  ", contacts_used / cur_package.contacts)
  const deciding_factor = Math.max(days_used / 30, contacts_used / cur_package.contacts);
  const dollars_to_be_added = (1 - deciding_factor) * cur_package.amount
  // console.log("dollars_to_be_added :", dollars_to_be_added);
  return Math.round((dollars_to_be_added + Number.EPSILON) * 100) / 100
}

export const getNewMonthCurDate = (dateTimeStamp) => {
  var now = new Date(dateTimeStamp);
  const day = now.getDate()
  let current = new Date(now.getFullYear(), now.getMonth() + 1, day);
  return current.toDateString().slice(4)
}


export const getTimeToSubscriptionExp = (dateTimeStamp) => {
  var then = new Date(dateTimeStamp);
  const day = then.getDate()
  let next = new Date(then.getFullYear(), then.getMonth() + 1, day);
  const differenceInDays = Math.floor((next.getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000))
  return differenceInDays
}


export const notify = (message, type = null) => {
  if (type == "success") {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }
  else if (type == "error") {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }
  else if (type == "info") {
    toast.info(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }
  else {
    const toastId = toast(message, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    console.log("=========TOAST ID========== :", toastId)
    return toastId
  }
}



export const setTimeString = (d) => {
  // console.log("date :", new Date(d).toLocaleString('en-us', { month: 'short', year: 'numeric' }))
  return new Date(d).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })

}

export const customOnlyNumberOnChange = (string) => {
  let stringArr = string.split('')
  let stringArrNumbers = stringArr.filter(character => (parseInt(character) || character == '0'))
  // console.log(`stringArrNumbers` , stringArrNumbers )
  if (stringArrNumbers?.length == stringArr?.length) {
    return (string)
  } else {
    return (stringArrNumbers.join(''))
  }

}

export const customAlphabetFilterOnChange = (string) => {


}

export const getBadgeToSend = (invitesSent) => {
  const inviteCheckpoints = Object.keys(invitationSentMilestones)
  console.log('invitationSentMilestonesBadges', invitationSentMilestonesBadges)
  inviteCheckpoints.map((checkpoint, index) => {
    if (invitesSent >= checkpoint) {
      console.log('invitationSentMilestonesBadges[index]', invitationSentMilestonesBadges[index])
      return (invitationSentMilestonesBadges[index])
    } else if (invitesSent < 10) {
      console.log('invitationSentMilestonesBadges[0]', invitationSentMilestonesBadges[0])
      return (tenContactsBadge)
    }
  })
}

export const handleTagRemoval = (tags, oldValue, newValue, inputRef) => {
  // console.log(`Values of inputRef are as follows:::::::::::::` , {"inputRef?.current" : inputRef?.current})
  let start = oldValue.split("").findIndex((el, index) => el !== newValue.charAt(index))
  let end = oldValue?.length - oldValue.split("").reverse().findIndex((el, index) => el !== newValue.charAt(newValue?.length - index - 1))
  const sortedTags = tags.reduce((prev, tag) => {
    return [...prev, ...[...oldValue.matchAll(tag)]]
  }, []).sort((a, b) => a["index"] - b["index"])

  let filteredTags = sortedTags.filter(tag => {
    // console.log("====================================================================================")
    let tagIndex = tag["index"]
    let tagLenIndex = tagIndex + tag[0]?.length
    // console.log("Tag index :::::::::::::::" , tagIndex)
    // console.log("Tag length index :::::::::::::::" , tagLenIndex)
    // console.log("tagLenIndex >= start + 1::::::::::::" , tagLenIndex >= start + 1)
    // console.log("tagIndex <= end - 1:::::::::::::::" , tagIndex <= end - 1)
    // console.log("Gotcha bitch ::::::::::::::::::::::::;" , {start , end})
    return tagLenIndex >= start + 1 && tagIndex <= end - 1
  })
  if (filteredTags?.length > 0) {
    let min = filteredTags[0]
    let max = filteredTags[filteredTags?.length - 1]
    return {
      value:
        oldValue.slice(0, min["index"] >= start ? start : min["index"]) +
        oldValue.slice(max["index"] + max[0]?.length >= end ? max["index"] + max[0]?.length : end),

      min: min["index"] >= start ? start : min["index"],

      max: max["index"] + max[0]?.length >= end ? max["index"] + max[0]?.length : end
    }

  }
  else if (filteredTags?.length == 0) {
    return { value: newValue, min: -1, max: -1 }
  }
}

export const invitationModalTags = [
  "{first name}",
  "{link}",
  "{my first name}",
  "{summary}",
  "{full name}",
  "{my full name}"
]

export const invitationModalTags2 = [
  "{recipient’s first name}",
  "{recipient’s full name}",
  "{recipient’s summary}",
  // "{recipient’s top skills}",
  // "{recipient’s top companies}",
  "{link}",
  "{my first name}",
  "{my full name}",
]

export const trackUserActivity = async (body) => {
  try {
    console.log("============TRACKING=============", { body })
    const url = `/admin/activity`
    return await callYoriApi(url, 'POST', { body: JSON.stringify(body) })
    // console.log("=====================scrapePersonLi=========================", {payload})
  }
  catch (err) {
    console.log("ERROR: ", err.message)
  }
}