import React, { useState } from 'react'

import './style.scss'
const ProfileImageComp = ({
    src = '',
    name,
    id,
    width, 
    height,
    fontSize,
}) => {


    const [profilePictureError, setProfilePictureError] = useState({id:id,flag:false})

    const handleImageError = () => {
        setProfilePictureError({id:id , flag:true})
    }

  return (
    <div
        className='profileImageComp'
        style= {{
            width: width,
            height: height,
            fontSize: fontSize,
            lineHeight: height
        }}
    >
        {
            profilePictureError?.flag && id == profilePictureError?.id ? 
            <h2
                className='profilePicturePlaceholder'
            >
                {name?.charAt(0)?.toUpperCase() || `U${id}`}
            </h2> : 
            <img
                style= {{
                    width: width,
                    height: height,
                    border: "none",
                    borderRadius: '50%'
                }}
                src={src} 
                onError = {handleImageError}
            />
        }
    </div>
  )
}

export default ProfileImageComp