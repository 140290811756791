import React from 'react'
import { useState } from 'react'
import profile from "../../../assets/icons/profile.png"
import ProfileImageComp from '../ProfileImageComp'
import "./style.scss"

function UserProfileCard({ user, showPackage = false }) {

    const [profilePictureError, setProfilePictureError] = useState(false)
    return (
        <div className='UserProfileCard'>
            <ProfileImageComp
                name={user?.first_name?.trim() + ' ' + user?.last_name}
                src={user?.picture || ""}
                id={user?.id}
                width="72px"
                height="72px"
                fontSize="28px"
            />
            <div>
                <p>{user?.first_name?.trim() + ' ' + user?.last_name}</p>
                {user?.id && <p id="userId">User ID:{user?.id}</p>}
                {user?.title && <p id="userId">{user?.title}</p>}
                {
                    showPackage && <p className='package'>{
                        user?.subscription_id == 1 ? 'Free' :
                            user?.subscription_id == 2 ? 'Gold' :
                                user?.subscription_id == 3 ? 'Platinum' : 'Invalid'
                    }
                    </p>
                }
            </div>
            {/* <div
                className='reloadButton'
            >
                <button>
                    <p>
                        Reload...
                    </p>
                </button>
            </div> */}
        </div>
    )
}

export default UserProfileCard