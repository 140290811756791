import React, { useEffect, useState } from 'react'
import { callYoriApi } from '../../../utils'
import GroupChartBox from '../../components/BarChartBox'
import BarChartBox from '../../components/BarChartBox'
import DashboardHeader from '../../components/DashboardHeader'
import SelectBox from '../../components/SelectBox'
import DashBoardSideBar from '../../components/sideBar'
import TotalStatsBox from '../../components/TotalStatsBox'


import './style.scss'

const Funnel = () => {
    const _ = require('lodash')

    const [tab, setTab] = useState(2)
    const [data, setData] = useState()
    const [barChartWeekLabels, setBarChartWeekLabels] = useState(['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']);
    const [barChartMonthLabels, setBarChartMonthLabels] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]);


    const date = (new Date()).toISOString().split('T')[0]



    const [numberStats, setNumberStats] = useState({
        totalVisits: "Loading...",
        newVistorVisits: "Loading...",
        oldVisitorVisits: "Loading...",
        anonymousVisitorVisits: "Loading...",
        
        numberOfNewUsers_CR: "Loading...",
        numberOfOldUsers_CR: "Loading...",
        newUsersContactReq: "Loading...",
        oldUsersContactReq: "Loading...",



        //Add numberStats of the company AI_Interaction and change the existing to the profile AI_Interac 
        numberOfNewUsers_AII: "Loading...",
        numberOfOldUsers_AII: "Loading...",
        newUsersAIInteraction: "Loading...",
        oldUsersAIInteraction: "Loading...",


        numberOfNewUsers_AII_CompanyView: "Loading...",
        numberOfOldUsers_AII_CompanyView: "Loading...",
        newUsersAIInteraction_CompanyView: "Loading...",
        oldUsersAIInteraction_CompanyView: "Loading...",
    })

    useEffect(() => {
        if (data) {
            setNumberStats({
                totalVisits: data?.visits_data?.total_visits || 0,
                newVistorVisits: data?.visits_data?.new_user_visits || 0,
                oldVisitorVisits: data?.visits_data?.old_user_visits || 0,
                anonymousVisitorVisits: data?.visits_data?.anonymous_visits || 0,

                numberOfNewUsers_CR: data?.contact_request_data?.number_of_new_users_cr,
                numberOfOldUsers_CR: data?.contact_request_data?.number_of_old_users_cr,
                newUsersContactReq: _.sum(Object.values(data?.contact_request_data?.new_user_contact_request_table_data)),
                oldUsersContactReq: _.sum(Object.values(data?.contact_request_data?.old_user_contact_request_table_data)),

                numberOfNewUsers_AII: data?.ai_interaction_data?.number_of_new_users_ai,
                numberOfOldUsers_AII: data?.ai_interaction_data?.number_of_old_users_ai,
                newUsersAIInteraction: _.sum(Object.values(data?.ai_interaction_data?.new_user_ai_interaction_table_data)),
                oldUsersAIInteraction: _.sum(Object.values(data?.ai_interaction_data?.old_user_ai_interaction_table_data)),

                // New case of default data 

                numberOfNewUsers_AII_CompanyView: data?.ai_interaction_data_company_view?.number_of_new_users_ai,
                numberOfOldUsers_AII_CompanyView: data?.ai_interaction_data_company_view?.number_of_old_users_ai,
                newUsersAIInteraction_CompanyView: _.sum(Object.values(data?.ai_interaction_data_company_view?.new_user_ai_interaction_table_data)),
                oldUsersAIInteraction_CompanyView: _.sum(Object.values(data?.ai_interaction_data_company_view?.old_user_ai_interaction_table_data)),
            })
            setNewUsersCRChartData({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: Object.values(data?.contact_request_data?.new_user_contact_request_table_data),
                total: _.sum(Object.values(data?.contact_request_data?.new_user_contact_request_table_data))
            })
            setOldUsersCRChartData({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: Object.values(data?.contact_request_data?.old_user_contact_request_table_data),
                total: _.sum(Object.values(data?.contact_request_data?.old_user_contact_request_table_data))
            })
            setNewUsersAIIChartData({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: Object.values(data?.ai_interaction_data?.new_user_ai_interaction_table_data),
                total: _.sum(Object.values(data?.ai_interaction_data?.new_user_ai_interaction_table_data))
            })
            setOldUsersAIIChartData({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: Object.values(data?.ai_interaction_data?.old_user_ai_interaction_table_data),
                total: _.sum(Object.values(data?.ai_interaction_data?.old_user_ai_interaction_table_data))
            })

        //These two need to be duplicated accrodingly

            setNewUsersAIIChartData_CompanyView({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: Object.values(data?.ai_interaction_data_company_view?.new_user_ai_interaction_table_data),
                total: _.sum(Object.values(data?.ai_interaction_data_company_view?.new_user_ai_interaction_table_data))
            })
            setOldUsersAIIChartData_CompanyView({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: Object.values(data?.ai_interaction_data_company_view?.old_user_ai_interaction_table_data),
                total: _.sum(Object.values(data?.ai_interaction_data_company_view?.old_user_ai_interaction_table_data))
            })
        }
    }, [data])


    const [newUsersCRChartData, setNewUsersCRChartData] = useState({
        labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        barChartData: [],
        total: "Loading..."
    })
    const [oldUsersCRChartData, setOldUsersCRChartData] = useState({
        labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        barChartData: [],
        total: "Loading..."
    })
    const [newUsersAIIChartData, setNewUsersAIIChartData] = useState({
        labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        barChartData: [],
        total: "Loading..."
    })
    const [oldUsersAIIChartData, setOldUsersAIIChartData] = useState({
        labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        barChartData: [],
        total: "Loading..."
    })

    const [newUsersAIIChartData_CompanyView, setNewUsersAIIChartData_CompanyView] = useState({
        labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        barChartData: [],
        total: "Loading..."
    })
    const [oldUsersAIIChartData_CompanyView, setOldUsersAIIChartData_CompanyView] = useState({
        labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        barChartData: [],
        total: "Loading..."
    })


    // onSelect needs to be handled with the new type That is ================= AIInteraction_Company
    const onSelect = async (type, filter) => {
        if (type === 'websiteVisit') {
            setNumberStats({
                ...numberStats,
                totalVisits: "Loading...",
                newVistorVisits: "Loading...",
                oldVisitorVisits: "Loading...",
                anonymousVisitorVisits: "Loading...",
            })
        }
        if (type === 'contactRequest') {
            setNumberStats({
                ...numberStats,
                numberOfNewUsers_CR: "Loading...",
                numberOfOldUsers_CR: "Loading...",
                newUsersContactReq: "Loading...",
                oldUsersContactReq: "Loading...",
            })
            setNewUsersCRChartData({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: [],
                total: "Loading..."
            })
            setOldUsersCRChartData({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: [],
                total: "Loading..."
            })
        }
        if (type === 'AIInteraction') {
            setNumberStats({
                ...numberStats,
                numberOfNewUsers_AII: "Loading...",
                numberOfOldUsers_AII: "Loading...",
                newUsersAIInteraction: "Loading...",
                oldUsersAIInteraction: "Loading...",
            })
            setNewUsersAIIChartData({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: [],
                total: "Loading..."
            })
            setOldUsersAIIChartData({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: [],
                total: "Loading..."
            })
        }

        if (type === 'AIInteraction_CompanyView') {
            setNumberStats({
                ...numberStats,
                numberOfNewUsers_AII_CompanyView: "Loading...",
                numberOfOldUsers_AII_CompanyView: "Loading...",
                newUsersAIInteraction_CompanyView: "Loading...",
                oldUsersAIInteraction_CompanyView: "Loading...",
            })
            setNewUsersAIIChartData_CompanyView({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: [],
                total: "Loading..."
            })
            setOldUsersAIIChartData_CompanyView({
                labels: ['Mon', 'Tu', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
                barChartData: [],
                total: "Loading..."
            })
        }

        const res = await callYoriApi("/admin/funnel", "POST", { body: JSON.stringify({ type, filter }) })

        if (type === "websiteVisit") {
            setNumberStats({
                ...numberStats,

                totalVisits: res?.visits_data?.total_visits || 0,
                newVistorVisits: res?.visits_data?.new_user_visits || 0,
                oldVisitorVisits: res?.visits_data?.old_user_visits || 0,
                anonymousVisitorVisits: res?.visits_data?.anonymous_visits || 0,
            })
        }

        if (type === "contactRequest") {
            setNumberStats({
                ...numberStats,

                numberOfNewUsers_CR: res?.contact_request_data?.number_of_new_users_cr,
                numberOfOldUsers_CR: res?.contact_request_data?.number_of_old_users_cr,
                newUsersContactReq: _.sum(Object.values(res?.contact_request_data?.new_user_contact_request_table_data)),
                oldUsersContactReq: _.sum(Object.values(res?.contact_request_data?.old_user_contact_request_table_data)),
            })
            setNewUsersCRChartData({
                labels: filter === 'week' ? barChartWeekLabels : (filter == 'month' ? barChartMonthLabels : ["Today", "", "", "", "", ""]),
                barChartData: Object.values(res?.contact_request_data?.new_user_contact_request_table_data),
                total: _.sum(Object.values(res?.contact_request_data?.new_user_contact_request_table_data))
            })
            setOldUsersCRChartData({
                labels: filter === 'week' ? barChartWeekLabels : (filter == 'month' ? barChartMonthLabels : ["Today", "", "", "", "", ""]),
                barChartData: Object.values(res?.contact_request_data?.old_user_contact_request_table_data),
                total: _.sum(Object.values(res?.contact_request_data?.old_user_contact_request_table_data))
            })
        }

        if (type === "AIInteraction") {
            setNumberStats({
                ...numberStats,

                numberOfNewUsers_AII: res?.ai_interaction_data?.number_of_new_users_ai,
                numberOfOldUsers_AII: res?.ai_interaction_data?.number_of_old_users_ai,
                newUsersAIInteraction: _.sum(Object.values(res?.ai_interaction_data?.new_user_ai_interaction_table_data)),
                oldUsersAIInteraction: _.sum(Object.values(res?.ai_interaction_data?.old_user_ai_interaction_table_data)),
            })
            setNewUsersAIIChartData({
                labels: filter === 'week' ? barChartWeekLabels : (filter == 'month' ? barChartMonthLabels : ["Today", "", "", "", "", ""]),
                barChartData: Object.values(res?.ai_interaction_data?.new_user_ai_interaction_table_data),
                total: _.sum(Object.values(res?.ai_interaction_data?.new_user_ai_interaction_table_data))
            })
            setOldUsersAIIChartData({
                labels: filter === 'week' ? barChartWeekLabels : (filter == 'month' ? barChartMonthLabels : ["Today", "", "", "", "", ""]),
                barChartData: Object.values(res?.ai_interaction_data?.old_user_ai_interaction_table_data),
                total: _.sum(Object.values(res?.ai_interaction_data?.old_user_ai_interaction_table_data))
            })
        }

        if (type === "AIInteraction_CompanyView") {
            setNumberStats({
                ...numberStats,

                numberOfNewUsers_AII_CompanyView: res?.ai_interaction_data_company_view?.number_of_new_users_ai,
                numberOfOldUsers_AII_CompanyView: res?.ai_interaction_data_company_view?.number_of_old_users_ai,
                newUsersAIInteraction_CompanyView: _.sum(Object.values(res?.ai_interaction_data_company_view?.new_user_ai_interaction_table_data)),
                oldUsersAIInteraction_CompanyView: _.sum(Object.values(res?.ai_interaction_data_company_view?.old_user_ai_interaction_table_data)),
            })
            setNewUsersAIIChartData_CompanyView({
                labels: filter === 'week' ? barChartWeekLabels : (filter == 'month' ? barChartMonthLabels : ["Today", "", "", "", "", ""]),
                barChartData: Object.values(res?.ai_interaction_data_company_view?.new_user_ai_interaction_table_data),
                total: _.sum(Object.values(res?.ai_interaction_data_company_view?.new_user_ai_interaction_table_data))
            })
            setOldUsersAIIChartData_CompanyView({
                labels: filter === 'week' ? barChartWeekLabels : (filter == 'month' ? barChartMonthLabels : ["Today", "", "", "", "", ""]),
                barChartData: Object.values(res?.ai_interaction_data_company_view?.old_user_ai_interaction_table_data),
                total: _.sum(Object.values(res?.ai_interaction_data_company_view?.old_user_ai_interaction_table_data))
            })
        }

        // console.log({ type, filter, res })
    }

    useEffect(async () => {
        setData(await callYoriApi("/admin/funnel", "POST", { body: JSON.stringify({ default: true }) }))
    }, [])


    return (

        <div
            className='dashboard'
        >
            <DashBoardSideBar tab={tab} setTab={setTab} />
            <div
                className='main'
            >
                <DashboardHeader tab={tab} />
                <div className='dasbboardBody'>

                    {/* ================================================================================================================================================================================ */}

                    <div className='statsSectionParent'>
                        <SelectBox options={["Today", "This Week", "This Month"]} id={1} type='websiteVisit' onSelect={onSelect} />
                        <div
                            className='statsSectionRatioRow'
                        >
                            <span>
                                <TotalStatsBox
                                    flexCheck={true} title="Visits" percentage="+11.01" rise={true}
                                    value={numberStats?.totalVisits}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="New Visitor Visits" percentage="+9.15" rise={true}
                                    value={numberStats?.newVistorVisits}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="Old User Visits" percentage="-0.56" rise={true}
                                    value={numberStats?.oldVisitorVisits}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="Anonymous Visits" percentage="-0.56" rise={true}
                                    value={numberStats?.anonymousVisitorVisits}
                                />
                            </span>
                        </div>
                    </div>
                    {/* ================================================================================================================================================================================ */}

                    <div className='statsSectionParent'>
                        <SelectBox options={["Today", "This Week", "This Month"]} id={1} type='contactRequest' onSelect={onSelect} />
                        <div
                            className='statsSectionRatioRow'
                        >
                            <span>
                                <TotalStatsBox
                                    flexCheck={true} title="New User CR" percentage="-1.48" rise={true}
                                    value={numberStats?.newUsersContactReq}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="# New Users" percentage="-1.48" rise={true}
                                    value={numberStats?.numberOfNewUsers_CR}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="CR to # Ratio" resultantStyle={true} percentage="-1.48" rise={true}
                                    value={
                                        typeof (numberStats?.newUsersContactReq) == 'number'
                                            ? (numberStats?.newUsersContactReq / numberStats?.numberOfNewUsers_CR)?.toFixed(2)
                                            : 'Loading...'
                                    }
                                />
                            </span>
                            <span>
                                <TotalStatsBox
                                    flexCheck={true} title="Old User CR" percentage="-1.48" rise={true}
                                    value={numberStats?.oldUsersContactReq}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="# Old Users" percentage="-1.48" rise={true}
                                    value={numberStats?.numberOfOldUsers_CR}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="CR to # Ratio" percentage="-1.48" rise={true}
                                    resultantStyle={true}
                                    value={
                                        typeof (numberStats?.oldUsersContactReq) == 'number'
                                            ? (numberStats?.oldUsersContactReq / numberStats?.numberOfOldUsers_CR)?.toFixed(2)
                                            : 'Loading...'
                                    }
                                />
                            </span>
                        </div>
                        <span
                            className='statsSectionCharts'
                        >
                            <GroupChartBox
                                data={newUsersCRChartData}
                                mainTitle={"New Users Contact Request"}
                                color='rgb(75, 68, 225)'
                            />

                            <GroupChartBox
                                data={oldUsersCRChartData}
                                mainTitle={"Old Users Contact Request"}
                                color='rgb(75, 68, 225)'
                            />

                        </span>
                    </div>
                    {/* ================================================================================================================================================================================ */}
                    <div className='statsSectionParent'>
                        <SelectBox options={["Today", "This Week", "This Month"]} id={1} type='AIInteraction' onSelect={onSelect} />
                        <h3>AI Interactions on Profile Views</h3>
                        <div
                            className='statsSectionRatioRow'
                        >
                            <span>
                                <TotalStatsBox
                                    flexCheck={true} title="New User AI-I on Profile View" percentage="-1.48" rise={true}
                                    value={numberStats?.newUsersAIInteraction}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="# New Users" percentage="-1.48" rise={true}
                                    value={numberStats?.numberOfNewUsers_AII}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="AI-I to # Ratio" percentage="-1.48" rise={true}
                                    resultantStyle={true}
                                    value={
                                        typeof (numberStats?.newUsersAIInteraction) == 'number'
                                            ? (numberStats?.newUsersAIInteraction / numberStats?.numberOfNewUsers_AII)?.toFixed(2)
                                            : 'Loading...'
                                    }
                                />
                            </span>
                            <span>
                                <TotalStatsBox
                                    flexCheck={true} title="Old User AI-I on Profile View" percentage="-1.48" rise={true}
                                    value={numberStats?.oldUsersAIInteraction}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="# Old Users" percentage="-1.48" rise={true}
                                    value={numberStats?.numberOfOldUsers_AII}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="AI-I to # Ratio" percentage="-1.48" rise={true}
                                    resultantStyle={true}
                                    value={
                                        typeof (numberStats?.oldUsersAIInteraction) == 'number'
                                            ? (numberStats?.oldUsersAIInteraction / numberStats?.numberOfOldUsers_AII)?.toFixed(2)
                                            : 'Loading...'
                                    }
                                />
                            </span>
                        </div>
                        <span
                            className='statsSectionCharts'
                        >
                            <GroupChartBox
                                data={newUsersAIIChartData}
                                mainTitle={"New Users AI Interaction on Person View"}
                                color='rgb(75, 68, 225)'
                            />
                            <GroupChartBox
                                data={oldUsersAIIChartData}
                                mainTitle={"Old Users AI Interaction on Person View"}
                                color='rgb(75, 68, 225)'
                            />
                        </span>
                    </div>

                    {/* ================================================================================================================================================================================ */}
                    
                    <div className='statsSectionParent'>
                        <SelectBox options={["Today", "This Week", "This Month"]} id={1} type='AIInteraction_CompanyView' onSelect={onSelect} />
                        <h3>AI Interactions on Company Views</h3>
                        <div
                            className='statsSectionRatioRow'
                        >

                            {
                                // numberOfNewUsers_AII_CompanyView: "Loading...",
                                // numberOfOldUsers_AII_CompanyView: "Loading...",
                                // newUsersAIInteraction_CompanyView: "Loading...",
                                // oldUsersAIInteraction_CompanyView: "Loading...",
                                
                            }
                            <span>
                                <TotalStatsBox
                                    flexCheck={true} title="New User AI-I on Company View" percentage="-1.48" rise={true}
                                    value={numberStats?.newUsersAIInteraction_CompanyView}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="# New Users" percentage="-1.48" rise={true}
                                    value={numberStats?.numberOfNewUsers_AII_CompanyView}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="AI-I to # Ratio" percentage="-1.48" rise={true}
                                    resultantStyle={true}
                                    value={
                                        typeof (numberStats?.newUsersAIInteraction_CompanyView) == 'number'
                                            ? (numberStats?.newUsersAIInteraction_CompanyView / numberStats?.numberOfNewUsers_AII_CompanyView)?.toFixed(2)
                                            : 'Loading...'
                                    }
                                />
                            </span>
                            <span>
                                <TotalStatsBox
                                    flexCheck={true} title="Old User AI-I on Company View" percentage="-1.48" rise={true}
                                    value={numberStats?.oldUsersAIInteraction_CompanyView}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="# Old Users" percentage="-1.48" rise={true}
                                    value={numberStats?.numberOfOldUsers_AII_CompanyView}
                                />
                                <TotalStatsBox
                                    flexCheck={true} title="AI-I to # Ratio" percentage="-1.48" rise={true}
                                    resultantStyle={true}
                                    value={
                                        typeof (numberStats?.oldUsersAIInteraction_CompanyView) == 'number'
                                            ? (numberStats?.oldUsersAIInteraction_CompanyView / numberStats?.numberOfOldUsers_AII_CompanyView)?.toFixed(2)
                                            : 'Loading...'
                                    }
                                />
                            </span>
                        </div>
                        <span
                            className='statsSectionCharts'
                        >
                            <GroupChartBox
                                data={newUsersAIIChartData_CompanyView}
                                mainTitle={"New Users AI Interaction on Company View"}
                                color='rgb(75, 68, 225)'
                            />
                            <GroupChartBox
                                data={oldUsersAIIChartData_CompanyView}
                                mainTitle={"Old Users AI Interaction on Company View"}
                                color='rgb(75, 68, 225)'
                            />
                        </span>
                    </div>

                    {/* ================================================================================================================================================================================ */}

                </div>
            </div>


        </div>
    )
}

export default Funnel

