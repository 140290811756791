import React from 'react'
import './style.scss'

import bellIcon from "../../../assets/icons/bellIcon.svg"
import refreshIcon from "../../../assets/icons/refreshIcon.svg"
import SearchInput from '../../../components/SearchInput'


function DashboardHeader({ tab, search, setSearch }) {
    return (
        <div className='dashboardHeader'>
            {
                tab === 0 && <div className='nav'>  Dashboards / <span>Default</span> </div>
            }
            {
                tab === 1 && <div className='nav'> Users </div>
            }
            {
                tab === 2 && <div className='nav'> Funnel </div>
            }
            <div className='headerRight'>

                {/* <span>

                    <SearchInput placeholder="Search" control={{ search, setSearch }} style={{
                        borderRadius: "8px",
                        padding: "1px 5px",
                        // width: "160px",
                    }} />
                </span>
                <img src={refreshIcon} />
                <img src={bellIcon} /> */}
            </div>
        </div>)
}

export default DashboardHeader