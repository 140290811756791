import React from 'react'


import greenCheckMark from '../../../../../assets/icons/greenCheckMark.svg'
import gift from '../../../../../assets/icons/gift.svg'
import "./style.scss"
const CheckPointLabel = ({
  amountOfCheckPoint,
  invitesOfCheckpoint,
  nextCheckPoint = false
}) => {
  return (
    <div
      className={nextCheckPoint ? 'checkPointLabel nextCheckPointLabel' : 'checkPointLabel'}
    >
      {
        !nextCheckPoint && <img class='greenCheckMark' src={greenCheckMark} />
      }
      <span
        className='checkPointReward'
      >
        <img src={gift} />
        <h2>
          {amountOfCheckPoint} credits
        </h2>
      </span>
      <span
        className='checkPointInvitationCount'
      >
        <p>
          {invitesOfCheckpoint} invites
        </p>
      </span>
    </div>
  )
}

export default CheckPointLabel