import React, { useEffect, useState } from 'react'
import './style.scss'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import FormikInput from '../FormikInput'
import { isEmpty, notify } from '../../utils'
import PrimaryButton from '../PrimaryButton'
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import GoogleLogo from '../../assets/icons/googleLogo.svg'

const AuthenticationForm = ({
    submitLabel = 'Sign Up ',
    footerLabel = 'Alrerady have an account?',
    footerHref = 'login',
    footerHrefLabel = 'Login Instead',
    authOptionsLabel = 'Or you can join with',
    onSuccessLogin,
    onFailure,
    authOptions = [],
    hasNameField = true,
    handleSubmit = async e => false
}) => {

    const [showPassword, setShowPassword] = useState(false)
    const googleAuth = (event) => {

        const elements = document.getElementsByClassName('circle');
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
        const circle = document.createElement('div')
        const x = event?.nativeEvent?.layerX
        const y = event?.nativeEvent?.layerY
        console.log({ event, x, y })
        circle.classList.add('circle')
        circle.style.left = `${x}px`
        circle.style.top = `${y}px`
        document.getElementsByClassName("clickEffect")[0].appendChild(circle)
        window.open(
            `${process.env.REACT_APP_API_URL}/auth/google/callback`,
            "_self"
        );
    };
    return (
        <div
            className='authenticationFormContainer'
        >
            <Formik
                initialValues={{ email: "", name: '', password: '' }}
                validate={values => {

                }}
                onSubmit={async (values, actions) => {
                    await handleSubmit(values, actions)
                }}
            >
                {({
                    errors,
                    isSubmitting
                }) =>
                    <Form>
                        {/* <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                            buttonText="Sign in with Google"
                            onSuccess={onSuccessLogin}
                            onFailure={onFailure}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true}
                            render={renderProps => ( */}
                        <PrimaryButton
                            onClick={googleAuth}
                            extra="clickEffect"
                            style={{
                                borderRadius: '100px',
                                width: '100%',
                                display: 'flex',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <img
                                src={GoogleLogo}
                            />
                            <p
                                style={{ margin: '4px 0px' }}
                            >
                                {
                                    isSubmitting ? "..." : submitLabel
                                }
                                with Google
                            </p>
                        </PrimaryButton>
                        {/* )} */}
                        {/* /> */}
                    </Form>
                }
            </Formik>
            <div>
                {authOptions?.length > 0 &&
                    <h3>
                        {authOptionsLabel}
                    </h3>}
                <div>
                    {authOptions.map(itm => {
                        const { onClick = e => false, name, src } = itm
                        return <button
                            key={name}
                            onClick={onClick}
                        >
                            <img
                                width='40'
                                height='40'
                                src={src}
                            />
                        </button>
                    }
                    )}
                </div>
            </div>
            <div>
                <h3>
                    {footerLabel}
                </h3>
                <a
                    href={footerHref}
                >
                    {footerHrefLabel}
                </a>
            </div>
        </div>)
}

export default AuthenticationForm