import React from 'react'
import "./style.scss"
import gift from "../../assets/icons/gift.svg"
import { calculateProgressToNextMilestone } from '../../utils'

function InvitationsCount({
  count,
  numberOfProspects,
  title,
  message,
  hasBottomBorder,
  packageMilestones,
}) {
  
  message = calculateProgressToNextMilestone(numberOfProspects , packageMilestones)
  return (
    <div className='invitations_count'>
        <React.Fragment>
          {
            hasBottomBorder ? 
              <p>{numberOfProspects}</p> 
              :
              <p>{`${hasBottomBorder ? '' : '$'}`}{count}</p> 
              
          }
        </React.Fragment>
        <p>{title}</p>
        {
          hasBottomBorder && <div className='hrline'></div>
        }
        <div className={`${!hasBottomBorder && "addbackground"}`}>
            <p>{message}</p>
            <img src={gift}/>
        </div>

    </div>
  )
}

export default InvitationsCount