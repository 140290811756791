/* global chrome */
export const PING = 'PING'
export const pingExtension = (callBack = r => false) => {
    const extId = process.env.REACT_APP_EXT_ID
    // console.log("Chrome :", chrome)
    // console.log("==============REACT_APP_EXT_ID==================", { extId, runTime: chrome.runtime })
    if (extId && chrome.runtime) {
        // console.log("==============PING==================", { extId })
        chrome.runtime.sendMessage(extId, {
            type: PING
        }, callBack)
        return true
    }
    return false
}

export const SEND_MESSAGE_TO_1ST_DEGREE_CONNECTION = 'SEND_MESSAGE_TO_1ST_DEGREE_CONNECTION'
export const sendMessageToFirstDegreeConnection = (payload, callBack = r => false) => {
    const extId = process.env.REACT_APP_EXT_ID
    if (extId && chrome.runtime) {
        chrome.runtime.sendMessage(extId, {
            type: SEND_MESSAGE_TO_1ST_DEGREE_CONNECTION,
            payload
        }, callBack)
        return true
    }
    return false
}

export const START_MESSAGE_SENDING = 'START_MESSAGE_SENDING'
export const pingExtToStartSendingMessages = (payload, callBack = r => false) => {
    const extId = process.env.REACT_APP_EXT_ID
    if (extId && chrome.runtime) {
        chrome.runtime.sendMessage(extId, {
            type: START_MESSAGE_SENDING,
        }, callBack)
        return true
    }
    return false
}