import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../../../components/PrimaryButton';
import "./style.scss"

const ConfirmationModal = ({ open, onClose, setConfirmPayment, amount, card }) => {
    if (!open) return null;

    return (
        <div onClick={onClose} className='overlay '>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className='modalContainer'
            >
                <div className='modalRight'>
                    <div className='modal_header'>
                        <h3>
                            Pay with Card
                        </h3>
                        {/* <img src={closebtn} onClick={onClose} /> */}
                    </div>
                    <div className='modalbody'>

                        <p>You will be charged ${amount} from your card ending with {card}. </p>

                        <div className='btndiv'>
                            <button onClick={onClose} style={{
                                background: "#ECEBFF",
                                color: "#4B44E1",
                                marginRight: "10px"
                            }}>Cancel</button>
                            <PrimaryButton variant='Beta' style={{ fontSize: "14px", padding: "0.3rem 0.8rem" }}
                                onClick={() => { setConfirmPayment(true); onClose() }}
                            >
                                Make Payment
                            </PrimaryButton>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;