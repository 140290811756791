import React from 'react'

import './style.scss'

const PillComponent = ({
    style = {},
    text = '',
    variant = 'Alpha',
    italics = true
}) => {
  return (
    <div
        className={`pillComponent pillComponent${variant} ${italics ? 'pillComponentItalics' : ''} `}
        style={style}
    >
        <p>
            {text}
        </p>
    </div>
  )
}

export default PillComponent