import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { calculateStringWidth, calculateTextForGivenWidth } from '../../utils'
import { v4 as UUID } from 'uuid'

import "./style.scss"

const EllipsisTextComponent = ({
    string , 
    stringLengthCap = 200 ,

}) => {

    let stringCalculated = ``
    const [pId, setpId] = useState(UUID)
    let idPlaceHolder = ''
    


    if(string){
        stringCalculated = calculateTextForGivenWidth(string, stringLengthCap)
    }else{}

    // console.log(`======stringCalculated=======` , stringCalculated)
    // console.log(`======pId=======` , pId)

  return (
    <React.Fragment>
        <p
            className='ellipsisTextComponent'
            data-tip data-for={pId}
        >
            {
                string ? stringCalculated : string
            }
        </p>
        <ReactTooltip
            className='toolTipCss'
            id={pId}
            place="bottom" 
            effect="solid"
            zIndex= "9"
        >
            {string}
        </ReactTooltip>
    </React.Fragment>
  )
}

export default EllipsisTextComponent