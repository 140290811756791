import * as ActionTypes from "../../actions/auth";

const initialState = {
    dollars: 0,
    credits: 0, 
    subscriptionId: 1,
    subscriptionUpdatedAt: 0,
    transactions: [],
    isLoading: false,
    isLoaded: false,
};

export const wallet = (state = initialState, action) => {
  switch (action.type) {
    
    case ActionTypes.INIT_WALLET:
    return {
        ...state,
        isLoading: true,
        isLoaded: false,
    };  

    case ActionTypes.INIT_WALLET_SUCCESS:
    return {
        ...state,
        ...action.payload,
        isLoading: false,
        isLoaded: true,
    };  

    case ActionTypes.INIT_WALLET_FAILURE:
    return {
        ...state,
        isLoading: false,
        isLoaded: true,
    };  
    
    case ActionTypes.SET_DOLLARS:
      return {
        ...state,
        dollars: action.payload,
      };  
    
    case ActionTypes.SET_CREDITS:
    return {
        ...state,
        credits : action.payload,
    };  
    
    case ActionTypes.SET_SUBSCRIPTION_ID:
    return {
        ...state,
        subscriptionId: action.payload,
    };  
    
    case ActionTypes.SET_TRANSACTIONS:
    return {
        ...state,
        transactions: [...state , ...action.payload],
    };  

    default:
      return state;
  }
};


