import React, { useEffect, useRef, useState } from 'react'
import "./style.scss"
import halfCircle from "../../assets/icons/halfcircle.svg"
import reward from "../../assets/icons/reward1.svg"

import celebrations from '../../assets/icons/celebrations.svg'

import { useSelector } from 'react-redux'

function RewardBox({ data }) {

  const [milestoneAchievedBool, setMilestoneAchievedBool] = useState(false)

  const ref = React.useRef(null);
  const imgRef = useRef(0)

  const { img, count, text, color , inviteCount , rewardRibbon } = data

  let {
      isLoaded,
      invitations_sent,
  } = useSelector(state => state.referrals?.invitations)

  
  useEffect(() => {
    if(invitations_sent?.length >= inviteCount){
      setMilestoneAchievedBool(true)
    }
  }, [isLoaded])


    
  return (
    <span ref={ref} className='reward_box' style={milestoneAchievedBool ? { backgroundColor: color } : { backgroundColor: color , opacity: '0.5'}}>
      {
        milestoneAchievedBool && <>

          <span
              className='ribbon' 
          >
            <img 
              src={rewardRibbon}/>
          </span>
          <span
              className='celebrations' 
          >
            <img 
              style={{
                  height: `100%`,
                  width: `100%`,
                }}
              src={celebrations}/>
          </span>
        </>
      }
      <div className='box_title'><img src={halfCircle} /> <span>{count}</span></div>
      <img 
        src={img} 
      />
      <p>Invite <span> {inviteCount} </span>contacts via LinkedIn</p>
    </span>
  )
}

export default RewardBox