import React, { useEffect, useState } from 'react'
import PillComponent from '../PillComponent'

import crunchbaseLogo from '../../assets/icons/crunchbaseLogo.svg'
import yahooLogo from '../../assets/icons/yahooLogo.svg'
import yoriLogoAndTextWhite from '../../assets/icons/yoriLogoAndTextWhite.svg'
import RightArrow from '../RightArrow'

import './style.scss'
import { isMobile } from 'react-device-detect'

const YoriPricingInfo = ({
    onGetExtension
}) => {

    const [hoverHandler, setHoverHandler] = useState(false)
    return (
        <div
            className='yoriPricingInfo'
        >
            <h1>
                Yori is free
            </h1>
            <div>
                <span
                    className='pricingComparison'
                >
                    <div
                        style={{ background: '#C4D7FF' }}
                    >
                        <img src={crunchbaseLogo} />
                        <p><b>$588  </b>/yr</p>
                    </div>
                    <div
                        style={{ background: '#EBDAFF' }}
                    >
                        <img src={yahooLogo} />
                        <p><b>$350  </b>/yr</p>
                    </div>
                </span>
                <span
                    className='pricingCTA'
                >
                    <img src={yoriLogoAndTextWhite} />
                    <h3>We don’t need your money. We need your <b>♥️</b></h3>
                    <p>Earn free credits by inviting your friends to Yori. Win-win.</p>
                    {
                        !isMobile ?
                            <>
                                <button
                                    onClick={onGetExtension}
                                    onMouseEnter={() => setHoverHandler(true)}
                                    onMouseLeave={() => setHoverHandler(false)}
                                >
                                    Get Extension for Free
                                    <RightArrow variant={hoverHandler ? 'blue' : 'white'} />
                                </button>
                            </> : <></>
                    }
                </span>
            </div>
        </div>
    )
}

export default YoriPricingInfo