import { upperCase } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initRewardsHistory } from '../../../../actions/referrals'
import profilePlaceholder from '../../../../assets/icons/profile.png'
import ProfileImageComponent from '../../../../components/ProfileImageComponent'

import "./style.scss"

function TransactionHistory() {

    const dispatch = useDispatch()
    const rewards_history = useSelector(state => state?.referrals?.rewards?.rewards_history)
    useEffect(() => {
        // console.log("Called ==================12312")
        dispatch(initRewardsHistory())
    }, [])

    const [userName, setUserName] = useState(localStorage.getItem('name'))



    return (
        <div className='reward_transactions'>
            {
                rewards_history?.length > 0 && <>

                    <h3>
                        Rewards History
                    </h3>
                    <div className='history_list'>
                        {
                            rewards_history?.length > 0 && rewards_history?.map(row => {
                                if (row?.source === "REFERRAL_SENT") {
                                    return <div>
                                        <div >
                                            <p>{row?.source_id} contacts invited</p>
                                            <p className='amount'>{row?.amount} credits</p>
                                        </div>
                                    </div>
                                }
                                else if (row?.source === "REFERRAL_ACCEPTED") {
                                    return <div>
                                        <div>
                                            <p>1 invitee signed up</p>
                                            <p className='amount'>{row?.amount} credits</p>
                                        </div>
                                        <div className='picture_box'>
                                            <ProfileImageComponent
                                                imageLink={`https://qlu-media-test.s3.amazonaws.com/${upperCase(row?.contact?.first_name.split(``)[0])}.jpg`}
                                                placeHolder={upperCase(row?.contact?.first_name?.trim().split(``)[0])}
                                                compact={true}
                                            />
                                            <p>
                                                {row?.contact?.first_name} {row?.contact?.last_name}
                                            </p>
                                        </div>
                                    </div>
                                }
                                else if (row?.source === "REFERRAL_USED") {
                                    return <div>
                                        <div>
                                            <p>You signed up</p>
                                            <p className='amount'>{row?.amount} credits</p>
                                        </div>
                                        <div className='picture_box'>
                                            {
                                                userName?.split(' ')[0]?.split('')[0] && <span
                                                    className='placeHolderTransactionHistory'
                                                >
                                                    <p>
                                                        {upperCase(userName?.split(' ')[0]?.split('')[0])}
                                                    </p>
                                                </span>
                                            }
                                            <p>
                                                {userName ? userName : ''}
                                            </p>
                                        </div>
                                    </div>
                                }
                            })
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default TransactionHistory