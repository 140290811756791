import { createStore, combineReducers, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import ReduxThunk from 'redux-thunk'
import rootReducer from './reducers/index'


const logger = createLogger({ collapsed: true, predicate: () => process.env.REACT_APP_ENV !== 'production' })

export default createStore(
  rootReducer,
  applyMiddleware(
    ReduxThunk,
    logger
  )
)
