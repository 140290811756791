import React, { useEffect, useState } from 'react'
import "./style.scss"
import leftarrow from "../../assets/icons/leftarrow.svg"
import rightarrow from "../../assets/icons/rightarrow.svg"
import ListTableHeader from '../ListTableHeader'
import arrow from "../../assets/icons/downarrow.svg"
import { useSelector } from 'react-redux'

const ListTable = ({
    onGetExtension=e=>false,
    titles,TableRow,data,active,functions,search,countSelected
}) => {

const [tableData,setTableData] = useState([])
const [Data,setData] = useState([])
const state=useSelector(state=>state)
    
useEffect(() => {
    if(data === "folders"){
        setData(state?.lists?.folders?.folders)
        setTableData(state?.lists?.folders?.folders)
    }
    if(data=== "userlist"){
        setData(state?.lists?.userslists?.userslist)
        setTableData(state?.lists?.userslists?.userslist)
    }
    if(data=== "companylists"){
        setData(state?.lists?.companylists?.companylists)
        setTableData(state?.lists?.companylists?.companylists)
    }

}, [state])

useEffect(() => {  
  setTableData(Data.filter(data=>data.name.toLowerCase().includes(search.toLowerCase())))
}, [search])


const [pageData,setPageData] = useState([])
let [totalPages,setTotalPages]= useState(0)
let [currentPage,setCurrentPage] = useState(1)

const nextPage = () =>{
    if(currentPage<totalPages){
      setPageData(tableData.slice(currentPage*10,(currentPage+1)*10))
      
      setCurrentPage(currentPage+1)

    }
}
const previousPage = () =>{
    if(currentPage!=1){
      setPageData(tableData.slice((currentPage-2)*10,(currentPage-1)*10))

      setCurrentPage(currentPage-1)
    }  
}

useEffect(() => {
  setTotalPages(Math.ceil(tableData?.length/10))
  setPageData(tableData.slice(0,10))
}, [tableData])


const sortData = (title,flag) =>{
  // title=title.toLowerCase()

  let list=[]
  if(title!="created_at" ) {
    setPageData([...(pageData.sort((a,b)=>{
    //  console.log("te ::",a[title],b[title])
     if(typeof a[title] == "string"){
          if(flag && a[title].toLowerCase() < b[title].toLowerCase()) return 1   // > 0 means sort a after b
          if(!flag && a[title].toLowerCase() > b[title].toLowerCase()) return 1  // < 0 means sort a before b
          return -1
      }
      else{
          if(flag && a[title] <= b[title]) return 1   // > 0 means sort a after b
          if(!flag && a[title] >= b[title]) return 1  // < 0 means sort a before b
          return -1
     }
    }))])
  }
  if(title=="created_at") {
    setPageData([...pageData.sort((a,b)=>{
      // console.log(a)
      if(flag)
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      else
          return  new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    })])
  }
}

// setTimeout(()=> console.log("folders :",tableData),100)

  return (  
    <div className='table'>
      {
        totalPages != 0 ? <>
        
      <ListTableHeader titles={titles} sortData={sortData}/>

        {
          pageData?.length > 0 && pageData.map((row,ind)=> <TableRow key={row.id} row={row} active={active} functions={functions} countSelected={countSelected}/> )
        }

        <div className='table_footer'>
            <div>
              {(currentPage-1)*10 } - { (currentPage * 10)<tableData?.length ? (currentPage * 10) : tableData?.length } of {tableData?.length}
            </div>
          
            
            <div className='table_footer_actions'>
                <img src={leftarrow} onClick={previousPage}/>
                <span>Page {totalPages!=0 ? currentPage : currentPage-1} of {totalPages}</span>
                <img src={rightarrow} onClick={nextPage}/>
            </div >
        </div>
        </> 
        : <div> <h4>No Lists Found </h4> </div>

      }
    </div>
  )
}

export default ListTable