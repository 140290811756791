import React from 'react'
import AuthBackgroundCover from '../../../components/AuthBackgroundCover'
import yoriLogo from '../../../img/YoriLogo.svg'
import yoriText from '../../../img/Yori.svg'
import yoriLogoAndText from '../../../img/yoriLogoAndText.svg'
import onBoardingConfetti from '../../../assets/icons/onBoardingConfetti.svg'
import "./style.scss"
import PrimaryButton from '../../../components/PrimaryButton'
import { useLocation, useNavigate } from 'react-router'
import { invitationAcceptedMilestones } from '../../../utils'

const RewardWelcome = () => {
  const navigate = useNavigate()
  const params = useLocation()?.state
  return (
    <div
      className='rewardWelcome'
    >
      <img
        style={{
          position: 'absolute',
          width: '50%',
          height: '100%',
          zIndex: '-1',
          alignSelf: 'flexStart'
        }}
        src={onBoardingConfetti}
      />
      <span
        className='rewardWelcomeLeft'
      >
        <img
          style={{
            alignSelf: 'flex-start',
            justifySelf: 'flex-start',
            marginLeft: '58px',
            marginTop: '32px',
          }}
          src={yoriLogoAndText}
        />
        <div
          className='rewardWelcomeLeft_Inner'
        >
          <h1>You just got {invitationAcceptedMilestones?.[1]} credits reward!</h1>
          <p>Thank you for accepting {params?.referral_user?.first_name}'s invite to Yori! As promised, we both get <span style={{ fontWeight: '700' }}>{invitationAcceptedMilestones?.[1]} credits</span> each! You can earn more awards by inviting your friends to Yori, just like how {params?.referral_user?.first_name}'s did!</p>
          <PrimaryButton
            onClick={() => navigate("/onboarding/intro_to_yori")}
            style={{
              width: '100%',
              marginTop: '122px',
              padding: '15px'
            }}
          >
            <span
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  margin: '0px'
                }}
              >Next</p>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <p
                  style={{
                    margin: '0px'
                  }}
                >{'>'}</p>
                <p
                  style={{
                    margin: '0px'
                  }}
                >{'>'}</p>
                <p
                  style={{
                    margin: '0px'
                  }}
                >{'>'}</p>
              </div>
            </span>
          </PrimaryButton>


        </div>
      </span>
      <span>
        <AuthBackgroundCover />
      </span>
    </div>
  )
}

export default RewardWelcome