import { callYoriApi } from "../utils"

export const SET_USER = "SET_USER"
export const setUser = (payload) => ({
    type: SET_USER,
    payload
})

export const SET_USER_ID = "SET_USER_ID"
export const setUserId = (payload) => ({
    type: SET_USER_ID,
    payload
})

export const SET_DROPMENU = "SET_DROPMENU"
export const setDropMenu = (payload) => ({
    type: SET_DROPMENU,
    payload
})

export const INIT_WALLET = "INIT_WALLET"
export const INIT_WALLET_SUCCESS = "INIT_WALLET_SUCCESS"
export const INIT_WALLET_FAILURE = "INIT_WALLET_FAILURE"
export const initWallet = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: INIT_WALLET
        })
        // fetch
        const transactionsResponse = await callYoriApi(`/wallet/transactions`, "GET")
        const walletCreditsResponse = await callYoriApi(`/wallet/credits`, "GET")

        // console.log(`error in transactionsResponse : `, transactionsResponse)
        // console.log(`error in walletCreditsResponse : `, walletCreditsResponse)

        const initWalletPayload = {
            transactions: transactionsResponse?.transactions,
            dollars: walletCreditsResponse?.doller_credits,
            credits: walletCreditsResponse?.credits,
            subscriptionId: walletCreditsResponse?.subscription_id,
            subscriptionUpdatedAt: parseInt(walletCreditsResponse?.data?.subscription_updated_at)
        }

        if ('error' in transactionsResponse || 'error' in walletCreditsResponse) {
            console.log(`error in transactionsResponse : `, transactionsResponse?.error)
            console.log(`error in walletCreditsResponse : `, walletCreditsResponse?.error)
            throw new Error("Something went wrong")
        }
        else {
            // console.log(`======INIT_WALLET_SUCCESS======initWalletPayload :`, initWalletPayload)
            dispatch({
                type: INIT_WALLET_SUCCESS,
                payload: initWalletPayload
            })
        }
    }
    catch (err) {
        dispatch({
            type: INIT_WALLET_FAILURE
        })
    }
}


export const SET_DOLLARS = "SET_DOLLARS"
export const setDollars = (payload) => ({
    type: SET_DOLLARS,
    payload
})

export const SET_CREDITS = "SET_CREDITS"
export const setCredits = (payload) => ({
    type: SET_CREDITS,
    payload
})

export const SET_SUBSCRIPTION_ID = "SET_SUBSCRIPTION_ID"
export const setSubscriptionId = (payload) => ({
    type: SET_SUBSCRIPTION_ID,
    payload
})

export const SET_TRANSACTIONS = "SET_TRANSACTIONS"
export const setTransactions = (payload) => ({
    type: SET_TRANSACTIONS,
    payload
})

export const SET_PUBLIC_IDENTIFIER = "SET_PUBLIC_IDENTIFIER"
export const setPublicIdentifier = (payload) => ({
    type: SET_PUBLIC_IDENTIFIER,
    payload
})

export const SET_USER_SUMMARY = "SET_USER_SUMMARY"
export const setUserSummary = (payload) => ({
    type: SET_USER_SUMMARY,
    payload
})

