import React, { useEffect, useRef, useState } from 'react'
import ListTable from '../../components/ListTable'
import MainHeader from '../../components/MainHeader'
import fullLeftArrow from "../../assets/icons/arrow-right.svg"

import "./style.scss"
import SearchInput from '../../components/SearchInput'

import companypic from "../../assets/icons/randcompanylogo.svg"
import companypic2 from "../../assets/icons/company2.svg"
import CompanyTableRow from '../../components/CompanyTableRow'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setComapnyLists, setCompaniesChecked } from '../../actions/lists'
import { CSVLink } from 'react-csv'
import LoadingSpinner from '../../components/LoadingSpinner'
import refreshToken from '../../services/refreshToken'
import { callYoriApi } from '../../utils'
function CompanyListTable() {

    const dispatch = useDispatch()
    const [isloading, setIsLoading] = useState(false)
    const TOKEN_EXPIRE_MESSAGE = "token expired"

    const getCompanyEntities = async () => {
        // let userid=userState?.user.id
        setIsLoading(true)
        // console.log("Parms :",params)
        let comp_id = params?.id
        if (comp_id != null) {

            // fetch
            callYoriApi(`/entities/companies/${comp_id}`, "GET")
                .then(resdata => {

                    console.log("Contact Entities response :", resdata)

                    if (resdata?.length > 0) {
                        resdata = resdata.map(r => { return { ...r, check: false } })
                        dispatch(setComapnyLists(resdata))
                    }
                    else
                        dispatch(setComapnyLists([]))
                    setIsLoading(false)

                }).catch(err => {
                    dispatch(setComapnyLists([]))
                    setIsLoading(false)

                    console.log("contact entities Err :", err)
                }
                )
        } else {
            dispatch(setComapnyLists([]))
            setIsLoading(false)

        }

    }


    useEffect(() => {
        getCompanyEntities()
        // dispatch(setComapnyLists(data))
    }, [])

    const [search, setSearch] = useState("")
    const list = useSelector(state => state?.lists?.companylists?.companylists)

    const params = useLocation()?.state
    console.log("Params ------:", params)



    const [checkFlag, setCheckFlag] = useState(false)
    const handleCheckFlag = (e) => {
        setCheckFlag(e.target.checked)
        dispatch(setCompaniesChecked(e.target.checked))
        if (e.target.checked)
            setCount(list.length)
        else
            setCount(0)
    }

    const [count, setCount] = useState(0)
    const countSelected = () => {
        setCount(list.filter(lis => lis.check == true).length)
    }

    const deleteSelected = async () => {
        console.log("delete selected")
        setIsLoading(true)
        let folder_id = params?.id

        const selected_ids = list.filter(company => company.check)
        let ids = []
        selected_ids.map(selected => ids = [...ids, selected.id])

        console.log("selected_ids :", ids)

        // fetch
        callYoriApi(`/entities/companies/${folder_id}`, "DELETE", { body: JSON.stringify(ids) })
            .then(mess => {
                console.log("mess :", mess);

                setIsLoading(false);
                getCompanyEntities()
            })
            .catch(err => {
                console.log("Del Err :", err);
                setIsLoading(false)
            })

    }

    const deleteRow = async (id) => {
        console.log('delete Company Row :', id)

        setIsLoading(true)
        let folder_id = params?.id
        // hit delete row api

        // fetch
        callYoriApi(`/entities/companies/${folder_id}/${id}`, "DELETE")
            .then(res => {
                console.log("Deleted Contact Row :", res)
                getCompanyEntities()
                setIsLoading(false)

            })
            .catch(err => console.log("Delete Contact Row ERR:", err))
    }



    const [filedata, setFileData] = useState([])
    const fileref = useRef()
    const headers = [
        { label: "name", key: "name" },
        { label: "size", key: "size" },
        { label: "company Type", key: "ipo_status" },
        { label: "location", key: "hq_location" },
        { label: "website", key: "website" }
    ];

    const dowlondSelected = () => {
        const selected_ids = list.filter(company => company.check)
        let data = []
        selected_ids.map(selected => data = [...data,
        { name: selected.name, size: selected.staff_count, company_type: selected.company_type, location: selected.location, website: selected.website }
        ])

        setFileData(data)

        setTimeout(() => fileref.current.link.click(), 500)
        console.log("data :", data)
    }


    return (
        <div
            className={`listContainer ${isloading && 'dim'}`}
        >
            <MainHeader />
            <div className='listBody'>

                <div className='list__nav'>
                    <div>
                        <span>
                            <Link className='navlink' to={"/lists"}><img src={fullLeftArrow} /></Link>
                        </span>
                        <div className='nav_heading'> {params?.name} </div>
                        <div className='nav_num'> {params?.count} </div>
                    </div>
                    <div>
                        <SearchInput control={{ search, setSearch }} placeholder="Search this list" />
                    </div>
                </div>

                <div className='table_header'>
                    <div>
                        <input type="checkbox" className='check' onClick={handleCheckFlag} /> {count} lists selected
                    </div>
                    <div>
                        <button className='deletebtn' disabled={count == 0} style={count == 0 ? { cursor: "not-allowed" } : { cursor: "pointer" }} onClick={deleteSelected}>Delete</button>
                        <button className='dowlondbtn' disabled={count == 0} style={count == 0 ? { cursor: "not-allowed" } : { cursor: "pointer" }} onClick={dowlondSelected}>Dowlond</button>
                        <CSVLink
                            filename='selected_data.csv'
                            target="_self"
                            data={filedata}
                            headers={headers}
                            ref={fileref}
                            style={{ display: "hidden" }}

                        >

                        </CSVLink>

                    </div>
                </div>
                <ListTable
                    data="companylists"
                    search={search}
                    TableRow={CompanyTableRow}
                    countSelected={countSelected}
                    functions={{ deleteRow }}
                    titles={["Name", "Size", "Location", "Company Type", "Website"]}
                />

            </div>

            {
                isloading && <LoadingSpinner />
            }
        </div>
    )
}

export default CompanyListTable