import React, { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedUserContacts } from '../../actions/referrals'
import profile from "../../assets/icons/pc1.svg"
import { capitalizeFirstAlphabet, notify } from '../../utils'
import EllipsisTextComponent from '../EllipsisTextComponent'
import ProfileImageComponent from '../ProfileImageComponent'
import GreenCheckCircle from '../SvgComponents/GreenCheckCircle'
import InvitedProfile from "../../assets/icons/invitedProfiles.svg"
import InvitingProfile from "../../assets/icons/invitingProfiles.svg"
import "./style.scss"
import CustomToastContainer from '../ToastContainer'

function InviteProfileCheckCard({
  profileId = 0,
  profile_picture = "",
  last_name = "",
  first_name = "",
  headline = "",
  inviteStatus,
  isLoading = false,
  hasCheck = true,
  isReferralSent = false,
  style = {},
  public_identifier = '',
  showMessage,
  isInSendingFlow = false,
  messageSendingState = 0,
  imageLink = ''
}) {

  const selectedUserContacts = useSelector(state => state.referrals?.userContacts?.selectedUserContacts)
  const selectedUserContactsIds = selectedUserContacts.map(({ id }) => id)
  // const {profile,name,title,from,flag} = card
  // const {profile_picture,public_identifier,last_name,headline,first_name,entity_urn} = card

  let flag = true
  const dispatch = useDispatch()

  const styleMap = {
    approved: { border: "1px solid green" },
    rejected: { border: "1px solid green" }
  }
  const defaultStyle = { border: "1px solid transparent" }
  // let avatar=""
  // if(!profile)
  // {
  //   const peices=name.split(" ")
  //   if(peices.length>=2)
  //     avatar = peices[0].slice(0,1) + peices[1].slice(0,1) 
  //   else if(peices.length==1)
  //     avatar = peices[0].slice(0,1) 
  //   else  {
  //     avatar="NA"
  //   }
  //   console.log("avatar :",peices.length,avatar.toUpperCase())
  // }
  // let temp=profile

  const [checkboxState, setCheckboxState] = useState(false)

  let initials = ""

  const firstNameAlphabet = capitalizeFirstAlphabet(first_name?.trim())?.charAt(0)
  const lastNameAlphabet = capitalizeFirstAlphabet(last_name?.trim())?.charAt(0)
  initials = (firstNameAlphabet + lastNameAlphabet)


  const handleCheck = (e, id) => {
    dispatch(selectSelectedUserContacts(e.target.checked, id))
  }
  const updateCardCheckState = (id) => {
    // if (selectedUserContacts.length < 10 || selectedUserContactsIds.includes(id))
    dispatch(selectSelectedUserContacts(!selectedUserContactsIds.includes(id), id))
    // else
    //   showMessage()
  }
  // useEffect(() => {
  //   if(isInSendingFlow){
  //     console.log(`isInSendingFlow detected true for prospect id:::::::` , profileId)
  //   }
  // }, [])
  
  
  return (
    <div className='checkbox_card' style={{ ...style }} onClick={() => hasCheck && !isReferralSent && !isInSendingFlow && updateCardCheckState(profileId)}>
      <div
        style={defaultStyle}
        className={(hasCheck && isReferralSent) ? 'full_profile_card blur_profile_card' : 'full_profile_card'}
      // className='full_profile_card'

      >
        {
          (hasCheck && !isReferralSent && !isInSendingFlow) ? <input type="checkbox" checked={selectedUserContactsIds.includes(profileId)} onChange={(e) => handleCheck(e, profileId)} />
            : (!hasCheck && isReferralSent) || (!hasCheck && !isReferralSent) ?
              <span style={{ width: "13px", height: "inherit", backgroundColor: "transparent", margin: "3px 3px 3px 4px" }}></span>
              : (hasCheck && isReferralSent) ? 
              <span>
                <input style={{ visibility: "hidden" }} type="checkbox" />
                <img src={InvitedProfile} className="invitedStatusRibbon" />
              </span> : 
              (hasCheck && isInSendingFlow) && 
              <span>
                <input style={{ visibility: "hidden" }} type="checkbox" />
                <img src={InvitingProfile} className="invitedStatusRibbon" />
              </span>
          // : (hasCheck && isReferralSent) && <input type="checkbox" checked={selectedUserContactsIds.includes(profileId)} onChange={(e)=>handleCheck(e, profileId)}/>

        }
        <div>
          <div className='avatarimage'>
            <ProfileImageComponent
              imageLink = {imageLink}
              placeHolder={initials}
            />
          </div>
          <div
            className='profileNameAndHeadline'
          >
            <p>{first_name + " " + last_name}</p>
            {
              headline &&
              <EllipsisTextComponent
                string={headline}
                stringLengthCap={257}
              />
            }
            {/* {isLoading &&
              <ThreeDots
                height="24"
                width="60"
                radius="9"
                color="#4B44E1"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            } */}
            {
              isLoading && <progress id="file" value={`${messageSendingState}`} max="4" >  </progress>
            }
          </div>
          {
            (!hasCheck && isReferralSent) &&
            <div
              className='inviteStatus'
            >
              {
                inviteStatus == 'approved' ? <GreenCheckCircle /> : inviteStatus == 'rejected' ? <div></div> : <div></div>
              }
            </div>
          }
        </div>

      </div>
    </div>
  )
}

export default InviteProfileCheckCard