import React, { useEffect, useState } from 'react'

import "./style.scss"

const ProfileImageComponent = ({
    url,
    imageLink,
    placeHolder,
    compact = false
}) => {

    const [error, setError] = useState(false)

    useEffect(() => {
        setError(true)
    }, [placeHolder])
    
return (
    // placeHolder === imgSrc 
        <span
            className='placeHolder'
            style={
                compact ? {height: '28px', width: '28px' , borderRadius: '0px' , fontWeight: '500'} : {}
            }
        >
            <p>
                {placeHolder}
            </p>
            <img
                style={error ? {display: 'none'} : {}}
                className={compact ? 'imageSizingCompact' : 'imageSizing'}
                src={imageLink}
                onError={e=>setError(true)}
                onLoad={e=>setError(false)}
            />

            
        </span>
    )
}

export default ProfileImageComponent