/* global chrome */

import React, { useEffect, useState } from 'react'
import AuthenticationForm from '../../components/AuthenticationForm'
import AuthBackgroundCover from '../../components/AuthBackgroundCover'
import './style.scss'
import AuthenticationStepForm from '../../components/AuthenticationStepForm'
import firebaseApp from '../../firebaseApp'
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, getAuth, sendEmailVerification, updateProfile, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, getAdditionalUserInfo, setPersistence, browserSessionPersistence, browserLocalPersistence, deleteUser } from 'firebase/auth'
import { querystringDecode } from '@firebase/util'
import { isLoggedIn, signOut, stringEquality, callYoriApi, trackUserActivity } from '../../utils'
import PrimaryButton from '../../components/PrimaryButton'
// import Sqout from "../../img/Sqout.svg"
// import SqoutLogo from "../../img/SqoutLogo.svg"
import { connect, useDispatch } from 'react-redux'
import { gapi } from 'gapi-script';

import CryptoJS from "crypto-js"

import axios from 'axios'
import { json, useLocation } from 'react-router'
import { setUser, setUserId } from '../../actions/auth'
import LoadingSpinner from '../../components/LoadingSpinner'
const Login = ({
  location,
  navigate,
  ...rest
}) => {



  const dispatch = useDispatch()
  const path = useLocation()
  // const [emailForSignIn, setEmailForSignIn] = useState(window.localStorage.getItem('emailForSignIn'))
  // const [nameForSignIn, setNameForSignIn] = useState(window.localStorage.getItem('nameForSignIn'))
  const [step, setStep] = useState(window.localStorage.getItem('authStep') || 0)
  const qsParams = querystringDecode(location?.search)
  const reg = location.pathname.split("/")[1]
  // console.log("path :", reg)
  const variantKey = location?.pathname?.split("/")[1]
  const authVariant = {
    login: {
      submitLabel: "Login ",
      footerLabel: "Don't have an account?",
      footerHref: "signup ",
      footerHrefLabel: "Signup Instead"
    },
    signup: {
      submitLabel: "Signup ",
      footerLabel: "Already have an account?",
      footerHref: "login ",
      footerHrefLabel: "Login Instead"
    }
  }

  const [isLoading, setisLoading] = useState(false)


  const sendTokenToExtension = (user, token) => {
    console.log("=======sendTokenToExtension=======")
    const extId = process.env.REACT_APP_EXT_ID

    if (extId && chrome.runtime) {
      chrome.runtime.sendMessage(extId, { type: "AUTH_LOGIN", user, token }, (res) => { })
    }
  }


  useEffect(() => {
    try {
      console.log(path)
      if (path?.search) {
        let data = path?.search?.split("val=")[1]
        var decrypted = CryptoJS.AES.decrypt(data, process.env.REACT_APP_HASH_AUTH_KEY);
        const user = decrypted.toString(CryptoJS.enc.Utf8);
        onSuccessLogin(JSON.parse(user))
      }
      else {
        const token = localStorage.getItem("TKNV1")
        if (token) {
          setisLoading(true)

          callYoriApi("/ping", "GET").then(res => {
            setisLoading(false)
            if (res?.success) {
              const name = localStorage.getItem("name")
              const email = localStorage.getItem("email")

              sendTokenToExtension({ name, email }, token)
              return navigate("/lists", { state: {} })
            }
            localStorage.removeItem("TKNV1")

          }).catch(err => console.log(err))
        }
      }
    } catch (error) {
      console.error(error?.message)
    }
  }, [])



  const onSuccessLogin = (res) => {

    setisLoading(true)
    // console.log('Profile OBJ:', res?.profileObj);
    const googleUser = res

    const token = localStorage.getItem("TKNV1")
    const referal_link = localStorage.getItem("referal_link")
    const options = referal_link ? { referal_link } : {}

    if (!token && googleUser) {
      // dont forget Referral link if available
      const payload = {
        email: googleUser?.email,
        name: googleUser?.name,
        google_id: googleUser?.googleId,
        picture: googleUser?.picture,
        ...options
      }
      fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      })
        .then(res => res.json())
        .then(data => {
          console.log("Data =======:", data)
          if (data.status === 200) {
            localStorage.removeItem("referal_link")
            localStorage.setItem("TKNV1", data.token)
            localStorage.setItem("name", data.user.name)
            localStorage.setItem("email", data.user.email)


            data?.is_super_user && localStorage.setItem("is_super_user", true)

            sendTokenToExtension(data.user, data.token)
            // send Message to Extension
            setisLoading(false)

            let alertOptions = {}
            if (referal_link) {
              alertOptions = { referral_user: data?.referral_user, is_referral: true, is_new_user: true }
              return navigate("/onboarding", { state: { ...alertOptions } })
            }
            if (data?.is_new_user) {
              console.log("Data =====data==:", data)
              alertOptions = { is_new_user: true }
              return navigate("/onboarding", { state: { ...alertOptions } })
            }
            trackUserActivity({
              action: "AUTH",
              entity_id: null,
              entity_type: "users",
              context: {
                  name: 'LOG IN'
              },
            })
            navigate("/lists", { state: { ...alertOptions } })
          }
        })
        .catch(error => {
          setisLoading(false)
          console.log("error :", error?.message)
        })
    }
    else {

      const name = localStorage.getItem("name")
      const email = localStorage.getItem("email")

      sendTokenToExtension({ name, email }, token)
      setisLoading(false)

      navigate("/lists")
    }
  };



  const signupSteps = [
    <React.Fragment>
      <h1 style={{ marginTop: "1.6rem", fontSize: "2rem", width: "100%" }}>
        Extension Installed!
      </h1>
      <h3>
        {`Let’s ${authVariant[variantKey].submitLabel}`}
      </h3>
      <AuthenticationForm
        submitLabel={authVariant[variantKey].submitLabel}
        footerLabel={authVariant[variantKey].footerLabel}
        footerHref={authVariant[variantKey].footerHref}
        footerHrefLabel={authVariant[variantKey].footerHrefLabel}
      // onSuccessLogin={onSuccessLogin}
      // onFailure={onFailure}
      />
    </React.Fragment>,
    <div
      className='verifyAuth'
    >

      <h3>
        {`${authVariant[variantKey].submitLabel} link has ben sent to your email`}
      </h3>
      {/* <PrimaryButton
        onClick={e=>{
          handleAuth(nameForSignIn, emailForSignIn)
          window.localStorage.setItem('authStep', 1);
          setStep(1)
        }}
      >
        {`Resend ${authVariant[variantKey].submitLabel} link`}
      </PrimaryButton> */}
      <PrimaryButton
        variant='Beta'
        onClick={e => {
          window.localStorage.removeItem('emailForSignIn')
          window.localStorage.removeItem('nameForSignIn')
          window.localStorage.setItem('authStep', 0);
          setStep(0)
        }}
      >
        {`Enter email again`}
      </PrimaryButton>
    </div>,
    <React.Fragment>
      <h3>
        What company do you work at?
      </h3>
      <AuthenticationStepForm
        inputPlaceholder='Enter Company'
        onClick={e => {
          // setStep(prev=>prev<2?prev+1:0)
        }}
      />
    </React.Fragment>,
    <React.Fragment>
      <h3>
        What is your role?
      </h3>
      <AuthenticationStepForm
        inputPlaceholder='Enter Role'
        onClick={e => {
          // setStep(prev=>prev<2?prev+1:0)
        }}
      />
    </React.Fragment>,

  ]

  return (
    <div
      className={`loginContainer ${isLoading && 'dim'}`}
    >
      {/* <div
        className='logoContainer'
        onClick={e => navigate("/")}
      >
        <img src={SqoutLogo} />
        <img src={Sqout} />
      </div> */}
      <span>
        <div>
          {signupSteps[step]}
        </div>
      </span>
      <span>
        <AuthBackgroundCover />
      </span>
      {
        isLoading && <LoadingSpinner />
      }
    </div>
  )
}

export default connect(state => ({
}))(Login)