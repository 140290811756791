
import { combineReducers } from 'redux'
import { companylists } from './comapnylists'
import { folders } from './folders'
import { userslists } from './userlists'


export default combineReducers({
    folders,
    userslists,
    companylists
})


