import * as ActionTypes from "../../actions/dashboard";

const initialState = {
  allUsers: [],
  isUsersTableLoading: false,
  isUsersTableLoaded: false,
  totalProfilesViewed: 0,
  totalCompaniesViewed: 0,
  userProfile: {},
  allUsersIds: [],
  allUsersCount: 0
};

export const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INITIATE_DASHBOARD:
      return {
        ...state,
        isUsersTableLoading: true,
        isUsersTableLoaded: false
      };

    case ActionTypes.INITIATE_DASHBOARD_FAILURE:
      return {
        ...state,
        isUsersTableLoading: false,
        isUsersTableLoaded: false
      };

    case ActionTypes.FETCH_INITIAL_USERS:
      return {
        ...state,
        allUsers: action.payload.initialUsersSorted,
        allUsersCount: action.payload.allUsersCount,
        allUsersIds: action.payload.allUsersIds,
        isUsersTableLoading: false,
        isUsersTableLoaded: true
      };

    case ActionTypes.FETCH_REMAINING_USERS_CHUNK:
      return {
        ...state,
        allUsers: [...state?.allUsers , ...action.payload.remainingUsersSorted],
        allUsersCount: action.payload.allUsersCount,
      };

    case ActionTypes.INIT_USER_PROFILE:
      return {
        ...state,
        userProfile: {}
      };
    case ActionTypes.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload
      };

    case ActionTypes.REFRESH_USER_ACTIVITY:
      return {
        ...state,
        userProfile: {...state?.userProfile , ...action.payload}
      };


    default:
      return state;
  }
};
