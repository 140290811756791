import * as ActionTypes from "../../actions/lists";

const initialState = {
    userslist:[]
};

export const userslists = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USERSLIST:
      return {
        ...state,
        userslist:[...action.payload],
      };

      case ActionTypes.SET_USERS_CHECKED:
        return {
          ...state,
          userslist:state.userslist.map(userlist=>{return {...userlist,check:action.payload}}),        
        }
      case ActionTypes.SET_USER_CHECKED:
        return {
          ...state,
          userslist:state.userslist.map(list=>{
            if(list.id==action.payload.id) 
               list.check=action.payload.check
            return {...list}}),        
        }
    default:
      return state;
  }
};
