import React, { useState } from 'react'
import HomeContainerPlaqueComponent from '../HomeContainerPlaqueComponent'

import listInfoAsset1 from '../../img/listInfoAsset1.png'
import excelLogo from '../../assets/icons/excelLogo.svg'
import yoriLogoAndTextWhite from '../../assets/icons/yoriLogoAndTextWhite.svg'

import './style.scss'
import RightArrow from '../RightArrow'
import { isMobile } from 'react-device-detect'

const ListInfoComponent = ({
    onGetExtension
}) => {
    const [ctaHoverHandler, setCtaHoverHandler] = useState(false)
    const [createListsHoverHandler, setCreateListsHoverHandler] = useState(false)

    return (
        <div
            className='listInfoComponent'
        >
            <h1>
                Create lists and save prospects
            </h1>
            <div
                className='listInfoCompCreateListsCTA'
            >
                <img src={listInfoAsset1} />
                <span>
                    <h3>
                        Cerate & Customize lists of companies
                    </h3>
                    {
                        !isMobile ?
                            <>
                                <button
                                    onMouseEnter={() => setCreateListsHoverHandler(true)}
                                    onMouseLeave={() => setCreateListsHoverHandler(false)}
                                >
                                    Get Extension for Free
                                    <RightArrow variant={createListsHoverHandler ? 'white' : 'black'} />
                                </button>
                            </> : <></>
                    }
                </span>
            </div>

            <span
                className='listInfoCompDownloadListsCTA'
            >
                <div style={{ background: '#1C1B27', }}>
                    <h3>Download your list as CSV</h3>
                    <div
                        className='excelLogoHighlight'
                    >
                        <div>
                            <h4>Dowload list</h4>
                            <p>450 companies selected</p>
                        </div>
                        <span>
                            <img src={excelLogo} />
                        </span>
                    </div>
                </div>

                <div style={{ background: '#4B44E1' }}>
                    <img src={yoriLogoAndTextWhite} />
                    <h3>Get Started with Yori now</h3>
                    <h2>Earn free credits by inviting your friends to Yori. Win-win</h2>
                    {
                        !isMobile ?
                            <>
                                <button
                                    onClick={onGetExtension}
                                    onMouseEnter={() => setCtaHoverHandler(true)}
                                    onMouseLeave={() => setCtaHoverHandler(false)}
                                >
                                    Get Extension for Free
                                    <RightArrow variant={ctaHoverHandler ? 'blue' : 'white'} />
                                </button>
                            </> : <></>
                    }
                </div>
            </span>
        </div>
    )
}

export default ListInfoComponent