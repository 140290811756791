import React, { useEffect, useState } from 'react'
import "./style.scss"
import DetailsBox from '../DetailsBox'
import SearchRow from '../SearchRow'

import leftarrow from "../../../assets/icons/leftarrow.svg"
import rightarrow from "../../../assets/icons/rightarrow.svg"

import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import LinkedInIcon from "../../../assets/icons/LinkedIn - Original.svg"
import { useSelector } from 'react-redux'




function UserLists({ user }) {

    const _ = require("lodash")
    const {
        user_lists
    } = useSelector(state => state?.dashboard?.dashboard?.userProfile)

    const [search, setSearch] = useState("")
    const [tab, setTab] = useState(0)

    const [Data, setData] = useState(user_lists)
    const [tableData, setTableData] = useState(Data)
    useEffect(() => {
        setTableData(user_lists)
    }, [user_lists])


    useEffect(() => {
        if (search == '') {
            setTableData(Data)
        } else {
            setTableData(Data?.filter(list => list?.name?.toLowerCase()?.includes(search?.toLowerCase())))
        }
    }, [search])


    const [pageData, setPageData] = useState([])
    let [totalPages, setTotalPages] = useState(0)
    let [currentPage, setCurrentPage] = useState(1)

    const nextPage = () => {
        if (currentPage < totalPages) {
            setPageData(tableData?.slice(currentPage * 8, (currentPage + 1) * 8))

            setCurrentPage(currentPage + 1)

        }
    }
    const previousPage = () => {
        if (currentPage != 1) {
            setPageData(tableData?.slice((currentPage - 2) * 8, (currentPage - 1) * 8))

            setCurrentPage(currentPage - 1)
        }
    }

    useEffect(() => {
        setTotalPages(Math.ceil(tableData?.length / 8))
        setPageData(tableData?.slice(0, 8))
    }, [tableData])


    return (
        <div className='userLists'>
            <div className='header'>
                <DetailsBox width="273.33px" title="Total lists" value={user_lists?.length || 0} />
                {/* <DetailsBox width="273.33px" title="Average Lists" value="3" select={true} /> */}
                {/* <DetailsBox width="273.33px" title="Average Entities in lists" value={(_.sum(user_lists?.map((list, id) => parseInt(list?.folder_length))) / user_lists?.length) || 0} select={true} /> */}
            </div>

            <SearchRow search={search} setSearch={setSearch} />

            <div className="usersTable">

                <MDBTable className="table">
                    <MDBTableHead className="tableHead">
                        <tr>
                            <th scope='col' style={{ width: "20%" }}>List name</th>
                            <th scope='col' style={{ width: "20%" }}>Entity Type</th>
                            <th scope='col' style={{ width: "20%" }}># of Entities</th>
                            <th scope='col' style={{ textAlign: "left" }}>Date Created</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody className='usersTableBody'>
                        {
                            pageData?.length > 0 && pageData?.map((row, id) => {
                                return <tr className='tableRow' >

                                    <td>
                                        <p className=''>{row?.name.trim()}</p>
                                    </td>
                                    <td >
                                        <p className=''>{row?.entity_type_id == 1 ? 'Profile' : row?.entity_type_id == 3 ? 'Company' : "Error"}</p>
                                    </td>
                                    <td >
                                        <p className=''>{row?.folder_length}</p>
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                        <p className=''>{new Date(row?.created_at).toLocaleString()}</p>
                                    </td>

                                </tr>

                            })
                        }
                    </MDBTableBody>
                </MDBTable>
                <div className='table_footer'>
                    <div>
                        {(currentPage - 1) * 8} - {(currentPage * 8) < tableData?.length ? (currentPage * 8) : tableData?.length} of {tableData?.length}
                    </div>


                    <div className='table_footer_actions'>
                        <img src={leftarrow} onClick={previousPage} />
                        <span>Page {totalPages != 0 ? currentPage : currentPage - 1} of {totalPages}</span>
                        <img src={rightarrow} onClick={nextPage} />
                    </div >
                </div>

            </div >


        </div>
    )
}

export default UserLists
