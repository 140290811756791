import React from 'react'
import background from '../../img/backgroundCover.svg'
import frontground from '../../img/frontgroundCover.svg'
import './style.scss'
const AuthBackgroundCover = ({

}) => {
  return (
    <div
        className='authBackgroundCover'
    >
        <img
            src={background}
        />
        <img
            src={frontground}
        />
    </div>
  )
}

export default AuthBackgroundCover