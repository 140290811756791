import { combineReducers } from "redux";
// import login from './login'
import auth from "./auth";
import lists from "./lists"
import referrals from "./referrals";
import dashboard from "./dashboard";

export default combineReducers({
  auth,
  lists,
  referrals,
  dashboard,
})
