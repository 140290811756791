import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/SearchInput'
import DetailsBox from '../DetailsBox'
import searchButton from "../../../assets/icons/Funnel.svg"
import LinkedInIcon from "../../../assets/icons/LinkedIn - Original.svg"
import yoriIcon from "../../../img/YoriLogo.svg"

import "./style.scss"
import Circles from '../Circles'
import PrimaryButton from '../../../components/PrimaryButton'
import ActivityRow from '../ActivityRow'
import SearchRow from '../SearchRow'
import { useDispatch, useSelector } from 'react-redux'
import { set } from 'lodash'
import { refreshUserActivity } from '../../../actions/dashboard'

function UserActivity({ user }) {
    const _ = require("lodash")
    const dispatch = useDispatch()

    const [search, setSearch] = useState("")
    const [activityResponseToUse, setActivityResponseToUse] = useState()
    const [activityDates, setActivityDates] = useState([])
    const [activityValues, setActivityValues] = useState([])
    const [datesToUse, setDatesToUse] = useState([])
    const [datesToUseCap, setDatesToUseCap] = useState(2)
    const [myInterval, setMyInterval] = useState(null)


    const {
        user_activity_response,
        profiles_viewed_count,
        credits_spent_count,
        companies_viewed_count,
    } = useSelector(state => state?.dashboard?.dashboard?.userProfile)

    useEffect(() => {
            let myInterval = setInterval(() => {
                dispatch(refreshUserActivity(user?.id))
            }, 5000)
        

      return () => {
          clearInterval(myInterval)
      }
    }, [])
    

    useEffect(() => {
        let activityDates = {}
        user_activity_response?.map((activity, id) => {
            const tryThisDate = new Date(activity?.updated_at).toISOString().split('T')[0]
            if (activityDates[tryThisDate]) {
                activityDates[tryThisDate] = [activity, ...activityDates[tryThisDate]]
            }
            else {
                activityDates[tryThisDate] = [activity]
            }
        })
        setActivityResponseToUse(activityDates)
    }, [user_activity_response])

    useEffect(() => {
        if (activityResponseToUse) {
            setActivityValues(Object.values(activityResponseToUse || {})?.map((row, id) => _.sortBy(row, new Date(row?.updated_at)?.getTime())?.reverse()) || [])
            setActivityDates(Object.keys(activityResponseToUse || {}) || [])
            // console.log({activityResponseToUse , activityDates , activityValues})
        }
    }, [activityResponseToUse])

    useEffect(() => {
        if (activityDates?.length != 0) {
            setDatesToUse(activityDates?.slice(0, 2))
        }
    }, [activityDates])

    const loadMoreHandler = () => {
        if (datesToUse?.length != 0) {
            setDatesToUse(activityDates?.slice(0, datesToUse?.length + 2))
        }
    }

    return (
        <div className='userActivity'>
            { }
            <div className='header'>
                {/* <DetailsBox title="Last Login" value="27 December, 2022" />
                <DetailsBox title="Average Session duration" value="10 Minutes" />
                <DetailsBox title="Daily Visits" value="2" /> */}
                <DetailsBox title="Profiles Viewed" value={profiles_viewed_count} />
                <DetailsBox title="Contacts Viewed" value={credits_spent_count} />
                <DetailsBox title="Companies Viewed" value={companies_viewed_count} />
            </div>
            {/* <SearchRow /> */}


            <div className='activities'>
                <div>
                    {
                        datesToUse && datesToUse?.map((row, id) => {
                            return (
                                <>
                                    <p>{activityDates[id] && new Date(activityDates[id])?.toUTCString()?.split(' ')?.slice(0, 4)?.join(' ')}</p>
                                    {
                                        activityValues[id]?.length > 0 &&
                                        activityValues[id]?.map(
                                            (value, id) => {
                                                return (
                                                    value?.entity_type == 'profile' ?
                                                        <ActivityRow
                                                            time={new Date(value?.updated_at).toLocaleTimeString()}
                                                            view="User Viewed"
                                                            viewType={value?.action == "OPENED_LI_PAGE" ? `Person's profile` : 'Contact details'}
                                                            linkName={value?.name}
                                                            link={`https://linkedin.com/in/${value?.linkedin_identifier}`}
                                                            platform=
                                                            {
                                                                value?.action == "OPENED_LI_PAGE" ? (value?.context?.view == true ? 'on Yori' : 'on LinkedIn') :
                                                                    value?.action == "BUTTON_CLICK" ? 'on Yori' : null
                                                            }
                                                            type=
                                                            {
                                                                value?.action == "OPENED_LI_PAGE" ? (value?.context?.view == true ? 'yori' : 'linkedin') :
                                                                    value?.action == "BUTTON_CLICK" ? 'contact' : null
                                                            }

                                                            icon={value?.action == "OPENED_LI_PAGE" ? (value?.context?.view == true ? yoriIcon : LinkedInIcon) : yoriIcon}
                                                        />
                                                        :
                                                        value?.entity_type == 'company' ?
                                                            <ActivityRow
                                                                time={new Date(value?.updated_at).toLocaleTimeString()}
                                                                view="User Viewed"
                                                                viewType="Company"
                                                                linkName={value?.name}
                                                                link={`https://linkedin.com/company/${value?.universal_name}`}
                                                                platform={value?.action == "OPENED_LI_PAGE" ? (value?.context?.view == true ? 'on Yori' : 'on LinkedIn') : null}
                                                                icon={value?.action == "OPENED_LI_PAGE" ? (value?.context?.view == true ? yoriIcon : LinkedInIcon) : null}
                                                                type={value?.action == "OPENED_LI_PAGE" ? (value?.context?.view == true ? 'yori' : 'linkedin') : null}
                                                            />
                                                        :
                                                        value?.entity_type == 'users' ? 
                                                            <ActivityRow
                                                            
                                                                time={new Date(value?.updated_at).toLocaleTimeString()}
                                                                view={value?.context?.name == "LOG IN" ? 'User logged in.' : 'User logged out.'}
                                                                // viewType=""
                                                                // linkName={value?.name}
                                                                // link={`https://linkedin.com/company/${value?.universal_name}`}
                                                                // platform={value?.action == "OPENED_LI_PAGE" ? (value?.context?.view == true ? 'on Yori' : 'on LinkedIn') : null}
                                                                // platform='from/to Yori'
                                                                // icon={yoriIcon}
                                                                type='auth'
                                                            
                                                            />
                                                        :
                                                        <p>Nothing Found</p>
                                                )
                                            }
                                        )
                                    }
                                </>

                            )
                        })
                    }
                </div>

                {
                    datesToUse && datesToUse?.length > 0 && datesToUse?.length !== activityDates.length &&
                    <PrimaryButton onClick={() => loadMoreHandler()} variant='Beta' style={{ marginLeft: "1rem", borderRadius: "10px", padding: "3px 10px", fontSize: "14px" }}>Load More</PrimaryButton>


                }
                {
                    profiles_viewed_count === 0 &&
                    credits_spent_count === 0 &&
                    companies_viewed_count === 0 && `No Activities Found for user`
                }
                {
                    !profiles_viewed_count && profiles_viewed_count != 0 &&
                    `Loading...`
                }

            </div>

        </div>
    )
}

export default UserActivity