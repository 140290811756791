import { getAuth } from 'firebase/auth';
import React from 'react'

const refreshToken = async () => await new Promise((resolve, reject) => {
  getAuth().onAuthStateChanged(function (user) {
    if (user) {
      const token = getAuth().currentUser?.getIdToken(/* forceRefresh */ true).then(res => res)
        .catch(error => {
          console.log("Error :", error)
          reject(false)
        })
      // console.log("=======================================================ttttttt",token)
      resolve(token)
    }
  });
})

export default refreshToken