import React from 'react'
import './style.scss'

import yoriSvg from "../../../img/Yori.svg"
import yoriLogoSvg from "../../../img/YoriLogo.svg"


// left sidebar dependencies
import UsersThree from "../../../assets/icons/UsersThree.svg"
import ChartPieSlice from "../../../assets/icons/ChartPieSlice.svg"
import profile from "../../../assets/icons/profile.png"
import { useNavigate } from 'react-router'

function DashBoardSideBar({ tab, setTab }) {
    const navigate = useNavigate()
    return (
        <div className='leftSideBar'>
            <div className='sideBarTop'>
                <div className='sideBarLogo' onClick={() => navigate("/lists")}>
                    <img src={yoriLogoSvg} />
                    <img src={yoriSvg} />
                </div>

                {/* buttons */}
                <div className='sideBarButtons'>
                    <div className={`sideBarButton ${tab === 0 ? 'sideBarButtonActive' : ''}`} onClick={(prev) => navigate("/admin/dashboard")}>
                        <img src={ChartPieSlice} />
                        <span> Default</span>
                    </div>
                    <div className={`sideBarButton ${tab === 1 ? 'sideBarButtonActive' : ''}`} onClick={(prev) => navigate("/admin/dashboard/users")}>
                        <img src={UsersThree} />
                        <span> Users</span>
                    </div>
                    <div className={`sideBarButton ${tab === 2 ? 'sideBarButtonActive' : ''}`} onClick={(prev) => navigate("/admin/dashboard/funnel")}>
                        <img src={ChartPieSlice} />
                        <span> Funnel</span>
                    </div>
                </div>


            </div>
            <div className='sideBarBottom'>
                <img src={profile} />
                <div>
                    <span>{localStorage.getItem("name")}</span>
                    <span>Admin</span>
                </div>
            </div>
        </div>
    )
}

export default DashBoardSideBar