import React, { useEffect, useState } from 'react'
import MainHeader from '../../../../components/MainHeader'
import fullLeftArrow from "../../../../assets/icons/arrow-right.svg"
import stripeLocked from "../../../../img/StripeLocked.svg"
import visalogo from "../../../../assets/icons/Visalogo.svg"
import circleslogo from "../../../../assets/icons/twocircles.svg"
import americanexpresslogo from "../../../../assets/icons/americanexpress.svg"

import "./style.scss"
import PrimaryButton from '../../../../components/PrimaryButton'
import { useNavigate } from 'react-router'
import { awaitfunction, customOnlyNumberOnChange } from '../../../../utils'
import { set } from 'lodash'
import { CountryDropdown } from 'react-country-region-selector'
import { callYoriApi, detectCardType, notify } from '../../../../utils'
import CustomToastContainer from '../../../../components/ToastContainer'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { async } from '@firebase/util'
import { isDisabled } from '@testing-library/user-event/dist/utils'
import ConfirmationModal from '../ConfirmationModal'


const TopUp = () => {

    const _ = require('lodash')
    const todayDate = new Date()
    const todayYear = _.toString(todayDate.getFullYear()).slice(2, 4)
    const todayMonth = _.toString(todayDate.getMonth() + 1)
    const navigate = useNavigate()
    const onGoBack = () => {
        navigate("/wallet")
    }
    const updateMonthYearChangeTrigger = () => {
        setMonthYearChangeTrigger(prev => prev += 1)
    }
    const handleSetDateTrue = () => {
        setFormStates(prev => ({ ...prev, cardExpiryYear: true, cardExpiryMonth: true }))
        // setFormStates(prev => ({ ...prev, cardExpiryMonth: true }))
        console.log(`:::::::::::::::::::inside the setdatetrue function `, formStates)
    }
    const handleSetDateFalse = () => {
        setFormStates(prev => ({ ...prev, cardExpiryYear: false, cardExpiryMonth: false }))
        // setFormStates(prev => ({ ...prev, cardExpiryYear: false }))
        // setFormStates(prev => ({ ...prev, cardExpiryMonth: false }))
        console.log(`______________inside the setdatefalse function `, formStates)
    }


    const [isAmountCorrect, setIsAmountCorrect] = useState(false)
    const [isCvcCorrect, setIsCvcCorrect] = useState(false)
    const [isCardNumberCorrect, setIsCardNumberCorrect] = useState(false)
    const [isExpiryMonthCorrect, setIsExpiryMonthCorrect] = useState(false)
    const [isExpiryYearCorrect, setIsExpiryYearCorrect] = useState(false)


    const [collectiveTrueState, setCollectiveTrueState] = useState(false)
    const [trueStateUpdateCard, setTrueStateUpdateCard] = useState(false)
    const [handleChangeTrigger, setHandleChangeTrigger] = useState(0)
    const [monthYearChangeTrigger, setMonthYearChangeTrigger] = useState(0)
    const [confirmPayment, setConfirmPayment] = useState(false)

    let [formValues, setFormValues] = useState({
        amount: '',
        cardNumber: '',
        cardExpiryMonth: '',
        cardExpiryYear: '',
        cardCvc: '',
        cardholderName: '',
        companyName: '',
        billingEmail: '',
        billingAddress: '',
        city: '',
        zipCode: '',
        country: ''
    })

    const [formStates, setFormStates] = useState({
        amount: true,
        cardNumber: true,
        cardExpiryMonth: true,
        cardExpiryYear: true,
        cardCvc: true,
        cardholderName: true,
        companyName: true,
        billingEmail: true,
        billingAddress: true,
        city: true,
        zipCode: true,
        country: true,
    })





    useEffect(() => {
        console.log('monthYearChangeTrigger above here', monthYearChangeTrigger)
        if (monthYearChangeTrigger != 0) {
            console.log(`today year and today month `, { todayYear, todayMonth, 'formValues?.cardExpiryYear': formValues?.cardExpiryYear, 'formValues?.cardExpiryMonth': formValues?.cardExpiryMonth })
            if ((parseInt(todayYear) < parseInt(formValues?.cardExpiryYear) || ((parseInt(todayYear) == parseInt(formValues?.cardExpiryYear)) && (parseInt(formValues?.cardExpiryMonth) >= parseInt(todayMonth))))) {
                handleSetDateTrue()
            } else {
                console.log('=================SET FALSE====================')
                handleSetDateFalse()
            }
            if (formValues?.cardCvc.length != 3) {
                setFormStates(prev => ({ ...prev, cardCvc: false }))
            }

            console.log('formStates', formStates)
        }
        if (handleChangeTrigger != 0) {
            // console.log(`formValues ::::::::` , formValues)
            console.log(`============TRIGGERED FOR setCollectiveTrueState============`, { formStates, formValues })
            setCollectiveTrueState(
                () => getCollectiveTrueState()
            )
            const tempState = { ...formStates }
            const tempValues = { ...formValues }
            delete tempState.amount
            delete tempValues.amount
            console.log(`TRIGGERED STATES HANDLING FOR UPDATE CARD========================`, { tempState, tempValues })
            setTrueStateUpdateCard(
                () => getCollectiveTrueState(tempState, tempValues)
            )

            // awaitfunction(2000)
            // console.log("trueStateUpdateCard -----:", trueStateUpdateCard, collectiveTrueState)

        }
        console.log('values displayed here ', { 'formValues.amount': formValues.amount, showUpdateButtons, isPaymentMethodAlreadyGiven, collectiveTrueState })
        console.log('isSubmitDisabled =============', isSubmitDisabled)

    }, [handleChangeTrigger])








    const getCollectiveTrueState = (states, values) => {
        const newState = states || formStates
        const newValues = values || formValues
        let currentStates = Object.values(newState)
        let stateslength = currentStates.length
        currentStates = currentStates.filter(bool => bool)
        console.log('currentStates', { states, newState })

        let currentValues = Object.values(newValues)
        let valueslength = currentValues.length
        currentValues = currentValues.filter(val => val !== '')
        console.log('currentValues', { values, newValues })
        // let inputLogicCheck = false
        // if(obj?.amountCorrect && obj?.cvcCorrect && obj?.cardNumberCorrect && obj?.expiryDateCorrect ) {
        //     inputLogicCheck = true
        // }

        // console.log("Lengths ---:", currentStates.length, stateslength, currentValues.length, valueslength)
        if (currentStates.length == stateslength && currentValues.length == valueslength) {
            console.log(`All good to go`)
            return (true)
        }
        else {
            return (false)
        }
    }

    // Check If payment method is already save
    const [cardDetails, setCardDetails] = useState()
    const [cardType, setCardType] = useState()
    const [isPaymentMethodAlreadyGiven, setIsPaymentMethodAlreadyGiven] = useState(false)
    const [showUpdateButtons, setShowUpdateButtons] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const getPaymentMethod = async () => {
        try {
            setIsLoading(true)
            const res = await callYoriApi("/payment/", "GET")
            if (res?.status == 200) {

                console.log('res?.card', res?.card)
                setCardDetails(res?.card)
                setIsPaymentMethodAlreadyGiven(true)
                setCardType(detectCardType(5555555555554444))

                // 378282246310005  amex
                // 4111111111111111  visa card
                // 5555555555554444  master card ex
                // res?.card.card_number
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error?.message)
        }
    }

    useEffect(() => {
        getPaymentMethod()
    }, [])

    const hideCardNumber = (number) => {
        return `***********${number.slice(12)}`
    }
    const handleFormStateChange = (e) => {
        if (e.target.value.length == 0) {
            setFormStates(prev => ({ ...prev, [e.target.id]: false }))
        } else {
            setFormStates(prev => ({ ...prev, [e.target.id]: true }))
        }
    }

    const handleAmountChange = (e, num) => {
        if (e.target.value >= 5 && e.target.value <= 10000) {
            //true case
            setFormStates(prev => ({ ...prev, [e.target.id]: true }))
            setFormValues(prev => ({ ...prev, ...num }))
        }
        if (e.target.value > 10000) {
            setFormStates(prev => ({ ...prev, [e.target.id]: true }))
            setFormValues(prev => ({ ...prev, [e.target.id]: 10000 }))
        }
        if (e.target.value < 5 && e.target.value !== '') {
            //show error
            setFormStates(prev => ({ ...prev, [e.target.id]: false }))
            setFormValues(prev => ({ ...prev, ...num }))
        }
        if (e.target.value == '') {
            //show error
            setFormStates(prev => ({ ...prev, [e.target.id]: false }))
            setFormValues(prev => ({ ...prev, [e.target.id]: '' }))
        }
    }

    const handleCvcChange = (e, num) => {
        if (e.target.value.length <= 3) {
            setFormStates(prev => ({ ...prev, [e.target.id]: false }))
            setFormValues(prev => ({ ...prev, ...num }))
        }
        if (e.target.value.length >= 3) {
            setFormStates(prev => ({ ...prev, [e.target.id]: true }))
            setFormValues(prev => ({ ...prev }))

            // if(e.target.value.length == 3){
            //     setIsCvcCorrect(true)
            // }
            if (parseInt(e.target.value) > 999) {
                setFormStates(prev => ({ ...prev, [e.target.id]: true }))
                setFormValues(prev => ({ ...prev }))
            }
            // updateMonthYearChangeTrigger()
        }

    }

    const getPayload = () => {

        const payload = {
            "cardholder_name": formValues.cardholderName,
            "number": formValues.cardNumber,
            "expiry_mm": parseInt(formValues?.cardExpiryMonth),
            "amount": parseInt(formValues.amount),
            "expiry_yy": parseInt(formValues?.cardExpiryYear),
            "cvc": formValues.cardCvc,
            "city": formValues.city,
            "zipcode": formValues.zipCode,
            "country": formValues.country,
            "company_name": formValues.companyName,
            "billing_address": formValues.billingAddress,
            "billing_email": formValues.billingEmail
        }
        return payload
    }

    const MakePayment = async () => {
        try {

            const payload = getPayload()

            setConfirmPayment(false)

            // payment method not given yet
            if (!cardDetails) {
                setIsLoading(true)
                const res = await callYoriApi("/payment/", "POST", { body: JSON.stringify(payload) })
                if (res.status == 200) {
                    navigate("/wallet", { state: { showAlert: true, message: res?.message } })
                }
                else {
                    console.log("error :", res)
                    notify(res?.error, "error")
                }
                setIsLoading(false)

            }
            else if (cardDetails && isPaymentMethodAlreadyGiven) {
                setIsLoading(true)
                const res = await callYoriApi("/payment/charge", "POST", { body: JSON.stringify({ amount: payload.amount }) })

                if (res.status == 200) {
                    navigate("/wallet", { state: { showAlert: true, message: res?.message } })
                }
                else {
                    await getPaymentMethod()
                    notify(res?.error, "error")
                }
                setIsLoading(false)

            }
        } catch (error) {
            console.log("Error :", error?.message)
        }

    }

    const updateCard = async () => {
        try {
            const payload = getPayload()

            setIsLoading(true)
            const res = await callYoriApi("/payment/update", "POST", { body: JSON.stringify(payload) })
            setIsLoading(false)
            setShowUpdateButtons(false)

            if (res.status == 200) {
                // setIsPaymentMethodAlreadyGiven(true)
                await getPaymentMethod()
                notify(res?.message, "success")
                // setShowUpdateButtons(false)
            }
            else {
                await getPaymentMethod()
                notify(res?.error, "error")
            }

        } catch (error) {
            console.log("error :", error?.message)
        }

    }



    const handleCardNumberChange = (e, obj) => {
        if (e.target.value.length <= 16) {
            setIsCardNumberCorrect(false)
            setFormValues(prev => ({ ...prev, ...obj }))
        }
        // else if (e.target.value.length > 16) {
        //     //true case
        //     setIsCardNumberCorrect(true)
        //     setFormValues(prev => ({ ...prev }))
        //     document.getElementById('cardExpiryDate').focus()
        // }
        if (e.target.value.length == 16) {
            document.getElementById('cardExpiryMonth').focus()
        }
        if (e.target.value.length > 16) {
            //true case
            setFormStates(prev => ({ ...prev, [e.target.id]: true }))
            setFormValues(prev => ({ ...prev }))
        }
        updateMonthYearChangeTrigger()
        console.log(`Showing these from card number trigger`, { formValues, formStates, monthYearChangeTrigger, handleChangeTrigger })
    }

    const handleCardExpiryMonthChange = (e, num) => {
        if (e.target.value.length == 1) {
            if (parseInt(e.target.value) == 0 || parseInt(e.target.value) == 1) {
                setFormStates(prev => ({ ...prev, [e.target.id]: false }))
                setFormValues(prev => ({ ...prev, ...num }))
            } else {
                setFormStates(prev => ({ ...prev, [e.target.id]: false }))
                setFormValues(prev => ({ ...prev, [e.target.id]: '' }))
            }
        } else if (e.target.value.length == 2) {

            if (parseInt(e.target.value) > 12) {
                setFormValues(prev => ({ ...prev }))
            } else if (parseInt(e.target.value) <= 12) {
                setFormValues(prev => ({ ...prev, ...num }))

                if (parseInt(formValues?.cardExpiryYear) > parseInt(todayYear)) {
                    setFormStates(prev => ({ ...prev, [e.target.id]: true }))
                    setFormStates(prev => ({ ...prev, cardExpiryYear: true }))
                } else if (parseInt(formValues?.cardExpiryYear) == parseInt(todayYear)) {
                    if (parseInt(e.target.value) >= todayMonth) {
                        setFormStates(prev => ({ ...prev, [e.target.id]: true }))
                        setFormStates(prev => ({ ...prev, cardExpiryYear: true }))
                    } else {
                        setFormStates(prev => ({ ...prev, [e.target.id]: false }))
                    }
                }
                document.getElementById('cardExpiryYear').focus()
            }
        } else if (e.target.value == '') {
            setFormValues(prev => ({ ...prev, [e.target.id]: '' }))
            setFormStates(prev => ({ ...prev, [e.target.id]: false }))
        }
        // updateMonthYearChangeTrigger()
    }

    const handleCardExpiryYearChange = (e, num) => {
        if (e.target.value.length < 2) {
            setFormStates(prev => ({ ...prev, [e.target.id]: false }))
            setFormValues(prev => ({ ...prev, ...num }))
        } else if (e.target.value.length == 2) {
            setFormValues(prev => ({ ...prev, ...num }))
            if (parseInt(e.target.value) >= parseInt(todayYear)) {
                setFormStates(prev => ({ ...prev, [e.target.id]: true }))
                if (parseInt(formValues?.cardExpiryMonth) >= parseInt(todayMonth)) {
                    setFormStates(prev => ({ ...prev, cardExpiryMonth: true }))
                } else {
                    setFormStates(prev => ({ ...prev, cardExpiryMonth: false }))
                }
            } else {
                setFormStates(prev => ({ ...prev, [e.target.id]: false }))
            }
            document.getElementById('cardCvc').focus()
        } else if (e.target.value.length > 2) {
            document.getElementById('cardCvc').focus()
        }
        // updateMonthYearChangeTrigger()
    }

    const handleOnCountryChange = () => {
        const valueToSend = document.getElementById('country').value
        console.log(valueToSend)
        setFormValues(prev => ({ ...prev, country: valueToSend }))
        setFormStates(prev => ({ ...prev, country: true }))
        setHandleChangeTrigger(prev => prev += 1)
    }



    const handleOnChangeFormValues = (e) => {

        let obj = { [e?.target?.id]: e?.target?.value }
        let num = { [e?.target?.id]: customOnlyNumberOnChange(e?.target?.value) }
        if (e.target.id == 'amount') {
            handleAmountChange(e, num)
        }
        else if (e.target.id == 'cardNumber') {
            handleCardNumberChange(e, num)
        }
        else if (e.target.id == 'cardExpiryMonth') {
            handleCardExpiryMonthChange(e, num)
        }
        else if (e.target.id == 'cardExpiryYear') {
            handleCardExpiryYearChange(e, num)
        }
        else if (e.target.id == 'cardCvc') {
            handleCvcChange(e, num)
        }
        else {
            setFormValues(prev => ({ ...prev, ...obj }))
            handleFormStateChange(e)
        }
        // await awaitfunction(300)
        setHandleChangeTrigger(prev => prev += 1)
    }

    // useEffect(() => {
    //     console.log('monthYearChangeTrigger above here' , monthYearChangeTrigger)
    //     if (monthYearChangeTrigger != 0) {
    //         if ((parseInt(todayYear) < parseInt(formValues?.cardExpiryYear) || ((parseInt(todayYear) == parseInt(formValues?.cardExpiryYear)) && (parseInt(formValues?.cardExpiryMonth) >= parseInt(todayMonth))))) {
    //             setFormStates(prev => ({ ...prev, cardExpiryYear: true }))
    //             setFormStates(prev => ({ ...prev, cardExpiryMonth: true }))
    //         } else {
    //             console.log('here ????')
    //             setFormStates(prev => ({ ...prev, cardExpiryYear: false }))
    //             setFormStates(prev => ({ ...prev, cardExpiryMonth: false }))
    //         }
    //         if (formValues?.cardCvc.length != 3) {
    //             setFormStates(prev => ({ ...prev, cardCvc: false }))
    //         }

    //         console.log('formStates', formStates)
    //     }
    // }, [handleChangeTrigger])


    const changeCard = () => {
        console.log('cardDetails', cardDetails)
        setFormValues(prev => ({
            ...prev,
            cardNumber: '',
            cardExpiryMonth: '',
            cardExpiryYear: '',
            cardCvc: '',
            cardholderName: cardDetails.cardholder_name,
            companyName: cardDetails.company_name,
            billingEmail: cardDetails.billing_email,
            billingAddress: cardDetails.billing_address,
            city: cardDetails.city,
            zipCode: cardDetails.zipcode,
            country: cardDetails.country,
        }))
        setIsPaymentMethodAlreadyGiven(false)
        setShowUpdateButtons(true)
        // awaitfunction(1000)
        // console.log("formValues :", formValues)
        // console.log("formStates :", formStates)
    }

    const errorStyle = {
        border: "1px solid red",
        borderRadius: "3px"
    }
    const isSubmitDisabled = (formValues.amount == '' || parseInt(formValues.amount) < 5) || showUpdateButtons || (!isPaymentMethodAlreadyGiven && !collectiveTrueState)



    const ConfirmResponse = () => {
        setOpenModal(true);
    }

    useEffect(() => {
        if (confirmPayment) {
            MakePayment()
        }
    }, [confirmPayment])

    return (
        <div
            className={`topUp ${isLoading && "dim"}`}
        >

            <ConfirmationModal open={openModal} setConfirmPayment={setConfirmPayment} amount={formValues?.amount} card={formValues?.cardNumber?.slice(12) || (cardDetails && cardDetails?.card_number?.slice(12))}
                onClose={() => { setOpenModal(false); }} />
            <CustomToastContainer />
            <MainHeader />
            <div
                className='topUpMainContainer'
            >
                <div
                    className='topUpLeft'
                >
                    <div
                        className='navigationButton'
                    >
                        <span
                            onClick={onGoBack}
                        >
                            <img src={fullLeftArrow} />
                        </span>
                        <h2>
                            Wallet top up
                        </h2>
                    </div>
                    <div
                        className='topUpForm'
                    >
                        <div>
                            <p>
                                What amount do you want to top your wallet up with?
                            </p>
                            <input
                                style={formStates?.amount ? {} : errorStyle}
                                min={5}
                                value={formValues?.amount}
                                id='amount'
                                className='inputBox'
                                placeholder='Amount'
                                onChange={handleOnChangeFormValues}
                            />

                        </div>
                        {
                            isPaymentMethodAlreadyGiven ? <>
                                {/* Payment method already given */}
                                <div className='saved_methods'>
                                    <h3>Payment Method</h3>
                                    <div className='payment_card'>
                                        <div>
                                            <img src={(cardType === "visa" && visalogo) || (cardType === "mastercard" && circleslogo) || (cardType === "amex" && americanexpresslogo)} />
                                            <div>
                                                <p>{hideCardNumber(cardDetails?.card_number)}</p>
                                                <p>{cardDetails?.cardholder_name}</p>
                                                <p>{`${cardDetails?.expiry_mm} / ${cardDetails?.expiry_yy}`}</p>
                                            </div>
                                        </div>
                                        <PrimaryButton
                                            style={{ fontSize: "16px" }}
                                            variant='Beta'
                                            onClick={changeCard}
                                        >
                                            Change
                                        </PrimaryButton>
                                    </div>

                                </div>

                            </> :
                                <>
                                    {/* Payment method not given yet */}

                                    <div>
                                        <span
                                            className='paymentMethod'
                                        >
                                            <p>
                                                Payment Method
                                            </p>
                                            <img src={stripeLocked} />
                                        </span>
                                        <span
                                            className='cardDetails'
                                            style={(formStates?.cardNumber && formStates?.cardCvc && formStates?.cardExpiryMonth && formStates?.cardExpiryYear) ? {} : errorStyle}
                                        >
                                            <input
                                                // style={stateOfInputs?.cardNumber ? {} : {...errorStyle , borderRight: 'transparent'}}
                                                id='cardNumber'
                                                className='inputBox cardNumber'
                                                placeholder='Card Number'
                                                value={formValues?.cardNumber}
                                                onChange={handleOnChangeFormValues}
                                            />
                                            <input
                                                // style={stateOfInputs?.cardExpiryDate ? {} : {...errorStyle , borderRadius: '0px' ,  borderRight: 'transparent' , borderLeft: 'transparent'}}
                                                id='cardExpiryMonth'
                                                className='inputBox cardExpiryMonth'
                                                placeholder='MM'
                                                value={formValues?.cardExpiryMonth}
                                                onChange={handleOnChangeFormValues}
                                            />
                                            <p>/</p>
                                            <input
                                                // style={stateOfInputs?.cardExpiryDate ? {} : {...errorStyle , borderRadius: '0px' ,  borderRight: 'transparent' , borderLeft: 'transparent'}}
                                                id='cardExpiryYear'
                                                className='inputBox cardExpiryYear'
                                                placeholder='YY'
                                                value={formValues?.cardExpiryYear}
                                                onChange={handleOnChangeFormValues}
                                            />
                                            <input
                                                // style={stateOfInputs?.cardCvc ? {} : {...errorStyle, borderLeft: 'transparent'}}
                                                value={formValues?.cardCvc}
                                                id='cardCvc'
                                                className='inputBox cardCvc'
                                                placeholder='CVC'
                                                onChange={handleOnChangeFormValues}
                                            />
                                        </span>
                                        <input
                                            style={formStates?.cardholderName ? {} : errorStyle}
                                            id='cardholderName'
                                            className='inputBox'
                                            placeholder='Cardholder Name'
                                            value={formValues.cardholderName}

                                            onChange={handleOnChangeFormValues}
                                        />
                                    </div>
                                    <div>
                                        <p>Billing Details</p>
                                        <input
                                            style={formStates?.companyName ? {} : errorStyle}
                                            id='companyName'
                                            className='inputBox'
                                            value={formValues.companyName}

                                            placeholder='Company Name'
                                            onChange={handleOnChangeFormValues}
                                        />
                                        <input
                                            style={formStates?.billingEmail ? {} : errorStyle}
                                            id='billingEmail'
                                            className='inputBox'
                                            placeholder='Billing Email'
                                            value={formValues.billingEmail}

                                            onChange={handleOnChangeFormValues}
                                        />
                                        <input
                                            style={formStates?.billingAddress ? {} : errorStyle}
                                            id='billingAddress'
                                            className='inputBox'
                                            placeholder='Billing Address'
                                            value={formValues.billingAddress}

                                            onChange={handleOnChangeFormValues}
                                        />
                                        <div >
                                            <input
                                                style={formStates?.city ? {} : errorStyle}
                                                id='city'
                                                className='inputBox halfInputBox'
                                                value={formValues.city}

                                                placeholder='City'
                                                onChange={handleOnChangeFormValues}
                                            />
                                            <input
                                                style={formStates?.zipCode ? {} : errorStyle}
                                                id='zipCode'
                                                className='inputBox halfInputBox'
                                                value={formValues.zipCode}

                                                placeholder='Zip Code'
                                                onChange={handleOnChangeFormValues}
                                            />
                                        </div>
                                        <CountryDropdown
                                            style={formStates?.country ? {} : errorStyle}
                                            id='country'
                                            className="countryDropdown"
                                            value={formValues?.country}
                                            onChange={handleOnCountryChange}
                                        />

                                    </div>
                                    {/* update card buttons */}
                                    {showUpdateButtons
                                        && <div className='update_card_buttons'>
                                            <button onClick={() => { setIsPaymentMethodAlreadyGiven(true); setShowUpdateButtons(false) }} className='cancel_btn'>Cancel</button>
                                            <button onClick={updateCard} disabled={!trueStateUpdateCard} style={!trueStateUpdateCard ? { cursor: "not-allowed" } : {}} className='update_btn'>Update Card</button>
                                        </div>
                                    }

                                </>
                        }
                    </div>
                </div>

                <div
                    className='topUpRight'
                >
                    <div className='payment_summary'>
                        <span>
                            <h2>
                                Top Up
                            </h2>
                            <h1>
                                ${formValues?.amount ? formValues.amount : 0}
                            </h1>
                        </span>
                        <p>
                            Credited Immediately
                        </p>
                        <div
                            className='buttonContainer'
                            onClick={ConfirmResponse}
                            style={collectiveTrueState ? {} : { cursor: "not-allowed" }}
                        >
                            <PrimaryButton disabled={isSubmitDisabled} style={{ width: "100%", justifyContent: "center", borderRadius: "100px" }} >
                                Pay With Card
                            </PrimaryButton>
                        </div>
                        <div className='footer_images'>
                            <img src={visalogo} />
                            <img src={circleslogo} />
                            <img src={americanexpresslogo} />
                        </div>

                    </div>
                </div>
            </div>
            {isLoading && <LoadingSpinner />}

        </div>
    )
}

export default TopUp