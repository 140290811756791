import * as ActionTypes from "../../actions/lists";

const initialState = {
    companylists:[]
};

export const companylists = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_COMPANYLIST:
      return {
        ...state,
        companylists:[...action.payload],
      };
    case ActionTypes.SET_COMPANIES_CHECKED:
    return {
        ...state,
        companylists:state.companylists.map(list=>{return {...list,check:action.payload}}),        
    }
    case ActionTypes.SET_COMPANY_CHECKED:
    return {
        ...state,
        companylists:state.companylists.map(list=>{
        if(list.id==action.payload.id) 
            list.check=action.payload.check
        return {...list}}),        
    }

    default:
      return state;
  }
};
