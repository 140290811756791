import React, { useEffect } from 'react'
import SelectBox from '../SelectBox'
import { BarChart } from './BarChart'
import { LineChart } from './LineChart'
import "./style.scss"

function GroupChartBox({ data, mainTitle, color, barChartLabels, chartType = "barChart", type, onSelect = () => false }) {

    //     profileViews
    // contacts_views
    // company_views
    // new_subscriptions

    // console.log("Console log with this written" , {chartType , data})
    useEffect(() => {
        if (chartType == 'lineChart') {
            console.log("Console log with this written", { chartType, data })
        }
    }, [])


    return (
        <div className='BarChartBox'>
            <div className='chartHead'>
                <span>{mainTitle}</span>
                {
                    type && <SelectBox options={["Today", "This Week", "This Month"]} id={1} type={type} onSelect={onSelect} />
                }
            </div>
            {
                chartType == 'lineChart'
                    ?
                    <span>
                        <p className='xl'>Total Counts :</p>
                        <p className='xl yori_xl'>Yori-{data?.yoriTotal}</p>
                        <p className='xl li_xl'>Li-{data?.liTotal}</p>
                    </span>
                    :
                    <span className='xl'>{data?.total}</span>
            }
            <div style={{ paddingTop: "1rem", width: "100%" }}>
                {
                    chartType == 'barChart' &&
                    <BarChart values={data?.barChartData} color={color} barChartLabels={data?.labels} />
                }

                {
                    chartType == 'lineChart' &&
                    <LineChart values1={data.line1Data} values2={data.line2Data} color1={color} color2='rgb(23, 169, 143)' labels={data?.labels} />
                }
            </div>
        </div>
    )
}

export default GroupChartBox