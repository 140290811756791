import * as ActionTypes from "../../actions/referrals"

const initialSelectedUserContacts = {
  isSent: false,
  isSending: false,
  hasFailed: false,
}
const initialState = {
  userContacts: [],
  selectedUserContacts: [],
  messagesProgress: [],
  // userContacts: [],
  // selectedUserContacts: [],
  isLoading: false,
  isLoaded: false,
  isDataScrapped: false,
  showMessagesSendingCards: false,
}



export const userContacts = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_CONTACTS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        userContacts: [...state.userContacts, ...action.payload]
      };

    case ActionTypes.SET_IS_USER_CONTACTS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case ActionTypes.SET_IS_USER_CONTACTS_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      };

    case ActionTypes.SET_IS_USER_CONTACTS_SCRAPPED:
      return {
        ...state,
        isDataScrapped: action.payload
      };

    case ActionTypes.ADD_SELECTED_USER_CONTACT:
      let check = false

      state.selectedUserContacts.map(({ id } , ind) => {

        if (id == action.payload || state.selectedUserContacts[ind].is_referal_sent) {
          check = true
        }
      })
      if (!check) {
        return {
          ...state,
          selectedUserContacts: [...state.selectedUserContacts, ...(state.userContacts.filter(({ id }) => id === action.payload).map(itm => ({ ...itm, ...initialSelectedUserContacts })))]
        };
      }
      else {
        return state
      }


    case ActionTypes.REMOVE_SELECTED_USER_CONTACT:
      return {
        ...state,
        selectedUserContacts: state.selectedUserContacts.filter((({ id }) => action.payload !== id))
      };
    case ActionTypes.INITIALIZE_SELECTED_USER_CONTACT:
      return {
        ...state,
        selectedUserContacts: []
      };

    case ActionTypes.UPDATE_SELECTED_USER_CONTACT:
      return {
        ...state,
        selectedUserContacts: state.selectedUserContacts.map(itm => action.payload.id === itm.id ? { ...itm, ...action.payload.value } : itm)
      }

    case ActionTypes.UPDATE_USER_CONTACT_STATE:
      state.userContacts.map((profile,ind) => {
        if(profile.id === action.payload){
            state.userContacts[ind].is_referal_sent = true
        }
      })
      return {
        ...state
      }

    case ActionTypes.ADD_INVITATION_SENDING_CHECK:
      return {
        ...state,
        selectedUserContacts: state.selectedUserContacts.map(contact => contact.id === action.payload ? {...contact , "is_in_sending_flow": true} : contact),
        userContacts: state.userContacts.map(contact => contact.id === action.payload ? {...contact , "is_in_sending_flow": true} : contact)
      }

    case ActionTypes.REMOVE_INVITATION_SENDING_CHECK:
      return {
        ...state,
        selectedUserContacts: state.selectedUserContacts.map(contact => contact.id === action.payload ? {...contact , "is_in_sending_flow": false} : contact),
        userContacts: state.userContacts.map(contact => contact.id === action.payload ? {...contact , "is_in_sending_flow": false} : contact)
      }

    case ActionTypes.SET_MESSAGE_SENDING_STATE:
      return {
        ...state,
        selectedUserContacts: state.selectedUserContacts.map(contact => contact.id === action.payload.id ? {...contact , "messageSendingState": action.payload.value} : contact),
      }
  
    case ActionTypes.REMOVE_MESSAGE_SENDING_STATE:
      return {
        ...state,
        selectedUserContacts: state.selectedUserContacts.map(contact => contact.id === action.payload.id ? {...contact , "messageSendingState": 0} : contact),
      }

    case ActionTypes.SET_MESSAGES_PROGRESS:
      return {
        ...state,
        messagesProgress: action.payload
      };

    case ActionTypes.RESET_MESSAGES_PROGRESS:
      return {
        ...state,
        messagesProgress: initialState?.messagesProgress
      };

    case ActionTypes.SET_SHOW_MESSAGE_SENDING_CARDS:
      return {
        ...state,
        showMessagesSendingCards: action.payload
      };

    case ActionTypes.REINITIATE_SELECTED_USER_CONTACTS:
      const selectedToPush = {...state.userContacts.filter((contact) => contact.id === action.payload)[0] , "is_in_sending_flow": true }
      console.log("selected to push ::::" , selectedToPush)
      return {
        ...state,
        userContacts: state.userContacts.map(contact => contact.id === action.payload ? {...contact , "is_in_sending_flow": true} : contact),
        selectedUserContacts: [...state.selectedUserContacts, selectedToPush ],
        // selectedUserContacts: state.selectedUserContacts.map(contact => contact.id === action.payload ? {...contact , "is_in_sending_flow": true} : contact),
      };


      
    default:
      return state;
  }
};