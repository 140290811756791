import React, { useEffect, useState } from 'react'
import "./style.scss"
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

import ProfileImageComp from '../../../../components/ProfileImageComp'
import leftarrow from "../../../../../assets/icons/leftarrow.svg"
import rightarrow from "../../../../../assets/icons/rightarrow.svg"
import { useNavigate } from 'react-router';


function UsersTable({ 
    data,
    search = '' 

}) {
    const navigate = useNavigate()
    // console.log("data :", data)
    const [tableData, setTableData] = useState(data)
    
        const [pageData, setPageData] = useState([])
        let [totalPages, setTotalPages] = useState(0)
        let [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        if (data) {
            setTableData(data)
        }
    }, [data])


    const nextPage = () => {
        if (currentPage < totalPages) {
            setPageData(tableData.slice(currentPage * 15, (currentPage + 1) * 15)) 
            setCurrentPage(currentPage + 1)
        }
    }
    const previousPage = () => {
        if (currentPage != 1) {
            setPageData(tableData.slice((currentPage - 2) * 15, (currentPage - 1) * 15))
            setCurrentPage(currentPage - 1)
        }
    }

    useEffect(() => {
        setTotalPages(Math.ceil(tableData?.length / 15))
        if(search != ''){
            setCurrentPage(1)
        }
        setPageData(tableData.slice((currentPage - 1) * 15, currentPage * 15))
    }, [tableData])

    return (
        <div className="usersTable">

            <MDBTable className="table">
                <MDBTableHead className="tableHead">
                    <tr>
                        <th scope='col'>User ID</th>
                        <th scope='col'>User Name</th>
                        <th scope='col'>Email</th>
                        <th scope='col'>Credits</th>
                        <th scope='col'>Cash</th>
                        <th scope='col'>Invites sent</th>
                        <th scope='col'>Invites Converted</th>
                        <th scope='col' style={{ textAlign: "left" }}>Date Created</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody className='usersTableBody'>
                    {
                        pageData?.length > 0 && pageData?.map((row, id) => {
                            return <tr className='tableRowUsers' onClick={() => navigate("/admin/dashboard/user-profile", { state: { user: row , id } })}>
                                <td>{row?.id}</td>
                                <td>
                                    <div className='userName'>
                                        <ProfileImageComp
                                            key = {id} 
                                            name={row?.first_name?.trim() + ' ' + row?.last_name}
                                            src={row?.picture || ''}
                                            id={row?.id}
                                            width="24px"
                                            height="24px"
                                            fontSize="10px"
                                        />
                                        <p className='fw-bold mb-1'>{row?.first_name?.trim() + ' ' + row?.last_name}</p>
                                    </div>
                                </td>
                                <td>
                                    <p className='text-muted mb-0'>{row?.email}</p>
                                </td>
                                <td>
                                    <p className='fw-normal mb-1'>{row?.credits}</p>
                                </td>
                                <td>
                                    <p className='fw-normal mb-1'>{(row?.doller_credits)?.toFixed(2)}</p>

                                </td>
                                <td>
                                    <p className='fw-normal mb-1'>{row?.sent}</p>
                                </td>
                                <td>
                                    <p className='fw-normal mb-1'>{row?.accepted}</p>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                    <p className='fw-normal mb-1'>{(new Date((row?.created_at)))?.toLocaleString()}</p>
                                </td>
                            </tr>

                        })
                    }
                </MDBTableBody>
            </MDBTable>
            <div className='table_footer'>
                <div>
                    {(currentPage - 1) * 15} - {(currentPage * 15) < tableData?.length ? (currentPage * 15) : tableData?.length} of {tableData?.length}
                </div>


                <div className='table_footer_actions'>
                    <img src={leftarrow} onClick={previousPage} />
                    <span>Page {totalPages != 0 ? currentPage : currentPage - 1} of {totalPages}</span>
                    <img src={rightarrow} onClick={nextPage} />
                </div >
            </div>

        </div >
    )
}

export default UsersTable