import React from 'react'
import "./style.scss"
import yoriSvg from "../../img/Yori.svg"
import yoriLogoSvg from "../../img/YoriLogo.svg"
import yoriLogoWhite from "../../img/YoriLogoWhite.svg"
import { useNavigate } from 'react-router'
const Footer = () => {
    const push = useNavigate()
  return (
    <div
        className='footer'
    >
        <div>
            <span>
                {/* <img
                    onClick={e=>push("/")}
                    src={yoriLogoSvg}
                />
                <img
                    onClick={e=>push("/")}
                    src={yoriSvg}
                />
                 */}
                <img
                    onClick={e=>push("/")}
                    src={yoriLogoWhite}
                />
            </span>
            <span
                className='privacyPolicyLink'
            >
                <a
                    href="/privacy-policy"
                >
                    Privacy Policy
                </a>
            </span>
        </div>
    </div>
  )
}

export default Footer