import React from 'react'
import SelectBox from '../SelectBox'
import "./style.scss"

function DetailsBox({ width = "178px", title, value, select = false }) {
    return (
        <div className='detailsBox' style={{ width, height: "80px" }}>
            <div>
                <p className='title'>{title}</p>
                {
                    select && <SelectBox options={["Today", "Weekly", "Monthly"]} />
                }

            </div>
            <p className='value'>{value}</p>
        </div>
    )
}

export default DetailsBox