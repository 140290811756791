import React from 'react'
import "./style.scss"

import arrow from "../../../../assets/icons/downarrow.svg"
import { setTimeString } from '../../../../utils'

function WalletTransactions({ transactions }) {
    return (
        <div className='wallet_transactions'>
            <h3>Wallet Transactions</h3>

            {
                transactions?.map(transaction => {
                    return <div>
                        <img src={arrow} style={!transaction?.is_debit ? { rotate: "180deg" } : {}} />
                        <div>
                            <span>
                                {transaction.source != "SUBSCRIPTION" &&
                                    (transaction.source === "REFERRAL_SENT" && transaction.source_id)
                                }

                                {`${((transaction.source == "REFERRAL_ACCEPTED") && " LinkedIn referral accepted reward") ||
                                    ((transaction.source == "REFERRAL_SENT") && " linkedin referral sent reward") ||
                                    ((transaction.source === 'REFERRAL_USED') && " You signed up using referral" || " Subscription Upgrade")}`}
                            </span>
                            <span>{transaction.source == "SUBSCRIPTION" && "-"} {transaction.source == "SUBSCRIPTION" && '$'} {transaction?.amount}  {transaction.source != "SUBSCRIPTION" && ' credits'}</span>
                            <span>{setTimeString(transaction.created_at)}</span>
                        </div>
                    </div>
                })
            }
            {/* <div>
                <img src={arrow} />
                <div>
                    <span>10 email Invites reward</span>
                    <span>$59</span>
                    <span>Sept 12, 2021</span>
                </div>
            </div>
         */}
        </div>
    )
}

export default WalletTransactions