import React, { useState } from 'react'

import linkedin from "../../assets/icons/linkedin.svg"
import deletelogo from "../../assets/icons/delete.svg"
import userpic from "../../assets/icons/user.svg"
import "./style.scss"
import { useDispatch } from 'react-redux'
import { setSingleUserChecked } from '../../actions/lists'

function UserTableRow({ row, functions, countSelected }) {

    const dispatch = useDispatch()
    const { editTitle, deleteRow } = functions
    const [check, setCheck] = useState(false)


    const controlCheck = (check, id) => {
        setCheck(check)
        dispatch(setSingleUserChecked({ check, id }))
        countSelected()
    }
    console.log("User Row-----:", row.id)
    return (
        <div className='user_table_row'>
            <input type="checkbox" className="check" checked={row.check} onClick={(e) => controlCheck(e.target.checked, row.id)} />
            <div className='row'>
                <div className='listname' onClick={() => controlCheck(!check, row.id)}> <img src={row.picture_url} /> {row.name}</div>
                <span className='title'>{row.title}</span>
                {
                    row?.company_name ? <div className='company'> <img src={row?.logo ? row?.logo : row?.li_logo || ""} /> {row.company_name}</div>
                        : <div className='company'>-----</div>
                }
                <span className='location'>{row.location} </span>
                <div className='contacts'>
                    {
                        row?.email && <span><span style={{ marginRight: "7px", paddingLeft: "0" }}>Email</span > {row.email || ""} </span>
                    }
                    {
                        row?.work_email && <span><span style={{ marginRight: "9px", paddingLeft: "0" }}>Work</span> {row.work_email || ""} </span>
                    }
                    {
                        row?.phone && row.phone != "null" && <span><span style={{ marginRight: "1px", paddingLeft: "0" }}>Phone</span> {row?.phone || ""} </span>
                    }

                </div>
            </div>
            <div className='row_btns'>
                <img src={deletelogo} onClick={() => deleteRow(row.id)} />
                <a href={`https://www.linkedin.com/in/${row.linkedin_identifier}`} target="_blank"> <img src={linkedin} /> </a>
                <a href={`https://www.linkedin.com/in/${row.linkedin_identifier}`} target="_blank"> <button className='viewbtn'>View</button></a>
            </div>
        </div>
    )
}

export default UserTableRow