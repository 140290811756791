import React, { useEffect, useState } from 'react'
import MainHeader from '../../../../components/MainHeader'
import "./style.scss"
import fullLeftArrow from "../../../../assets/icons/arrow-right.svg"
import PrimaryButton from '../../../../components/PrimaryButton'
import visalogo from "../../../../assets/icons/Visalogo.svg"
import americanexpresslogo from "../../../../assets/icons/americanexpress.svg"
import circleslogo from "../../../../assets/icons/twocircles.svg"
import stripeLocked from "../../../../img/StripeLocked.svg"
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { setRewardBalance } from '../../../../actions/referrals'
import { awaitfunction, calculateIncentive, callYoriApi, customOnlyNumberOnChange, detectCardType, getNewMonthCurDate, notify, packages } from '../../../../utils'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import RedNotMarkCircle from '../../../../components/SvgComponents/RedNotMarkCircle'
import { ToastContainer, toast } from 'react-toastify';
import CustomToastContainer from '../../../../components/ToastContainer'
import TopUp from '../TopUp'
import { CountryDropdown } from 'react-country-region-selector'
import { useSelector } from 'react-redux'
import ConfirmationModal from '../ConfirmationModal'



function SubscriptionForm() {

    const [flag, setFlag] = useState(false)
    const navigate = useNavigate()

    const [useWallet, setUseWallet] = useState(false)
    const [autoRenew, setAutoRenew] = useState(false)


    const state = useLocation()?.state
    const active = state?.active
    const credits = useSelector(state => state?.auth?.wallet?.credits)


    let [formValues, setFormValues] = useState({
        // amount: '',
        cardNumber: '',
        cardExpiryMonth: '',
        cardExpiryYear: '',
        cardCvc: '',
        cardholderName: '',
        companyName: '',
        billingEmail: '',
        billingAddress: '',
        city: '',
        zipCode: '',
        country: ''
    })

    const [formStates, setFormStates] = useState({
        // amount: true,
        cardNumber: true,
        cardExpiryMonth: true,
        cardExpiryYear: true,
        cardCvc: true,
        cardholderName: true,
        companyName: true,
        billingEmail: true,
        billingAddress: true,
        city: true,
        zipCode: true,
        country: true,
    })

    const _ = require('lodash')
    const todayDate = new Date()
    const todayYear = _.toString(todayDate.getFullYear()).slice(2, 4)
    const todayMonth = _.toString(todayDate.getMonth() + 1)

    const [isAmountCorrect, setIsAmountCorrect] = useState(false)
    const [isCvcCorrect, setIsCvcCorrect] = useState(false)
    const [isCardNumberCorrect, setIsCardNumberCorrect] = useState(false)
    const [isExpiryMonthCorrect, setIsExpiryMonthCorrect] = useState(false)
    const [isExpiryYearCorrect, setIsExpiryYearCorrect] = useState(false)


    const [collectiveTrueState, setCollectiveTrueState] = useState(false)
    const [trueStateUpdateCard, setTrueStateUpdateCard] = useState(false)
    const [handleChangeTrigger, setHandleChangeTrigger] = useState(0)
    const [monthYearChangeTrigger, setMonthYearChangeTrigger] = useState(0)
    const [confirmPayment, setConfirmPayment] = useState(false)
    const [cardDetails, setCardDetails] = useState()
    const [cardType, setCardType] = useState()
    const [isPaymentMethodAlreadyGiven, setIsPaymentMethodAlreadyGiven] = useState(false)
    // const [isPaymentMethodAlreadyGiven, setIsPaymentMethodAlreadyGiven] = useState(false)
    const [showUpdateButtons, setShowUpdateButtons] = useState(false)
    const [isLoading, setIsFormLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const isSubmitDisabled =
        // (formValues.amount == '' || parseInt(formValues.amount) < 5) || 
        showUpdateButtons ||
        (!isPaymentMethodAlreadyGiven && !collectiveTrueState)
    // !collectiveTrueState

    const handleSetDateTrue = () => {
        setFormStates(prev => ({ ...prev, cardExpiryYear: true, cardExpiryMonth: true }))
        // setFormStates(prev => ({ ...prev, cardExpiryMonth: true }))
        // console.log(`:::::::::::::::::::inside the setdatetrue function `, formStates)
    }
    const handleSetDateFalse = () => {
        setFormStates(prev => ({ ...prev, cardExpiryYear: false, cardExpiryMonth: false }))
        // setFormStates(prev => ({ ...prev, cardExpiryYear: false }))
        // setFormStates(prev => ({ ...prev, cardExpiryMonth: false }))
        // console.log(`______________inside the setdatefalse function `, formStates)
    }



    const updateMonthYearChangeTrigger = () => {
        setMonthYearChangeTrigger(prev => prev += 1)
    }

    const handleFormStateChange = (e) => {
        if (e.target.value.length == 0) {
            setFormStates(prev => ({ ...prev, [e.target.id]: false }))
        } else {
            setFormStates(prev => ({ ...prev, [e.target.id]: true }))
        }
    }

    const getPayload = () => {

        const payload = {
            "cardholder_name": formValues.cardholderName,
            "number": formValues.cardNumber,
            "expiry_mm": parseInt(formValues?.cardExpiryMonth),
            "amount": parseInt(formValues.amount),
            "expiry_yy": parseInt(formValues?.cardExpiryYear),
            "cvc": formValues.cardCvc,
            "city": formValues.city,
            "zipcode": formValues.zipCode,
            "country": formValues.country,
            "company_name": formValues.companyName,
            "billing_address": formValues.billingAddress,
            "billing_email": formValues.billingEmail
        }
        return payload
    }


    const updateCard = async () => {
        try {
            const payload = getPayload()

            setIsPageLoading(true)
            const res = await callYoriApi("/payment/update", "POST", { body: JSON.stringify(payload) })
            setIsPageLoading(false)
            setShowUpdateButtons(false)

            if (res.status == 200) {
                // setIsPaymentMethodAlreadyGiven(true)
                notify(res?.message, "success")
                await getPaymentMethod()
                // setShowUpdateButtons(false)
            }
            else {
                notify(res?.error, "error")
                await getPaymentMethod()
            }

        } catch (error) {
            console.log("error :", error?.message)
        }

    }







    const [current_subscription_id, setCurrentSubscriptionId] = useState()
    const [subscriptionDateTime, setSubscriptionDateTime] = useState()
    const [contacts_remaining, setContactsRemaining] = useState()

    const [select, setSelect] = useState(active)

    const [subscription_selected, setSubcriptionSelected] = useState({})

    const [availableRewardBalance, setAvailableRewardBalance] = useState(0)

    const [isPageLoading, setIsPageLoading] = useState(false)

    const [insufficientAmountInWallet, setInsufficientAmountInWallet] = useState(false)
    const [amountNotAvailableInWallet, setAmountNotAvailableInWallet] = useState(0)
    const [isInitializing, setIsInitializing] = useState(false)
    const initializeData = async () => {
        try {
            setIsInitializing(true)
            const result = await callYoriApi("/wallet/credits", "GET")
            // console.log("Credits :", { result })
            setAvailableRewardBalance(result?.doller_credits)
            setCurrentSubscriptionId(result.subscription_id)
            setSubscriptionDateTime(result.subscription_updated_at)
            setContactsRemaining(result.credits)
            setIsInitializing(false)
            getPaymentMethod()

        } catch (error) {
            console.log("Error :", error?.message)
            setIsInitializing(false)

        }
    }

    useEffect(() => {
        initializeData()
        // console.log(`=========insufficientAmountInWallet==============`, insufficientAmountInWallet)
    }, [])










    const getPaymentMethod = async () => {
        try {
            setIsFormLoading(true)
            const res = await callYoriApi("/payment/", "GET")
            // console.log("See that we entered here again", { res })
            if (res?.status == 200) {

                // console.log('res?.card', res?.card)
                setCardDetails(res?.card)
                setIsPaymentMethodAlreadyGiven(true)
                setCardType(detectCardType(5555555555554444))

                // 378282246310005  amex
                // 4111111111111111  visa card
                // 5555555555554444  master card ex
                // res?.card.card_number
            }
            setIsFormLoading(false)
        } catch (error) {
            console.log(error?.message)
        }
    }

    useEffect(() => {

        if (!isInitializing) {
            if (availableRewardBalance - (subscription_selected.cost - prorated_amount) < 0) {
                setInsufficientAmountInWallet(true)
                setAmountNotAvailableInWallet((subscription_selected.cost - availableRewardBalance - prorated_amount).toFixed(2))
            }
            else {
                setInsufficientAmountInWallet(false)
                setAmountNotAvailableInWallet(0)
            }
        }
    }, [subscription_selected, isInitializing])

    useEffect(() => {
        // console.log("Package :", select)
        setSubcriptionSelected(
            {
                contacts: packages[select].contacts,
                cost: packages[select].amount,
                subcribe_package_id: packages[select].subscription_id,
                package: select == 1 && "Free" || select == 2 && "Gold" || select == 3 && "Platinum"
            }
        )

    }, [select])

    const subscribePackage = async () => {
        // console.log("===========Subscripe Package===========")
        setIsPageLoading(true)
        try {
            const res = await callYoriApi("/subscription", "POST", {
                body: JSON.stringify(isPaymentMethodAlreadyGiven ?
                    { //body when payment method is available
                        subscription_id: subscription_selected.subcribe_package_id,
                        is_already_customer: true,
                    }
                    :
                    { //body when payment method is not available
                        subscription_id: subscription_selected.subcribe_package_id,
                        is_already_customer: false,
                        ...getPayload()
                    }
                )
            })
            // console.log("Subcribe Page Response :", res)
            if (res?.status == 200) {
                setIsPageLoading(false)
                navigate("/wallet", { state: { showAlert: true, message: res?.message } })
            }
            else {
                // console.log("callled/////////////////", res?.error?.slice(0, 25))
                notify(res?.error, "error")
                setIsPageLoading(false)
            }
            setConfirmPayment(false)
        } catch (error) {
            setIsPageLoading(false)
            console.log("Error :", error?.message)
            notify("Something went wrong!", "error")
            setConfirmPayment(false)
        }
    }

    let [prorated_amount, setProratedAmount] = useState(0)

    const ProratedAmount = () => {
        if (current_subscription_id == 2 && packages[select].subscription_id == 3) {
            // calculateIncentive(,packages[current_subscription_id])
            setProratedAmount(calculateIncentive(contacts_remaining, subscriptionDateTime, packages[current_subscription_id]))
            return <div className='wallet_amount'>
                <p className='text'>Amount prorated from current subscription</p>
                <p >${prorated_amount}</p>
            </div>
        }
        else {
            setProratedAmount(0)
            return <></>
        }
    }

    const TodayDate = ({ invoice_box_message = "" }) => (<p className={`subscription_lightext ${invoice_box_message}`} style={{ margin: "0.8rem 0" }}>
        {
            current_subscription_id > packages[select].subscription_id ?
                `Provided on ${getNewMonthCurDate(parseInt(subscriptionDateTime))} - your billing date`
                : "Provided upfront"
        }
    </p>)

    const DownGradeDate = () => (<p style={{ overflowWrap: "anywhere", fontSize: "20px", width: "70%" }}>
        {
            current_subscription_id > packages[select].subscription_id ?
                `Total (charged on ${getNewMonthCurDate(parseInt(subscriptionDateTime))} - your billing date)
                `
                : "Total (charged today)"
        }
    </p>)


    const ConfirmButtonStyle = {
        width: "100%",
        justifyContent: "center",
        fontSize: "17px",
        marginTop: "1.4rem",
        borderRadius: "100px",
    }



    const getCollectiveTrueState = (states, values) => {
        const newState = states || formStates
        const newValues = values || formValues
        let currentStates = Object.values(newState)
        let stateslength = currentStates.length
        currentStates = currentStates.filter(bool => bool)
        console.log('currentStates', { states, newState })

        let currentValues = Object.values(newValues)
        let valueslength = currentValues.length
        currentValues = currentValues.filter(val => val !== '')
        console.log('currentValues', { values, newValues })
        // let inputLogicCheck = false
        // if(obj?.amountCorrect && obj?.cvcCorrect && obj?.cardNumberCorrect && obj?.expiryDateCorrect ) {
        //     inputLogicCheck = true
        // }

        // console.log("Lengths ---:", currentStates.length, stateslength, currentValues.length, valueslength)
        if (currentStates.length == stateslength && currentValues.length == valueslength) {
            console.log(`All good to go`)
            return (true)
        }
        else {
            return (false)
        }
    }

    // toast("hello world")

    useEffect(() => {
        // console.log('monthYearChangeTrigger above here', monthYearChangeTrigger)
        if (monthYearChangeTrigger != 0) {
            // console.log(`today year and today month `, { todayYear, todayMonth, 'formValues?.cardExpiryYear': formValues?.cardExpiryYear, 'formValues?.cardExpiryMonth': formValues?.cardExpiryMonth })
            if ((parseInt(todayYear) < parseInt(formValues?.cardExpiryYear) || ((parseInt(todayYear) == parseInt(formValues?.cardExpiryYear)) && (parseInt(formValues?.cardExpiryMonth) >= parseInt(todayMonth))))) {
                handleSetDateTrue()
            } else {
                // console.log('=================SET FALSE====================')
                handleSetDateFalse()
            }
            if (formValues?.cardCvc.length != 3) {
                setFormStates(prev => ({ ...prev, cardCvc: false }))
            }

            console.log('formStates', formStates)
        }
        if (handleChangeTrigger != 0) {
            // console.log(`formValues ::::::::` , formValues)
            console.log(`============TRIGGERED FOR setCollectiveTrueState============`, { formStates, formValues })
            setCollectiveTrueState(
                () => getCollectiveTrueState()
            )
            const tempState = { ...formStates }
            const tempValues = { ...formValues }
            delete tempState.amount
            delete tempValues.amount
            // console.log(`TRIGGERED STATES HANDLING FOR UPDATE CARD========================`, { tempState, tempValues })
            setTrueStateUpdateCard(
                () => getCollectiveTrueState(tempState, tempValues)
            )

            // awaitfunction(2000)
            // console.log("trueStateUpdateCard -----:", trueStateUpdateCard, collectiveTrueState)

        }
        // console.log('values displayed here ', { 'formValues.amount': formValues.amount, showUpdateButtons, isPaymentMethodAlreadyGiven, collectiveTrueState })
        // console.log('isSubmitDisabled =============', isSubmitDisabled)

    }, [handleChangeTrigger])


    const hideCardNumber = (number) => {
        return `***********${number?.slice(12)}`
    }

    const changeCard = () => {
        console.log('cardDetails', cardDetails)
        setFormValues(prev => ({
            ...prev,
            cardNumber: '',
            cardExpiryMonth: '',
            cardExpiryYear: '',
            cardCvc: '',
            cardholderName: cardDetails.cardholder_name,
            companyName: cardDetails.company_name,
            billingEmail: cardDetails.billing_email,
            billingAddress: cardDetails.billing_address,
            city: cardDetails.city,
            zipCode: cardDetails.zipcode,
            country: cardDetails.country,
        }))
        setIsPaymentMethodAlreadyGiven(false)
        console.log('did you come here')
        setShowUpdateButtons(true)
        // awaitfunction(1000)
        // console.log("formValues :", formValues)
        // console.log("formStates :", formStates)
    }

    const handleCardNumberChange = (e, obj) => {
        if (e.target.value.length <= 16) {
            setIsCardNumberCorrect(false)
            setFormValues(prev => ({ ...prev, ...obj }))
        }
        // else if (e.target.value.length > 16) {
        //     //true case
        //     setIsCardNumberCorrect(true)
        //     setFormValues(prev => ({ ...prev }))
        //     document.getElementById('cardExpiryDate').focus()
        // }
        if (e.target.value.length == 16) {
            document.getElementById('cardExpiryMonth').focus()
        }
        if (e.target.value.length > 16) {
            //true case
            setFormStates(prev => ({ ...prev, [e.target.id]: true }))
            setFormValues(prev => ({ ...prev }))
        }
        updateMonthYearChangeTrigger()
        console.log(`Showing these from card number trigger`, { formValues, formStates, monthYearChangeTrigger, handleChangeTrigger })
    }

    const handleCardExpiryMonthChange = (e, num) => {
        if (e.target.value.length == 1) {
            if (parseInt(e.target.value) == 0 || parseInt(e.target.value) == 1) {
                setFormStates(prev => ({ ...prev, [e.target.id]: false }))
                setFormValues(prev => ({ ...prev, ...num }))
            } else {
                setFormStates(prev => ({ ...prev, [e.target.id]: false }))
                setFormValues(prev => ({ ...prev, [e.target.id]: '' }))
            }
        } else if (e.target.value.length == 2) {

            if (parseInt(e.target.value) > 12) {
                setFormValues(prev => ({ ...prev }))
            } else if (parseInt(e.target.value) <= 12) {
                setFormValues(prev => ({ ...prev, ...num }))

                if (parseInt(formValues?.cardExpiryYear) > parseInt(todayYear)) {
                    setFormStates(prev => ({ ...prev, [e.target.id]: true }))
                    setFormStates(prev => ({ ...prev, cardExpiryYear: true }))
                } else if (parseInt(formValues?.cardExpiryYear) == parseInt(todayYear)) {
                    if (parseInt(e.target.value) >= todayMonth) {
                        setFormStates(prev => ({ ...prev, [e.target.id]: true }))
                        setFormStates(prev => ({ ...prev, cardExpiryYear: true }))
                    } else {
                        setFormStates(prev => ({ ...prev, [e.target.id]: false }))
                    }
                }
                document.getElementById('cardExpiryYear').focus()
            }
        } else if (e.target.value == '') {
            setFormValues(prev => ({ ...prev, [e.target.id]: '' }))
            setFormStates(prev => ({ ...prev, [e.target.id]: false }))
        }
    }

    const handleCardExpiryYearChange = (e, num) => {
        if (e.target.value.length < 2) {
            setFormStates(prev => ({ ...prev, [e.target.id]: false }))
            setFormValues(prev => ({ ...prev, ...num }))
        } else if (e.target.value.length == 2) {
            setFormValues(prev => ({ ...prev, ...num }))
            if (parseInt(e.target.value) >= parseInt(todayYear)) {
                setFormStates(prev => ({ ...prev, [e.target.id]: true }))
                if (parseInt(formValues?.cardExpiryMonth) >= parseInt(todayMonth)) {
                    setFormStates(prev => ({ ...prev, cardExpiryMonth: true }))
                } else {
                    setFormStates(prev => ({ ...prev, cardExpiryMonth: false }))
                }
            } else {
                setFormStates(prev => ({ ...prev, [e.target.id]: false }))
            }
            document.getElementById('cardCvc').focus()
        } else if (e.target.value.length > 2) {
            document.getElementById('cardCvc').focus()
        }
    }

    const handleOnCountryChange = () => {
        const valueToSend = document.getElementById('country').value
        // console.log(valueToSend)
        setFormValues(prev => ({ ...prev, country: valueToSend }))
        setFormStates(prev => ({ ...prev, country: true }))
        setHandleChangeTrigger(prev => prev += 1)
    }


    // const handleAmountChange = (e, num) => {
    //     if (e.target.value >= 5 && e.target.value <= 10000) {
    //         //true case
    //         setFormStates(prev => ({ ...prev, [e.target.id]: true }))
    //         setFormValues(prev => ({ ...prev, ...num }))
    //     }
    //     if (e.target.value > 10000) {
    //         setFormStates(prev => ({ ...prev, [e.target.id]: true }))
    //         setFormValues(prev => ({ ...prev, [e.target.id]: 10000 }))
    //     }
    //     if (e.target.value < 5 && e.target.value !== '') {
    //         //show error
    //         setFormStates(prev => ({ ...prev, [e.target.id]: false }))
    //         setFormValues(prev => ({ ...prev, ...num }))
    //     }
    //     if (e.target.value == '') {
    //         //show error
    //         setFormStates(prev => ({ ...prev, [e.target.id]: false }))
    //         setFormValues(prev => ({ ...prev, [e.target.id]: '' }))
    //     }
    // }

    const handleCvcChange = (e, num) => {
        if (e.target.value.length <= 3) {
            setFormStates(prev => ({ ...prev, [e.target.id]: false }))
            setFormValues(prev => ({ ...prev, ...num }))
        }
        if (e.target.value.length >= 3) {
            setFormStates(prev => ({ ...prev, [e.target.id]: true }))
            setFormValues(prev => ({ ...prev }))

            // if(e.target.value.length == 3){
            //     setIsCvcCorrect(true)
            // }
            if (parseInt(e.target.value) > 999) {
                setFormStates(prev => ({ ...prev, [e.target.id]: true }))
                setFormValues(prev => ({ ...prev }))
            }
            // updateMonthYearChangeTrigger()
        }

    }


    const handleOnChangeFormValues = (e) => {

        let obj = { [e?.target?.id]: e?.target?.value }
        let num = { [e?.target?.id]: customOnlyNumberOnChange(e?.target?.value) }
        // if (e.target.id == 'amount') {
        //     handleAmountChange(e, num)
        // }
        if (e.target.id == 'cardNumber') {
            handleCardNumberChange(e, num)
        }
        else if (e.target.id == 'cardExpiryMonth') {
            handleCardExpiryMonthChange(e, num)
        }
        else if (e.target.id == 'cardExpiryYear') {
            handleCardExpiryYearChange(e, num)
        }
        else if (e.target.id == 'cardCvc') {
            handleCvcChange(e, num)
        }
        else {
            setFormValues(prev => ({ ...prev, ...obj }))
            handleFormStateChange(e)
        }
        // await awaitfunction(300)
        setHandleChangeTrigger(prev => prev += 1)
    }

    const ConfirmResponse = () => {
        setOpenModal(true);
    }

    useEffect(() => {
        if (confirmPayment) {
            subscribePackage()
        }
    }, [confirmPayment])

    const errorStyle = {
        border: "1px solid red",
        borderRadius: "3px"
    }

    return (
        <div className={`subscription_form`}>
            {
                isPageLoading ?
                    <LoadingSpinner />

                    : <>
                        <ConfirmationModal open={openModal} setConfirmPayment={setConfirmPayment} amount={packages[select].amount} card={formValues?.cardNumber?.slice(12) || (cardDetails && cardDetails?.card_number?.slice(12))}
                            onClose={() => { setOpenModal(false) }} />

                        <MainHeader />
                        <CustomToastContainer />
                        <div className='form_container'>
                            <div className='form_left'>
                                <div >
                                    <div className='upgrade_section'>
                                        <span>
                                            <Link to={"/wallet"} state={{ selected: true }} style={{ textDecoration: "none" }}>
                                                <img onClick={() => setFlag(true)} src={fullLeftArrow} />
                                            </Link>
                                        </span>

                                        <h2>Upgrade Subscription</h2>
                                    </div>

                                    <div>
                                    </div>
                                    <div className='credits'>
                                        <p>How many credits would you like in your new plan?</p>
                                        <div className='select_input'>
                                            <select className='select_field' value={select} onChange={(e) => { console.log(e.target.value); setSelect(parseInt(e.target.value)) }}>
                                                {(current_subscription_id != 1) && <option value="1">{packages[1].contacts} credits per month</option>}
                                                {(current_subscription_id != 2 || credits == 0) && <option value="2">{packages[2].contacts} credits per month</option>}
                                                {(current_subscription_id != 3 || credits == 0) && <option value="3">{packages[3].contacts} credits per month</option>}
                                            </select >
                                        </div >
                                        <TodayDate />
                                        {/* <p className='subscription_lightext'>Your remaining contact credits will not be rolled over to the new suscription.</p> */}

                                    </div >
                                    <div
                                        className='upgrade_subscription_form_parent'
                                    >
                                        {
                                            isPaymentMethodAlreadyGiven ? <>
                                                {/* Payment method already given */}
                                                <div className='saved_methods'>
                                                    <h3>Payment Method</h3>
                                                    <div className='payment_card'>
                                                        <div>
                                                            <img src={(cardType === "visa" && visalogo) || (cardType === "mastercard" && circleslogo) || (cardType === "amex" && americanexpresslogo)} />
                                                            <div>
                                                                <p>{hideCardNumber(cardDetails?.card_number)}</p>
                                                                <p>{cardDetails?.cardholder_name}</p>
                                                                <p>{`${cardDetails?.expiry_mm} / ${cardDetails?.expiry_yy}`}</p>
                                                            </div>
                                                        </div>
                                                        <PrimaryButton
                                                            style={{ fontSize: "16px" }}
                                                            variant='Beta'
                                                            onClick={changeCard}
                                                        >
                                                            Change
                                                        </PrimaryButton>
                                                    </div>

                                                </div>

                                            </> :
                                                <>
                                                    {/* Payment method not given yet */}
                                                    <div
                                                        className='upgrade_subscription_form'
                                                    >
                                                        <span
                                                            className='paymentMethod'
                                                        >
                                                            <p>
                                                                Payment Method
                                                            </p>
                                                            <img src={stripeLocked} />
                                                        </span>
                                                        <span
                                                            className='cardDetails'
                                                            style={(formStates?.cardNumber && formStates?.cardCvc && formStates?.cardExpiryMonth && formStates?.cardExpiryYear) ? {} : errorStyle}
                                                        >
                                                            <input
                                                                // style={stateOfInputs?.cardNumber ? {} : {...errorStyle , borderRight: 'transparent'}}
                                                                id='cardNumber'
                                                                className='inputBox cardNumber'
                                                                placeholder='Card Number'
                                                                value={formValues?.cardNumber}
                                                                onChange={handleOnChangeFormValues}
                                                            />
                                                            <input
                                                                // style={stateOfInputs?.cardExpiryDate ? {} : {...errorStyle , borderRadius: '0px' ,  borderRight: 'transparent' , borderLeft: 'transparent'}}
                                                                id='cardExpiryMonth'
                                                                className='inputBox cardExpiryMonth'
                                                                placeholder='MM'
                                                                value={formValues?.cardExpiryMonth}
                                                                onChange={handleOnChangeFormValues}
                                                            />
                                                            <p>/</p>
                                                            <input
                                                                // style={stateOfInputs?.cardExpiryDate ? {} : {...errorStyle , borderRadius: '0px' ,  borderRight: 'transparent' , borderLeft: 'transparent'}}
                                                                id='cardExpiryYear'
                                                                className='inputBox cardExpiryYear'
                                                                placeholder='YY'
                                                                value={formValues?.cardExpiryYear}
                                                                onChange={handleOnChangeFormValues}
                                                            />
                                                            <input
                                                                // style={stateOfInputs?.cardCvc ? {} : {...errorStyle, borderLeft: 'transparent'}}
                                                                value={formValues?.cardCvc}
                                                                id='cardCvc'
                                                                className='inputBox cardCvc'
                                                                placeholder='CVC'
                                                                onChange={handleOnChangeFormValues}
                                                            />
                                                        </span>
                                                        <input
                                                            style={formStates?.cardholderName ? {} : errorStyle}
                                                            id='cardholderName'
                                                            className='inputBox'
                                                            placeholder='Cardholder Name'
                                                            value={formValues.cardholderName}

                                                            onChange={handleOnChangeFormValues}
                                                        />
                                                    </div>
                                                    <div
                                                        className='upgrade_subscription_form'
                                                    >
                                                        <p>Billing Details</p>
                                                        <input
                                                            style={formStates?.companyName ? {} : errorStyle}
                                                            id='companyName'
                                                            className='inputBox'
                                                            value={formValues.companyName}

                                                            placeholder='Company Name'
                                                            onChange={handleOnChangeFormValues}
                                                        />
                                                        <input
                                                            style={formStates?.billingEmail ? {} : errorStyle}
                                                            id='billingEmail'
                                                            className='inputBox'
                                                            placeholder='Billing Email'
                                                            value={formValues.billingEmail}

                                                            onChange={handleOnChangeFormValues}
                                                        />
                                                        <input
                                                            style={formStates?.billingAddress ? {} : errorStyle}
                                                            id='billingAddress'
                                                            className='inputBox'
                                                            placeholder='Billing Address'
                                                            value={formValues.billingAddress}

                                                            onChange={handleOnChangeFormValues}
                                                        />
                                                        <div >
                                                            <input
                                                                style={formStates?.city ? {} : errorStyle}
                                                                id='city'
                                                                className='inputBox halfInputBox'
                                                                value={formValues.city}

                                                                placeholder='City'
                                                                onChange={handleOnChangeFormValues}
                                                            />
                                                            <input
                                                                style={formStates?.zipCode ? {} : errorStyle}
                                                                id='zipCode'
                                                                className='inputBox halfInputBox'
                                                                value={formValues.zipCode}

                                                                placeholder='Zip Code'
                                                                onChange={handleOnChangeFormValues}
                                                            />
                                                        </div>
                                                        <CountryDropdown
                                                            style={formStates?.country ? {} : errorStyle}
                                                            id='country'
                                                            className="countryDropdown"
                                                            value={formValues?.country}
                                                            onChange={handleOnCountryChange}
                                                        />

                                                    </div>
                                                    {/* update card buttons */}
                                                    {showUpdateButtons
                                                        && <div className='update_card_buttons'>
                                                            <button onClick={() => { setIsPaymentMethodAlreadyGiven(true); setShowUpdateButtons(false) }} className='cancel_btn'>Cancel</button>
                                                            <button onClick={updateCard} disabled={!trueStateUpdateCard} style={!trueStateUpdateCard ? { cursor: "not-allowed" } : {}} className='update_btn'>Update Card</button>
                                                        </div>
                                                    }

                                                </>
                                        }
                                    </div>

                                </div >
                            </div >
                            <div className='form_right'>
                                <div className='payment_summary'>
                                    <h3 style={{
                                        margin: "0", fontSize: "20px"
                                    }}>{subscription_selected.package}</h3>
                                    <div >
                                        <p className='title'>{subscription_selected.contacts} credits/mo</p>
                                        <p className='bill_amount'>${subscription_selected.cost || 0}</p>
                                    </div>
                                    <TodayDate invoice_box_message='invoice_box_message' />

                                    {/* <div className='hrline'></div> */}

                                    {/* <div className='wallet_amount'>
                                        <p>Amount in wallet</p>
                                        <p >${availableRewardBalance.toFixed(2)}</p>
                                    </div>
                                    <ProratedAmount />
                                    <div className='wallet_amount'>
                                        <p>Amount charged from wallet</p>
                                        <p >-${(subscription_selected.cost - prorated_amount).toFixed(2)}</p>
                                    </div> */}

                                    {/* <div >
                                        <DownGradeDate />
                                        <p className='bill_amount bill_amount2' style={insufficientAmountInWallet ? { color: "#EB5757" } : {}}>${(subscription_selected.cost - prorated_amount).toFixed(2) || 0}</p>
                                    </div> */}

                                    {/* {
                                        insufficientAmountInWallet && <div
                                            className='insufficientMessageCard'
                                        >
                                            <RedNotMarkCircle
                                                height='32'
                                                width='32'
                                            />
                                            <p>
                                                Not enough amount in your wallet. Please top up your wallet and try again.
                                            </p>
                                            <button onClick={() => navigate('/wallet/topup')
                                            }>
                                                Top up
                                            </button>
                                        </div>
                                    } */}

                                    <PrimaryButton
                                        style={isInitializing ? {
                                            ...ConfirmButtonStyle,
                                            background: "#BDBDBD"

                                        } : {
                                            ...ConfirmButtonStyle,
                                        }}
                                        onClick={ConfirmResponse}
                                        disabled={
                                            // insufficientAmountInWallet || 
                                            isInitializing ||
                                            isSubmitDisabled
                                        }

                                    >
                                        Confirm Payment
                                    </PrimaryButton>

                                    <div className='footer_images'>
                                        <img src={visalogo} />
                                        <img src={circleslogo} />
                                        <img src={americanexpresslogo} />
                                    </div>

                                </div>
                            </div>
                        </div >
                    </>
            }
        </div >
    )
}

export default SubscriptionForm