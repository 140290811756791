import React, { useState, useEffect } from 'react'
// import sqoutSvg from "../../img/Sqout.svg"
// import sqoutLogoSvg from "../../img/SqoutLogo.svg"
import yoriSvg from "../../img/Yori.svg"
import yoriLogoSvg from "../../img/YoriLogo.svg"
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import profile from "../../assets/icons/profile.png"
import gift from "../../assets/icons/gift.svg"

import "./style.scss"
import { useDispatch, useSelector } from 'react-redux';
import { initWallet, setDropMenu, setUser } from '../../actions/auth';
import { getAuth } from "firebase/auth";
import { setReferralAcceptedReward, setUserContacts } from '../../actions/referrals';
import { callYoriApi, signOut } from '../../utils';
import TopAlert from '../TopAlert';


const MainHeader = ({
  onGetExtension = e => false
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const route = useLocation().pathname.split("/")[1]
  const [showMenu, setShowMenu] = useState(false)
  const isContactsCountLoading = useSelector(state => state?.auth?.wallet?.isLoading)
  const subscriptionType = useSelector(state => state?.auth?.wallet?.subscriptionId)
  const [lessAmountAlert, setLessAmountAlert] = useState()

  useEffect(async () => {
    dispatch(initWallet())
    dispatch(setReferralAcceptedReward())
    // const res = await callYoriApi("/subscription/alert", "GET")
    // // console.log("res :", res)
    // setLessAmountAlert(res)
  }, [])



  const drop_menu = useSelector(state => state.auth?.user?.drop_menu)
  let credits = useSelector(state => state?.auth?.wallet?.credits)
  const rewardsNotSeen = useSelector(state => state?.referrals?.rewards?.rewards_not_seen)
  const [showRewardIcon, setshowRewardIcon] = useState(rewardsNotSeen?.length > 0)

  useEffect(() => {
    // console.log("==========rewardsNotSeen=========", rewardsNotSeen)
    setshowRewardIcon(rewardsNotSeen?.length > 0)
  }, [rewardsNotSeen])

  const path = useLocation()
  // console.log(path?.pathname)
  const user = { name: localStorage.getItem("name"), email: localStorage.getItem("email") }

  return (
    <>
      <div
        className='mainHeaderContainer headeroverlay'
      // onClick={(e)=>setShowMenu(false)}

      // onClick={(e) => {
      //     e.stopPropagation();
      //     // setShowMenu(false)
      //   }}
      >
        <span>
          <div onClick={() => navigate("/")}>
            <img
              src={yoriLogoSvg}
            />
            <img
              src={yoriSvg}
            />
          </div>
        </span>
        <span>
          <ul>
            <NavLink to={"/lists"} className={`${route == "lists" ? "header__link--active " : ""} header__link`} >   Lists </NavLink>
            <NavLink to={"/reward"} className={`${route == "reward" ? "header__link--active " : ""} header__link`} >   Rewards {showRewardIcon && <img src={gift} />}    </NavLink>
            {/* <NavLink to={"/invitations_credits"} className={`${route == "invitations_credits" ? "header__link--active " : ""} header__link`} >   Invitations and Credits</NavLink> */}
            <NavLink to={"/invitations_credits"} className={`${(route == "invitations_credits") ? "header__link--active " : ""} header__link`} >   Invitations and Credits</NavLink>
            {/* <NavLink to= {"/connect"} className={`${(route == "connect" || route == "invitations_credits") ? "header__link--active " : ""} header__link`} >   Invitations and Credits</NavLink> */}
            <NavLink to={"/wallet"} className={`${route == "wallet" ? "header__link--active " : ""} header__link`} >   Wallet</NavLink>
          </ul>
        </span>

        <div>
          <span
            className='remainingContacts'
          >
            {
              !isContactsCountLoading ? <p>{credits} credits</p> : <p>Loading</p>
            }
          </span>
          <span className='dropmenu' onClick={(e) => { e.stopPropagation(); dispatch(setDropMenu(!drop_menu)); setShowMenu(!showMenu) }}>
            <img src={profile} />
            <p>{user?.name}</p>
            <div className={`profiledrop ${drop_menu ? "menu_drop" : ""}`}>
              <h3>My Profile</h3>
              <h4 onClick={() => navigate("/profile")}>Account Settings</h4>
              {/* <h4 onClick={() => navigate("/billing")}>Billing Information</h4> */}
              <h4 onClick={() => signOut(navigate)} className='logout'>logout</h4>
            </div>
          </span>
        </div>
      </div>
      {/* {
        path?.pathname !== "/wallet/topup" && lessAmountAlert?.show_alert && <TopAlert text={`You do not have enough money in your wallet for your upcoming renewal on ${lessAmountAlert?.date}. Please send more invites or top up your wallet`} />
      } */}
    </>
  )
}

export default MainHeader