import * as ActionTypes from "../../actions/referrals"

const initialState = {
  rewards: [],
  reward_balance: 0,
  rewards_history: [],
  rewards_not_seen: [],
  isEarningLoading: false,
  isEarningLoaded: false,
  earning: 0,
}


export const rewards = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_REWARDS:
      return {
        ...state,
        rewards: [...action.payload]
      };
    case ActionTypes.SET_REWARD_BALANCE:
      return {
        ...state,
        reward_balance: action.payload
      };
    case ActionTypes.INIT_REWARDS_HISTORY:
      return {
        ...state,
        rewards_history: action.payload
      };
    case ActionTypes.SET_REFERRAL_ACCEPTED_REWARD:
      return {
        ...state,
        rewards_not_seen: action.payload
      };
    case ActionTypes.UPDATE_REFERRAL_ACCEPTED_REWARD:
      return {
        ...state,
        rewards_not_seen: action.payload
      };


    case ActionTypes.INIT_EARNING:
      return {
          ...state,
          isEarningLoading: true,
          isEarningLoaded: false,
      };
    case ActionTypes.INIT_EARNING_SUCCESS:
      return {
          ...state,
          isEarningLoading: false,
          isEarningLoaded: true,
          earning: action.payload
      };
    case ActionTypes.INIT_EARNING_FAILURE:
      return {
          ...state,
          isEarningLoading: false,
          isEarningLoaded: false,
      };
    default:
      return state;
  }
};